import React, { Component } from 'react'
import BasicDetails from './BasicDetails';
import ContactDetails from './ContactDetails';
import DocumentDetails from './DocumentDetails';
import EducationDetails from './EducationDetails';
import PaymentDetails from './PaymentDetails';
import Summary from './Summary';
import Submission from './Submission';
import CoursePreference from './CoursePreference';
import VjcetService from '../vjcet-service';
import GuardianDetails from './GuardianDetails';
import Allocation from './Allocation';

import './style.css'

export class Registration extends Component {


    state = {
        step: 1,
        basicData: {
            first_name: '', middle_name: '', last_name: '', dob: '', gender: '', community: "",
            applicant_email: '', status: 0,
            applicant_mobile: '', title: "mr", id: this.props.match.params.a_id, photo: null,photo_sel: null,
            religion_id: '', sub_quota_id: "", application_id: "", quota_id: 0, photo_url: "", photo_error: "",
            vjcet_admission_email: 'admission@vjcet.org', vjcet_admission_tel: '8281352211', stage: 1, allotment_made: false,
            allotment_branch_id: -1, allotment_quota_id: -1, allotment_deposit_reference: "", allotment_acceptance_datetime: "",
            allotment_paid: 0, allotment_balance: 0
        },
        contact: {
            home_address_1: '', home_address_2: '', home_address_pincode: '', home_address_city: '',
            home_address_district: '', home_address_state: '', home_address_country: '', communication_address_1: '',
            communication_address_2: '', communication_address_pincode: '', communication_address_city: '',
            communication_address_district: '', communication_address_state: '', communication_address_country: ''
        },
        guardian: {
            father_name: '', father_occupation: '', father_mobile: '', mother_name: '', mother_occupation: '',
            mother_mobile: '', guardian_name: '', guardian_relationship: ' ', guardian_mobile: '', guardian_address_1: '',
            guardian_address_2: '', guardian_address_city: '', guardian_address_district: '', guardian_address_pincode: '',
            guardian_address_state: '', annual_income: ''
        },
        education: {
            qualification_exam: 0, qualification_month: 0, qualification_year: 0, qualification_board: 0, qualification_registration_no: '',
            physics_score: '', chemistry_score: '', maths_score: '', keam_year: 2024, keam_application_no: '', keam_roll_no: '', paper_1_score: "", paper_2_score: "",
            keam_total_score: 0.0000, school_name: '', school_district: ''
        },
        courses: { first_option: -1, second_option: -1, third_option: -1, fourth_option: -1, fifth_option: '', sixth_option: '' },
        documents: {
            parent_signature: null, parent_signature_url: '', parent_signature_error: '',parent_signature_sel: null,
            tenth_certificate: null, tenth_certificate_url: '', tenth_certificate_error: '',tenth_certificate_sel: null,
            entrance_marklist: null, entrance_marklist_url: '', entrance_marklist_error: '',entrance_marklist_sel: null,
            community_certificate: null, community_certificate_url: '', community_certificate_error: '',community_certificate_sel: null,
            application_signature: null, application_signature_url: '', application_signature_error: '',application_signature_sel: null,
            twelth_certificate: null, twelth_certificate_url: '', twelth_certificate_error: '',twelth_certificate_sel: null,
            entrance_exam_admit_card: null, entrance_exam_admit_card_url: '', entrance_exam_admit_card_error: '',entrance_exam_admit_card_sel: null,
        },
        payment: {},
        errors: {},
        loggedIn: true
    }

    //next step
    nextStep = (new_step) => {

        this.setState({
            step: new_step
        });

    }
    //previous step
    prevStep = (new_step) => {

        this.setState({
            step: new_step
        });
    }
    //to hide college code from form header
    formValue = () => {
        return true;
    }
    logout = () => {
        //console.log("Logged out");

        VjcetService.sign_out().then((resp) => {

            let data = resp.data;
            if (data.success) {
                VjcetService.remove_local_storage("access_token");
                VjcetService.remove_local_storage("a_id");

                this.props.history.push('/');
            } else {
                console.log("error signing out");
                VjcetService.remove_local_storage("access_token");
                VjcetService.remove_local_storage("a_id");

                this.props.history.push('/');
            }
        }).catch(function (error) {

            VjcetService.remove_local_storage("access_token");
            VjcetService.remove_local_storage("a_id");
            this.props.history.push('/');

        });
    }

    basicDataChange = ({ currentTarget: input }) => {
        const basicData = { ...this.state.basicData };
        basicData[input.name] = input.value;
        // console.log("gender change  " + input.name + " " + input.value);
        this.setState({ basicData })
    }

    contactDataChange = ({ currentTarget: input }) => {
        const contact = { ...this.state.contact };
        contact[input.name] = input.value;
        this.setState({ contact })
    }
    guardianDataChange = ({ currentTarget: input }) => {
        const guardian = { ...this.state.guardian };
        guardian[input.name] = input.value;
        this.setState({ guardian })
    }
    educationDataChange = ({ currentTarget: input }) => {
        const education = { ...this.state.education };
        let name = input.name;
        education[input.name] = input.value;
        if (name === 'paper_1_score' || name === 'paper_2_score') {
            let score1 = education['paper_1_score'] || 0.0000;
            let score2 = education['paper_2_score'] || 0.0000;
            education['keam_total_score'] = (parseFloat(score1) + parseFloat(score2)).toFixed(4);
        }
        this.setState({ education })
    }
    coursesDataChange = ({ currentTarget: input }) => {
        const courses = { ...this.state.courses };
        courses[input.name] = input.value;
        this.setState({ courses })
    }

    documentsDataChange = ({ currentTarget: input }) => {
        const documents = { ...this.state.documents };
        documents[input.name] = input.value;
        this.setState({ documents })
    }
    paymentsDataChange = ({ currentTarget: input }) => {
        const payment = { ...this.state.payment };
        payment[input.name] = input.value;
        this.setState({ payment })
    }
    profileImageChanged = (event) => {

        const basicData = { ...this.state.basicData };

        if (event.target.files[0] && event.target.files[0] !== undefined) {
            let file_size = event.target.files[0].size;
            basicData['photo_error'] = "";
            if (file_size <= VjcetService.MAX_FILE_SIZE) {

                basicData['photo'] = event.target.files[0];
                basicData["photo_url"] = URL.createObjectURL(event.target.files[0]);
				basicData["photo_sel"] = true;

            } else {
                basicData['photo_error'] = "Photo file size cannot exceed 2MB";
            }

            this.setState({ basicData });
        }

    }

    updateSelectValue = (event) => {
        const basicData = this.state.basicData;
        basicData[event.target.name] = event.target.value;
        this.setState({ basicData })
    }
    updateEducationSelectValue = (event) => {
        const education = { ...this.state.education };
        let edu_name = event.target.name;
        education[edu_name] = event.target.value;
        if (edu_name === "qualification_exam") {
            education['qualification_board'] = 0;
        }
        this.setState({ education })
    }

    updatePreferenceSelectValue = (event) => {
        const courses = { ...this.state.courses };
        courses[event.target.name] = event.target.value;
        this.setState({ courses })
    }

    documentChangeHandler = (event) => {

        const documents = { ...this.state.documents };
        let doc_name = event.target.name;
        let doc_url = doc_name + "_url";
        let doc_error = doc_name + "_error";
		let doc_sel = doc_name+ "_sel";
		console.log('changed document.');
		
        if (event.target.files[0] && event.target.files[0] !== undefined) {
            let file_size = event.target.files[0].size;

            documents[doc_error] = "";
            if (file_size <= VjcetService.MAX_FILE_SIZE) {
                documents[doc_name] = event.target.files[0];
                documents[doc_url] = URL.createObjectURL(event.target.files[0]);
				documents[doc_sel] = true;
            } else {
                documents[doc_error] = "File size cannot exceed 2MB";
            }
            this.setState({ documents });
        }
    }

    cloneAddress = (event) => {

        const contact = { ...this.state.contact };
        let cloneState = event.target.checked;
        if (cloneState) {

            contact['communication_address_1'] = contact['home_address_1'];
            contact['communication_address_2'] = contact['home_address_2'];
            contact['communication_address_pincode'] = contact['home_address_pincode'];
            contact['communication_address_city'] = contact['home_address_city'];
            contact['communication_address_district'] = contact['home_address_district'];
            contact['communication_address_state'] = contact['home_address_state'];
            contact['communication_address_country'] = contact['home_address_country'];
        } else {
            contact['communication_address_1'] = "";
            contact['communication_address_2'] = "";
            contact['communication_address_pincode'] = "";
            contact['communication_address_city'] = "";
            contact['communication_address_district'] = "";
            contact['communication_address_state'] = "";
            contact['communication_address_country'] = "";
        }

        this.setState({ contact })
    }



    componentDidMount() {

        VjcetService.load_applicant_data().then((resp) => {

            if (resp && resp !== undefined) {
                const data = resp.data;

                if (data.success) {
                    const applicant = data.application;
                    const basicData = { ...this.state.basicData };
                    const contact = { ...this.state.contact };
                    const guardian = { ...this.state.guardian };
                    const education = { ...this.state.education };
                    const courses = { ...this.state.education };
                    const documents = { ...this.state.documents };
                    let stage = 1;

                    if (applicant.stage && applicant.stage !== undefined) {
                        stage = parseInt(applicant.stage, 10) || 1;
                        basicData["stage"] = stage;
                    }

                    let photo = applicant.photo || "";
                    if (photo.length > 0) {
                        photo = data.url + photo;
                    }

                    basicData["application_id"] = data.application_id || "";
                    basicData["applicant_email"] = data.email || "";
                    basicData["first_name"] = applicant.first_name || "";
                    basicData["middle_name"] = applicant.middle_name || "";
                    basicData["last_name"] = applicant.last_name || "";
                    // basicData["aadhar"] = applicant.aadhar || "";
                    basicData["dob"] = applicant.dob || "";
                    basicData["title"] = applicant.title || "mr";
                    basicData["gender"] = applicant.gender || -1;
                    basicData["applicant_mobile"] = applicant.applicant_mobile || "";
                    basicData["photo"] = applicant.photo || "";
                    basicData["photo_url"] = photo;
                    basicData["religion_id"] = applicant.religion_id || "";
                    basicData["sub_quota_id"] = applicant.sub_quota_id || "";
                    basicData["quota_id"] = applicant.quota_id || "";
                    basicData["community"] = applicant.community || "";
                    basicData["allotment_branch_id"] = applicant.allotment_branch_id || -1;
                    basicData["allotment_quota_id"] = applicant.allotment_quota_id || -1;
                    basicData["allotment_deposit_reference"] = applicant.allotment_deposit_reference || "";
                    basicData["allotment_acceptance_datetime"] = applicant.allotment_acceptance_datetime || "";
                    basicData["status"] = applicant.status || -1;
                    basicData["allotment_paid"] = applicant.paid || -1;
                    basicData["allotment_balance"] = applicant.balance || -1;


                    if (applicant.allotment_branch_id > 0 && applicant.allotment_branch_id > 0) {
                        basicData["allotment_made"] = true;
                    }

                    guardian["father_name"] = applicant.father_name || "";
                    guardian["father_occupation"] = applicant.father_occupation || "";
                    guardian["father_mobile"] = applicant.father_mobile || "";
                    guardian["mother_name"] = applicant.mother_name || "";
                    guardian["mother_occupation"] = applicant.mother_occupation || "";
                    guardian["mother_mobile"] = applicant.mother_mobile || "";
                    guardian["annual_income"] = applicant.annual_income || "";
                    guardian["guardian_name"] = applicant.guardian_name || "";
                    guardian["guardian_relationship"] = applicant.guardian_relationship || "";
                    guardian["guardian_mobile"] = applicant.guardian_mobile || "";


                    if (applicant.addresses && applicant.addresses.length > 0) {

                        applicant.addresses.forEach(address => {

                            if (address.type === 1) {

                                contact["home_address_1"] = address.line_1 || "";
                                contact["home_address_2"] = address.line_2 || "";
                                contact["home_address_pincode"] = address.pincode || "";
                                contact["home_address_city"] = address.city || "";
                                contact["home_address_district"] = address.district || "";
                                contact["home_address_state"] = address.state || "";
                                contact["home_address_country"] = address.country || "";
                            } else if (address.type === 2) {

                                contact["communication_address_1"] = address.line_1 || "";
                                contact["communication_address_2"] = address.line_2 || "";
                                contact["communication_address_pincode"] = address.pincode || "";
                                contact["communication_address_city"] = address.city || "";
                                contact["communication_address_district"] = address.district || "";
                                contact["communication_address_state"] = address.state || "";
                                contact["communication_address_country"] = address.country || "";
                            } else if (address.type === 3) {

                                guardian["guardian_address_1"] = address.line_1 || "";
                                guardian["guardian_address_2"] = address.line_2 || "";
                                guardian["guardian_address_pincode"] = address.pincode || "";
                                guardian["guardian_address_city"] = address.city || "";
                                guardian["guardian_address_district"] = address.district || "";
                                guardian["guardian_address_state"] = address.state || "";
                            }

                        });
                    }

                    if (applicant.qualification && applicant.qualification !== undefined) {
                        let qualification = applicant.qualification;
                        let keam_total_score = (qualification.total_keam && qualification.total_keam !== undefined) ? (qualification.total_keam).toFixed(4) : "";
                        education["qualification_board"] = qualification.board || -1;
                        education["qualification_exam"] = qualification.qualification_exam || -1;
                        education["qualification_month"] = qualification.month || "";
                        education["qualification_year"] = qualification.year || 2020;
                        education["qualification_registration_no"] = qualification.registration_no || "";
                        education["physics_score"] = qualification.physics_score || 0.00;
                        education["chemistry_score"] = qualification.chemistry_score || 0.00;
                        education["maths_score"] = qualification.maths_score || 0.00;
                        education["keam_year"] = qualification.keam_year || "";
                        education["keam_application_no"] = qualification.keam_application_no || "";
                        education["keam_roll_no"] = qualification.keam_roll_no || "";
                        education["paper_1_score"] = qualification.paper_1_score || 0.0000;
                        education["paper_2_score"] = qualification.paper_2_score || 0.0000;
                        education["keam_total_score"] = keam_total_score;
                        education["school_name"] = qualification.school_name || "";
                        education["school_district"] = qualification.school_district || "";
                    }


                    if (applicant.options && applicant.options.length > 0) {

                        applicant.options.forEach(option => {

                            switch (option.preference_order) {


                                case 1:
                                    courses["first_option"] = option.course_id || -1;
                                    break;

                                case 2:
                                    courses["second_option"] = option.course_id || -1;
                                    break;

                                case 3:
                                    courses["third_option"] = option.course_id || -1;
                                    break;

                                case 4:
                                    courses["fourth_option"] = option.course_id || -1;
                                    break;


                                default:
                                    break;
                            }

                        });
                    }

                    if (applicant.documents && applicant.documents.length > 0) {

                        applicant.documents.forEach(doc => {

                            let doc_file = doc.doc_path || "";
                            if (doc_file.length > 0) {
                                doc_file = data.url + doc_file;

                                switch (doc.type) {

                                    case 1:
                                        documents["application_signature"] = doc.doc_path;
                                        documents["application_signature_url"] = doc_file;
                                        break;

                                    case 2:
                                        documents["parent_signature"] = doc.doc_path;
                                        documents["parent_signature_url"] = doc_file;
                                        break;

                                    case 3:
                                        documents["tenth_certificate"] = doc.doc_path;
                                        documents["tenth_certificate_url"] = doc_file;
                                        break;

                                    case 4:
                                        documents["twelth_certificate"] = doc.doc_path;
                                        documents["twelth_certificate_url"] = doc_file;
                                        break;

                                    case 5:
                                        documents["entrance_marklist"] = doc.doc_path;
                                        documents["entrance_marklist_url"] = doc_file;
                                        break;

                                    case 6:
                                        documents["entrance_exam_admit_card"] = doc.doc_path;
                                        documents["entrance_exam_admit_card_url"] = doc_file;
                                        break;

                                    case 7:
                                        documents["community_certificate"] = doc.doc_path;
                                        documents["community_certificate_url"] = doc_file;
                                        break;


                                    default:
                                        break;
                                }
                            }

                        });
                    }



                    this.setState({ basicData, contact, guardian, education, courses, documents });

                    this.setState({ showMessage: true });

                    // console.log("setting step val " + stage);
                    this.setState({
                        step: stage
                    });


                } else {

                }
            }
        });
    }

    render() {
        const { step } = this.state;
        const { basicData, contact, guardian, education, courses, documents, payment } = this.state;


        switch (step) {
            case 1:
                return (
                    <BasicDetails
                        nextStep={this.nextStep}
                        profileImageChanged={this.profileImageChanged}
                        updateSelectValue={this.updateSelectValue}
                        handleChange={this.basicDataChange}
                        updatePhoto={this.updatePhoto}
                        values={basicData}
                        step={step}
                        logout={this.logout}
                        formValue={this.formValue}
                    />
                )

            case 2:
                return (
                    <ContactDetails
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        cloneAddress={this.cloneAddress}
                        handleChange={this.contactDataChange}
                        values={contact}
                        appInfo={basicData}
                        step={step}
                        logout={this.logout}
                        formValue={this.formValue}

                    />
                )
            case 3:
                return (
                    <GuardianDetails
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.guardianDataChange}
                        values={guardian}
                        appInfo={basicData}
                        step={step}
                        logout={this.logout}
                        formValue={this.formValue}

                    />
                )
            case 4:
                return (
                    <EducationDetails
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        updateEducationSelectValue={this.updateEducationSelectValue}
                        handleChange={this.educationDataChange}
                        values={education}
                        appInfo={basicData}
                        step={step}
                        logout={this.logout}
                        formValue={this.formValue}

                    />
                )
            case 5:
                return (
                    <CoursePreference
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        updatePreferenceSelectValue={this.updatePreferenceSelectValue}
                        handleChange={this.coursesDataChange}
                        values={courses}
                        appInfo={basicData}
                        step={step}
                        logout={this.logout}
                        formValue={this.formValue}

                    />
                )

            case 6:
                return (
                    <DocumentDetails
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.documentsDataChange}
                        documentChangeHandler={this.documentChangeHandler}
                        values={documents}
                        appInfo={basicData}
                        step={step}
                        logout={this.logout}
                        formValue={this.formValue}

                    />
                )
            case 7:
                return (
                    <Summary
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        values={this.state}
                        step={step}
                        formValue={this.formValue}

                    />
                )
            case 8:
                return (
                    <PaymentDetails
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.paymentsDataChange}
                        values={payment}
                        appInfo={basicData}
                        step={step}
                        formValue={this.formValue}

                    />
                )

            case 9:
                return (
                    <Submission 
						nextStep={this.nextStep}     
						values={education}
						appInfo={basicData} 
						formValue={this.formValue} 
						handleChange={this.educationDataChange}
						documentChangeHandler={this.documentChangeHandler}
						documents={documents}
						profileImageChanged={this.profileImageChanged}
					/>
                )

            case 10:
                return (
                    <Allocation nextStep={this.nextStep} handleChange={this.basicDataChange} appInfo={basicData} formValue={this.formValue} />
                )

            default:
                return (
                    <BasicDetails
                        nextStep={this.nextStep}
                        profileImageChanged={this.profileImageChanged}
                        updateSelectValue={this.updateSelectValue}
                        handleChange={this.basicDataChange}
                        updatePhoto={this.updatePhoto}
                        values={basicData}
                        step={step}
                        logout={this.logout}
                        formValue={this.formValue}

                    />
                )

        }
    }
}

export default Registration
