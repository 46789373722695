import React from 'react'
import { FormControl, FormLabel } from '@material-ui/core';
import './style.css'
import code from '../../images/college_code.png'
import VjcetService from 'vjcet-service';

const SubHeader = ({ appInfo }) => {


  return (
    <div id="sub-header">
      <div>
        <FormControl component="fieldset">
          <FormLabel component="h4" style={subheader}>APPLICATION FORM FOR B.TECH DEGREE ADMISSION {VjcetService.ACADEMIC_YEAR} </FormLabel>
        </FormControl>
        <div style={textStyle}>
          <p>Application Number: <span className="highlight">{appInfo.application_id}</span></p>
          <p>Applicant Name: <span className="highlight text-upper">{appInfo.first_name} {appInfo.middle_name} {appInfo.last_name}</span> </p>
        </div>
        <div className="sub-header-img">
          <img src={code} alt="code" />
        </div>


      </div>
    </div >

  )
}

const subheader = {
  color: '#3F3D56',
  fontSize: "20px",
  fontWeight: "700",
  textAlign: "center",
}
const textStyle = {
  fontSize: "12px",
  marginTop: "10px",
  color: '#3F3D56'

}

export default SubHeader