import React from 'react'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';


function getSteps() {
    return ['Basic Info', 'Contacts', 'Guardian Details', 'Education', "Course Preference", "Documents", "Summary", "Payment", "Submission"];
}


const ProgressBar = (props) => {
    const steps = getSteps();


    return (
        <div>
            <Stepper activeStep={props.step - 1} alternativeLabel className="pbstyles">

                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))
                }

            </Stepper>
        </div>
    )

}



export default ProgressBar
