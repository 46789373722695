import React from 'react'
import { Container, List, ListItem, ListItemText } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Smartphone';
import './style.css'


const FormFooter = () => {
	const date = new Date();
	const year = date.getFullYear();
	
    return (
        <div id="form-footer" style={footerStyle} className="container-style">
            <Container >
                <div id="form-footer-content">
                    <div id="conatcts" style={listStyle}>
                        <h4>For any queries please contact : </h4>
                        <List id="contact-list">
                            <ListItem>
                                <ListItemText>
                                    Mr. Manu Jose<br />
                                    <a href="tel:8281352211"><PhoneIcon style={{ fontSize: 15 }} />8281352211</a><br />
                                    <a href="tel:8281652211"><PhoneIcon style={{ fontSize: 15 }} />8281652211</a>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    Mr. Babu T Chacko<br />
                                    <a href="tel:9656154572"><PhoneIcon style={{ fontSize: 15 }} />9656154572</a>
                                </ListItemText>
                            </ListItem>
                        </List>

                    </div>


                </div>
                <div className="form-footer-text-style">
                    <p>&#169; { year } Viswajyothi College Of Engineering and Technology. All rights reserved.</p>
                </div>

            </Container>
        </div>
    )
}

const footerStyle = {
    background: 'linear-gradient(#9D0055,#270015)',
}

const listStyle = {
    color: "#f4f4f4",
    fontSize: "0.8rem",
    fontWeight: "700"
}

export default FormFooter