
import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../Components/Header'
import { Container } from '@material-ui/core';
import Footer from '../Components/Footer'
import './style.css'

const About = () => {
    return (
        <div>
            <Header />

            <Container className="margin-b">
                <div className="footer-pages-header">
                    <div className="dark-line"></div>
                    <h1 className="title-style">About Us</h1>
                </div>

                <section className="section-styles">
                    <h4>Viswajyothi was established in the year 2001, and it is affiliated to A.P.J.AbdulKalam Technological University. A highly committed Management Team with competent and dedicated Faculty and staff makes this place an International Center of Excellence in Engineering Education.</h4>
                    <h4>PG Programmes offered:</h4>
                    <ul>
                        <li>Computer Science & Engineering</li>
                        <li>Electronics and Communication Engineering with specialization VLSI & Embedded Systems.</li>
                        <li>Industrial Engineering and Management</li>
                        <li>MBA</li>
                    </ul>
                </section>
                <section className="section-styles">
                    <h4>B-Tech Programmes offered:(All programs Accredited by NBA))</h4>
                    <ul>
                        <li>Artificial Intelligence Data Science</li>
                        <li>Civil Engineering</li>
                        <li>Computer Science and Design</li>
                        <li>Computer Science and Engineering</li>
                        <li>Electronics and Communication engineering</li>
                        <li>Electrical and Electronics Engineering</li>
                        <li>Information Technology</li>
                        <li>Mechanical Engineering</li>
                    </ul>

                </section>
            </Container>
            <Footer />


        </div>
    )
}


export default About;