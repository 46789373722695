import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Typography, Box, List, ListItem, ListItemAvatar, ListItemText, Avatar, Divider, ListItemIcon, Grid } from '@material-ui/core';
import Quota from './Quota'
import Programme from './Programme'
import Fee from './Fee'
import Dates from './Dates'
import Online from './Online'
import Contacts from './Contacts'
import PhoneIcon from '@material-ui/icons/Phone';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MenuIcon from '@material-ui/icons/Menu';
import {
    Container, Link

} from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
}));

export default function Instruction() {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container id="instruction-container">
            <div id="instruction">
                <div className="header-style">
                    <h1 className="title-style1">Admission Details</h1>
                </div>
                <div  >
                    <Grid item xs={12}>

                        <AppBar position="static" style={{ "color": "black", "background": "#f4f4f4", "boxShadow": "none", "paddingTop": "30px" }}>
                            <Tabs value={value} onChange={handleChange} variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example">
                                <Tab label="COURSES" icon={<AssessmentIcon />} {...a11yProps(0)} />
                                <Tab label="ADMISSION INSTRUCTIONS" icon={<MenuIcon />} {...a11yProps(1)} />
                                <Tab label="FEE STRUCTURE" icon={<AccountBalanceIcon />} {...a11yProps(2)} />
                                <Tab label="IMPORTANT DATES" icon={<CalendarTodayIcon />} {...a11yProps(3)} />
                                <Tab label="Online Application" icon={<MenuIcon />} {...a11yProps(4)} />
                                <Tab label="Contacts" icon={<PhoneIcon />} {...a11yProps(5)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <Programme />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Quota />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Fee />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <Dates />
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <Online />
                        </TabPanel>
                        <TabPanel value={value} index={5}>
                            <Contacts />
                        </TabPanel>
                    </Grid>
                </div>

            </div>

        </Container>
    );
}