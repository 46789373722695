import React, { Component } from 'react'
import {
    Container
} from '@material-ui/core'
import Btn from '../Components/Button'
import ProgressBar from '../Components/ProgressBar'
import Header from '../Components/Header'
import VjcetService from '../vjcet-service';
import Footer from '../Components/FormFooter';
import SubHeader from '../Components/SubHeader';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

export class PaymentDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: {},
            payment_ref: '',
            loading: false,
            success: "",
            failure: ""
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    back = (e) => {
        e.preventDefault();
        this.props.prevStep(VjcetService.STAGE_SUMMARY);
    }

    componentDidMount() {
        // const script = document.createElement("script");
        // script.async = true;
        // script.src = "https://checkout.razorpay.com/v1/checkout.js";

        // document.body.appendChild(script);
    }
    paymentHandler = (e) => {
        //console.log("key is " + process.env.REACT_APP_RAZOR_KEY);
        const { appInfo } = this.props;

        let full_name = appInfo.first_name + "" + appInfo.last_name;
        let email = appInfo.applicant_email + "";

        if (full_name.length === 0) {
            full_name = process.env.REACT_APP_PAYMENT_NAME;
        }

        if (email.length === 0) {
            email = "";
        }

        this.setState({ loading: true });

        let _that = this;

        e.preventDefault();
        const options = {
            key: process.env.REACT_APP_RAZOR_KEY,
            amount: process.env.REACT_APP_APPLICATION_FEE,
            currency: "INR",
            name: 'Application Fee',
            description: 'VJCET Application fee',
            // order_id: appInfo.application_id,
            handler(response) {


                const paymentId = response.razorpay_payment_id;
                const order_id = response.razorpay_order_id;
                const signature = response.razorpay_signature;
                const payment_amount = 500;

                VjcetService.submit_applicant_payment_info({ payment_id: paymentId }).then((resp) => {
                    const data = resp.data;
                    _that.setState({ loading: false });
                    if (data && data.success) {

                        _that.props.nextStep(VjcetService.STAGE_SUBMISSION);

                    } else {

                        _that.setState({ failure: data.info });
                        // console.log("error updating payment ")
                    }
                }).catch(err => {
                    if (err.response) {
                        _that.setState({ failure: " Failed request:  Saving Payment reference. Error : " + err.response.data.message });
                    } else if (err.request) {
                        console.log("error req " + err.request);
                    } else {
                        console.log("something else " + err);
                    }
                });


            },
            "modal": {
                "ondismiss": function () {
                    _that.setState({ loading: false });
                }
            },

            prefill: {
                name: full_name,
                email: email,
            },
            notes: {
                address: 'Kerala India',
            },
            theme: {
                color: '#790514',
            },
        };


        try {

            const rzp1 = new window.Razorpay(options);
            rzp1.open();
        } catch (err) {
            _that.setState({ failure: "Payment could not be processed. Please try again" + err.message });
        }

    }

    render() {
        const { appInfo, step, formValue } = this.props;
        return (
            <div id="wrapper" className="height">
                <Header name="container-style" form={formValue} />
                <Container className="container-style">
                    <SubHeader appInfo={appInfo} />
                    <ProgressBar step={step} />
                    <h2 className="sub-title1">Payment section</h2>
                    <p>
                        Thank you for preparing your application.  Please click on Pay Now to proceed with payment for your application.
					</p>


					
					{/* <p className="italic font-semibold mt-6">
					Note: Even though we have received your application, we will start the processing of the
					application only after the announcement of KEAM 2024 score. Once KEAM 2024 score is
					published, Management quota (Non NRI) applicants shall update the KEAM score and the
					related attachments in your login.
                    </p> */}
                    

                    {this.state.loading && <CircularProgress size={68} className="fabProgress" />}
                    {this.state.failure && <Alert variant="filled" severity="error">
                        {this.state.failure}
                    </Alert>}

                    <div className="btn-style">
                        <Btn title='Previous' click={this.back} />
                        <Btn title='Pay Now' click={this.paymentHandler} />
                    </div>

                </Container>
                <Footer />

            </div>


        )
    }
}




export default PaymentDetails
