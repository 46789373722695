import React, { Component } from 'react'
import Header from '../Components/Header'
import {
    Container,

} from '@material-ui/core';
import logo from '../images/logoVJCET.png'
import VjcetService from '../vjcet-service';
import Login from '../Components/Login';
import Alert from '@material-ui/lab/Alert';
import EmailContact from '../EmailTemplate/emailContact'


export class Email extends Component {

    state = {
        history: this.props.history,
        showMessage: false,
        errors: {},
        success: "",
        failure: ""
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token') || "";

        if (token.length > 0) {
            VjcetService.verify_email(token).then((resp) => {
                console.log('successly verified ');
                if (resp && resp !== undefined) {
                    const data = resp.data;
                    console.log('successly verified ');

                    if (data.success) {

                        this.setState({ showMessage: true });

                    } else {
                        this.setState({ failure: "Email verification Failed. Please contact the admissinons support team. Error : " + data.info })
                    }
                }
            }).catch(err => {
                if (err.response) {
                    this.setState({ failure: " Failed request: Email verification failure. Please contact the admissinons support team. Error : " + err.response.data.message });
                } else if (err.request) {
                    console.log("error req " + err.request);
                } else {
                    console.log("something else " + err);
                }
            });
        } else {
            this.setState({ failure: "Invalid token link. Please check link in the email or contact the Admin support team : " })
        }

    }

    handleClick() {

    }

    render() {


        return (

            <div id="email-verify" >

                <Container className="container-style" style={style1}>
                    <div className="logo-email">
                        <img src={logo} alt="logo" />
                    </div>
                    {this.state.showMessage ? (

                        <div>
                            < div style={pStyle} >
                                <h4>Your email has been verified.</h4>
                                <p>Please click below button to login. </p>
                            </div >

                            <div style={marginStyle}>
                                <div className="btn-style1">
                                    <Login history={this.state.history} />
                                </div>

                            </div>
                        </div>

                    ) : (
                            <p>Loading...</p>
                        )}
                    {this.state.failure && <Alert variant="filled" severity="error">
                        {this.state.failure}
                    </Alert>}
                    <EmailContact/>
                </Container>
            </div >




        )
    }
}

const style1 = {
    display: 'grid',
    gridTemplateColumns: '100vw',
    marginTop: "5vh",
}
const pStyle = {
    marginTop: "20vh",
}
const marginStyle = {
    marginTop: "20vh"
}
const btnStyle = {
    color: '#fff',
    backgroundColor: '#9d0055',
    minWidth: '150px',
    height: '50px',
    fontSize: '14px',
    fontWeight: '700',
    borderRadius: '30px',
    borderStyle: 'none'
}

export default Email
