import React from 'react';
import {
    ListItem, ListItemText, List

} from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Smartphone';
import './style.css'



export default function emailContact() {

    return (
        <div id="conatcts" className="email-list-style">
            <h4>For any queries please contact : </h4>
            <List id="contact-list-link">
                <ListItem>
                    <ListItemText>
                        Mr. Manu Jose<br />
                        <a href="tel:8281352211"><PhoneIcon style={{ fontSize: 15 }} />8281352211</a><br />
                        <a href="tel:8281652211"><PhoneIcon style={{ fontSize: 15 }} />8281652211</a>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        Mr. Babu T Chacko<br />
                        <a href="tel:9656154572"><PhoneIcon style={{ fontSize: 15 }} />9656154572</a>
                    </ListItemText>
                </ListItem>
            </List>
        </div>
    );
}
