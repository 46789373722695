import React, { Component } from 'react'
import {
    Container,
    TextField,
    InputAdornment,
    MenuItem

} from '@material-ui/core';
import Btn from '../Components/Button'
import ProgressBar from '../Components/ProgressBar'
import Icon from '@material-ui/core/Icon';
import Header from '../Components/Header'
import Footer from '../Components/FormFooter';
import SubHeader from '../Components/SubHeader';
import VjcetService from '../vjcet-service';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';


const courses = VjcetService.get_course_preferences();

export class CoursePreference extends Component {

    preference_timeout = null;

    constructor(props) {
        super(props);
        this.state = {
            error: this.props.errors,
            first_option: -1,
            second_option: -1,
            third_option: -1,
            fourth_option: -1,
            success: "",
            failure: ""
        }
    }

    validate = () => {

        const error = {};
        const { values } = this.props;
        let selections = [];

        if (values.first_option === undefined || values.first_option === "" || values.first_option <= 0) {
            error.first_option = "Please specify first option ";
        } else {
            selections.push(values.first_option);
        }

        if (values.second_option > 0) {
            if (selections.includes(values.second_option)) {
                error.second_option = "Course can only be selected once";
            } else {
                selections.push(values.second_option);
            }
        }

        if (values.third_option > 0) {
            if (selections.includes(values.third_option)) {
                error.third_option = "Course can only be selected once";
            } else {
                selections.push(values.third_option);
            }
        }

        if (values.fourth_option > 0) {
            if (selections.includes(values.fourth_option)) {
                error.fourth_option = "Course can only be selected once";
            } else {
                selections.push(values.fourth_option);
            }
        }

        return Object.keys(error).length === 0 ? null : error;
    }

    save_data = (continue_after_save) => {

        const { values } = this.props;

        const error = this.validate();
        this.setState({
            error
        });
        let error_timeout = setTimeout(() => this.setState({ error: {} }), 3000);
        if (error) return;


        if (!this.state.loading) {
            this.setState({ loading: true });
            this.preference_timeout = setTimeout(() => this.setState({ loading: false }), 10000);

            let data = [];
            if (values.first_option > 0) {
                data.push({ course_id: values.first_option, preference_order: 1 });
            }

            if (values.second_option > 0) {
                data.push({ course_id: values.second_option, preference_order: 2 });
            }

            if (values.third_option > 0) {
                data.push({ course_id: values.third_option, preference_order: 3 });
            }

            if (values.fourth_option > 0) {
                data.push({ course_id: values.fourth_option, preference_order: 4 });
            }

            VjcetService.submit_applicant_course_preferences({ options: data }).then((resp) => {

                let data = resp.data;
                if (data.success) {

                    if (error_timeout && error_timeout !== undefined) {
                        clearTimeout(error_timeout);
                    }

                    if (continue_after_save) {
                        this.props.nextStep(VjcetService.STAGE_DOCUMENTS);
                    } else {
                        this.props.logout();
                    }
                } else {
                    this.setState({ failure: "Error: Saving Course Preference. Error : " + data.info });
                }

            }).catch(err => {

                if (error_timeout && error_timeout !== undefined) {
                    clearTimeout(error_timeout);
                }

                if (err.response) {
                    this.setState({ failure: " Failed request: Saving Course Preference. Error : " + err.response.data.message });
                } else if (err.request) {
                    console.log("error req " + err.request);
                } else {
                    console.log("something else " + err);
                }
            });
        }
    }

    continue = (e) => {
        e.preventDefault();
        this.save_data(true);
    }

    save_and_exit = (e) => {
        e.preventDefault();
        this.save_data(false);
    }

    back = (e) => {
        e.preventDefault();
        this.props.prevStep(VjcetService.STAGE_EDUCATION);
    }

    componentWillUnmount() {
        if (this.preference_timeout && this.preference_timeout !== undefined) {
            clearTimeout(this.preference_timeout);
        }
    }

    render() {
        const { values, appInfo, updatePreferenceSelectValue, step, formValue } = this.props;
        const { error } = this.state;

        return (
            <div id="wrapper">
                <Header name="container-style" form={formValue} />
                <Container className="container-style" id="courses_container">
                    <SubHeader appInfo={appInfo} />
                    <ProgressBar step={step} />
                    <h3 className="sub-title1">Course Preferences</h3>
                    <div id="courses_preference">
                        <div className="form-fields">
                            <TextField
                                id="first_option"
                                name="first_option"
                                label="First Preference"
                                onChange={updatePreferenceSelectValue}
                                value={values.first_option}
                                required
                                select
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">calendar_today</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            >
                                {courses.map(option => (
                                    <MenuItem key={option.key} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {error && <div className="error-text">{error.first_option}</div>}

                        </div>
                        <div className="form-fields">
                            <TextField
                                id="second_option"
                                name="second_option"
                                label="Second Preference"
                                onChange={updatePreferenceSelectValue}
                                value={values.second_option}
                                select
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">calendar_today</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            >
                                {courses.map(option => (
                                    <MenuItem key={option.key} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {error && <div className="error-text">{error.second_option}</div>}
                        </div>
                        <div className="form-fields">
                            <TextField
                                id="third_option"
                                name="third_option"
                                label="Third Preference"
                                onChange={updatePreferenceSelectValue}
                                value={values.third_option}
                                select
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">calendar_today</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            >
                                {courses.map(option => (
                                    <MenuItem key={option.key} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {error && <div className="error-text">{error.third_option}</div>}
                        </div>
                        <div className="form-fields">
                            <TextField
                                id="fourth_option"
                                name="fourth_option"
                                label="Fourth Preference"
                                onChange={updatePreferenceSelectValue}
                                value={values.fourth_option}
                                select
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">calendar_today</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            >
                                {courses.map(option => (
                                    <MenuItem key={option.key} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {error && <div className="error-text">{error.fourth_option}</div>}
                        </div>
                    </div>

                    {this.state.loading && <LinearProgress />}

                    {this.state.failure && <Alert variant="filled" severity="error">
                        {this.state.failure}
                    </Alert>}
                    {this.state.success && <Alert variant="filled" severity="success" >
                        {this.state.success}
                    </Alert>}

                    <div className="btn-style">
                        <Btn title='Previous' click={this.back} />
                        <Btn title='Save and Exit' click={this.save_and_exit} />
                        <Btn title='Save and Proceed' click={this.continue} />
                    </div>
                </Container>
                <Footer />

            </div>


        )
    }
}



export default CoursePreference
