import React, { Component } from 'react'
import {
    Container,
    TextField,
    InputAdornment,
    Checkbox,
    FormControlLabel

} from '@material-ui/core';
import Btn from '../Components/Button'
import ProgressBar from '../Components/ProgressBar'
import Icon from '@material-ui/core/Icon';
import Header from '../Components/Header'
import Footer from '../Components/FormFooter'
import SubHeader from '../Components/SubHeader';
import VjcetService from '../vjcet-service';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';


export class ContactDetails extends Component {

    contact_timeout = null;
    state = {
        error: this.props.errors,
        success: "",
        failure: "",
        loading: false
    }


    validate = () => {

        const error = {};
        const { values } = this.props;


        if (values.home_address_1 === undefined || values.home_address_1 === '') {
            error.home_address_1 = "Address1 is required";
        }

        if (values.home_address_2 === "") {
            error.home_address_2 = "Address2 is required";
        }

        if (values.home_address_pincode === '') {
            error.home_address_pincode = "Pincode is required";
        }
        if (values.home_address_city === "") {
            error.home_address_city = "City is required";
        }
        if (values.home_address_district === "") {
            error.home_address_district = "District is required";
        }
        if (values.home_address_state === "") {
            error.home_address_state = "State is required";
        }
        if (values.home_address_country === "") {
            error.home_address_country = "Country is required";
        }
        if (values.communication_address_1 === undefined || values.communication_address_1 === "") {
            error.communication_address_1 = "Address1 is required";
        }
        if (values.communication_address_2 === "") {
            error.communication_address_2 = "Address2 is required";
        }

        if (values.communication_address_pincode === '') {
            error.communication_address_pincode = "Pincode is required";
        }
        if (values.communication_address_city === "") {
            error.communication_address_city = "City is required";
        }
        if (values.communication_address_district === "") {
            error.communication_address_district = "District is required";
        }
        if (values.communication_address_state === "") {
            error.communication_address_state = "State is required";
        }
        if (values.communication_address_country === "") {
            error.communication_address_country = "Country is required";
        }
        return Object.keys(error).length === 0 ? null : error;
    }



    save_data = (continue_after_save) => {

        const { values } = this.props;

        const error = this.validate();
        this.setState({
            error
        });
        let error_timeout = setTimeout(() => this.setState({ error: {} }), 3000);
        if (error) return;

        if (!this.state.loading) {

            const data = values;

            this.setState({ loading: true });

            this.contact_timeout = setTimeout(() => this.setState({ loading: false }), 10000);

            VjcetService.submit_applicant_contact_info(data).then((resp) => {

                let data = resp.data;
                if (data.success) {
                    if (error_timeout && error_timeout !== undefined) {

                        clearTimeout(error_timeout);
                    }
                    if (continue_after_save) {
                        this.props.nextStep(VjcetService.STAGE_GUARDIAN_DETAILS);
                    } else {
                        this.props.logout();
                    }
                } else {
                    this.setState({ failure: "Error: Saving Contact info. Error : " + data.info });
                }

            }).catch(err => {

                if (error_timeout && error_timeout !== undefined) {
                    clearTimeout(error_timeout);
                }

                if (err.response) {
                    this.setState({ failure: " Failed request: Saving Contact info. Error : " + err.response.data.message });
                } else if (err.request) {
                    console.log("error req " + err.request);
                } else {
                    console.log("something else " + err);
                }
            });
        }
    }

    continue = (e) => {
        e.preventDefault();
        this.save_data(true);
    }

    save_and_exit = (e) => {
        e.preventDefault();
        this.save_data(false);
    }

    back = (e) => {
        e.preventDefault();
        this.props.prevStep(VjcetService.STAGE_BASIC);
    }

    componentWillUnmount() {
        if (this.contact_timeout && this.contact_timeout !== undefined) {
            clearTimeout(this.contact_timeout);
        }
    }

    render() {
        const { values, appInfo, handleChange, cloneAddress, step, formValue } = this.props;
        const { error } = this.state;


        return (
            <div id="wrapper">
                <Header name="container-style" form={formValue} />
                <Container className="container-style" id="contact-container">
                    <SubHeader appInfo={appInfo} />
                    <ProgressBar step={step} />


                    <div id="contact-details" className="form-style">
                        <div className="permanent-add">
                            <div style={hStyle}>
                                <h3 className="sub-title1">Permanent Address</h3>
                            </div>

                            <TextField
                                label="Address line 1"
                                onChange={handleChange}
                                value={values.home_address_1}
                                name="home_address_1"
                                id="home_address_1"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">home</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text">{error.home_address_1}</div>}


                            <TextField
                                label="Address line 2"
                                onChange={handleChange}
                                value={values.home_address_2}
                                name="home_address_2"
                                id="home_address_2"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">location_on</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text"> {error.home_address_2}</div>}


                            <TextField
                                label="City"
                                onChange={handleChange}
                                value={values.home_address_city}
                                name="home_address_city"
                                id="home_address_city"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">location_on</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text"> {error.home_address_city}</div>}



                            <TextField
                                label="District"
                                onChange={handleChange}
                                value={values.home_address_district}
                                name="home_address_district"
                                id="home_address_district"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">location_on</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text"> {error.home_address_district}</div>}


                            <TextField
                                label="State"
                                onChange={handleChange}
                                value={values.home_address_state}
                                name="home_address_state"
                                id="home_address_state"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">location_on</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text"> {error.home_address_state}</div>}

                            <TextField
                                label="Pincode"
                                onChange={handleChange}
                                value={values.home_address_pincode}
                                name="home_address_pincode"
                                id="home_address_pincode"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">location_on</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text"> {error.home_address_pincode}</div>}

                            <TextField
                                label="Country"
                                onChange={handleChange}
                                value={values.home_address_country}
                                name="home_address_country"
                                id="home_address_country"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">location_on</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text"> {error.home_address_country}</div>}


                        </div>
                        <div className="communication-add">

                            <div style={hStyle}>
                                <h3 className="sub-title1">Communication Address</h3>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={cloneAddress}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    }
                                    label="Check if Commnuincation address is similar to Permanent Address"
                                    className="labelStyle"
                                />

                            </div>

                            <TextField
                                label="Address line 1"
                                onChange={handleChange}
                                value={values.communication_address_1}
                                name="communication_address_1"
                                id="communication_address_1"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">home</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text"> {error.communication_address_1}</div>}

                            <TextField
                                label="Address line 2"
                                onChange={handleChange}
                                value={values.communication_address_2}
                                name="communication_address_2"
                                id="communication_address_2"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">location_on</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text"> {error.communication_address_2}</div>}


                            <TextField
                                label="City"
                                onChange={handleChange}
                                value={values.communication_address_city}
                                name="communication_address_city"
                                id="communication_address_city"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">location_on</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text"> {error.communication_address_city}</div>}


                            <TextField
                                label="District"
                                onChange={handleChange}
                                value={values.communication_address_district}
                                name="communication_address_district"
                                id="communication_address_district"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">location_on</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text"> {error.communication_address_district}</div>}


                            <TextField
                                label="State"
                                onChange={handleChange}
                                value={values.communication_address_state}
                                name="communication_address_state"
                                id="communication_address_state"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">location_on</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text"> {error.communication_address_state}</div>}


                            <TextField
                                label="Pincode"
                                onChange={handleChange}
                                value={values.communication_address_pincode}
                                name="communication_address_pincode"
                                id="communication_address_pincode"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">location_on</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text"> {error.communication_address_pincode}</div>}

                            <TextField
                                label="Country"
                                onChange={handleChange}
                                value={values.communication_address_country}
                                name="communication_address_country"
                                id="communication_address_country"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">location_on</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text"> {error.communication_address_country}</div>}

                        </div>
                    </div>

                    {this.state.loading && <LinearProgress />}

                    {this.state.failure && <Alert variant="filled" severity="error">
                        {this.state.failure}
                    </Alert>}
                    {this.state.success && <Alert variant="filled" severity="success" >
                        {this.state.success}
                    </Alert>}

                    <div className="btn-style">
                        <Btn title='Previous' click={this.back} />
                        <Btn title='Save and Exit' click={this.save_and_exit} />
                        <Btn title='Save and Proceed' click={this.continue} />

                    </div>


                </Container>
                <Footer />

            </div>

        )
    }
}

const hStyle = {
    height: "16vh"
}




export default ContactDetails
