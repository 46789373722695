import React from 'react'
import Introduction from './Introduction'
import Admission from './Admission'
import Stories from './Stories'
import Placement from './Placement'
import Application from './Application'
import Footer from '../Components/Footer'
import Instruction from './Instructions'
import Header from '../Components/Header';


function Home(props) {
  const history = props.history;
  // console.log(history);
  return (
    <div className="App">

      <Header />
      <Introduction />
      <Admission />
      <Instruction />
      <Application history={history} />
      <Stories />
      <Placement />
      <Footer />


    </div>

  )
}

export default Home;