
import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../Components/Header'
import { Container } from '@material-ui/core';
import Footer from '../Components/Footer'
import './style.css'

const ContactUs = () => {
    return (
        <div>
            <Header />
            <Container className="margin-b">
                <div className="footer-pages-header">
                    <div className="dark-line"></div>
                    <h1 className="title-style">Contact Details</h1>
                </div>
                <section className="section-styles">

                    <h4>Address</h4>
                    <ul>
                        <li>Viswajyothi College of Engineering & Technology Vazhakulam,</li>
                        <li>Muvattupuzha,</li>
                        <li>Ernakulam,</li>
                        <li>Kerala,</li>
                        <li>India-686670</li>
                    </ul>
                </section>
                <section className="section-styles">
                    <h4>Email</h4>
                    <ul>
                        <li>vjcet@vjcet.org</li>
                        <li>vjcvklm@gmail.com</li>

                    </ul>

                </section>
                <section className="section-styles">
                    <h4>Our Phone</h4>
                    <ul>
                        <li>0485-2262211</li>
                        <li>0485-2262244</li>
                        <li>0485-2262255</li>
                        <li>0485-2262311</li>
                        <li>0485-2262977</li>

                    </ul>

                </section>
            </Container>
            <Footer />


        </div>
    )
}


export default ContactUs;