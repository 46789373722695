import React, { Component } from 'react'
import {
    Container,
    TextField,
    InputAdornment,
    MenuItem
} from '@material-ui/core';
import Btn from '../Components/Button'
import ProgressBar from '../Components/ProgressBar'
import Icon from '@material-ui/core/Icon';
import Header from '../Components/Header'
import Footer from '../Components/FormFooter';
import SubHeader from '../Components/SubHeader';
import VjcetService from '../vjcet-service';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';

const quali_exams = VjcetService.get_quali_exams();

const quali_board = VjcetService.get_quali_boards();

const months = VjcetService.get_months();

const quali_years = VjcetService.get_quali_years();
const keam_regex = /^\d+\.\d{4}$/;


export class EducationDetails extends Component {

    education_timeout = null;

    constructor(props) {
        super(props);
        this.state = {
            error: {},
            qualification_exam: -1,
            qualification_month: 0,
            qualification_yaer: 2020,
            success: "",
            failure: ""
        }
    }

    validate = () => {

        const error = {};
        const { values, appInfo } = this.props;

        let quali_exam = 0;

        if (values.qualification_exam === "" || values.qualification_exam <= 0) {
            error.qualification_exam = "Please specify the qualification exam ";
        } else {
            quali_exam = values.qualification_exam;
        }

        if (values.qualification_month === "" || values.qualification_month <= 0) {
            error.qualification_month = "Please specify month";
        }

        if (values.qualification_year === '' || values.qualification_year <= 0) {
            error.qualification_year = "Please specify year";
        }
        if (values.qualification_board === "" || values.qualification_board <= 0) {
            error.qualification_board = "Please choose qualification board";
        }
        if (values.qualification_registration_no === "") {
            error.qualification_registration_no = "Please specify Registration number";
        }
        if (values.physics_score === "") {
            error.physics_score = "Physics score is required";
        } else if (quali_exam === 1 && values.physics_score > 120) {
            error.physics_score = "Physics score cannot be more than 120";
        } else if (quali_exam !== 1 && values.physics_score > 100) {
            error.physics_score = "Please check that the Physics score and Qualifying exams are correct";
        }

        if (values.chemistry_score === "") {
            error.chemistry_score = "Chemistry score is required";
        } else if (quali_exam === 1 && values.chemistry_score > 120) {
            error.chemistry_score = "Chemistry score cannot be more than 120";
        } else if (quali_exam !== 1 && values.chemistry_score > 100) {
            error.chemistry_score = "Please check that the Chemistry score and Qualifying exams are correct";
        }

        if (values.maths_score === "") {
            error.maths_score = "Mathematics score is required";
		} else if (quali_exam === 1 && values.maths_score > 120) {
            error.maths_score = "Mathematics score cannot be more than 120";	
        } else if (quali_exam !== 1 && values.maths_score > 100) {
            error.maths_score = "Mathematics score cannot be more than 100";
        }

        if (values.school_name === "") {
            error.school_name = "School name is required";
        }
        if (values.school_district === "") {
            error.school_district = "District of school is required";
        }

        //checking quota_id before validation

        if (appInfo.quota_id === 1) {
            // if (values.keam_year === "") {
            //     error.keam_year = "KEAM Year is required";
            // }
            if (values.keam_application_no === '') {
                error.keam_application_no = "KEAM Application number is required";
            }
            if (values.keam_roll_no === "") {
                error.keam_roll_no = "KEAM Roll number is required";
            }
            if (values.paper_1_score === "") {
                error.paper_1_score = "KEAM score is required";
            } else if (values.paper_1_score >300) {
                error.paper_1_score = "KEAM maximum score is 300"
            } else if (!keam_regex.test(values.paper_1_score)) {
                error.paper_1_score = "Please enter the marks as in marksheet with 4 decimal places(e.g 587.1230)"
            }
            /*if (values.paper_2_score === "") {
                error.paper_2_score = "KEAM Paper2 is required";
            } else if (!keam_regex.test(values.paper_2_score)) {
                error.paper_2_score = "Please enter the marks as in marksheet with 4 decimal places (e.g 587.1230)"
            }*/

            /*if (values.keam_total_score === "") {
                error.keam_total_score = "KEAM Total score is required";
            }*/
        }

        return Object.keys(error).length === 0 ? null : error;
    }

    save_data = (continue_after_save) => {

        const { values } = this.props;

        const error = this.validate();
        this.setState({
            error
        });
        let error_timeout = setTimeout(() => this.setState({ error: {} }), 3000);
        if (error) return;

        if (!this.state.loading) {

            this.setState({ loading: true });
            this.education_timeout = setTimeout(() => this.setState({ loading: false }), 30000);

            const data = values;

            VjcetService.submit_applicant_education_info(data).then((resp) => {

                let data = resp.data;
                if (data.success) {

                    if (error_timeout && error_timeout !== undefined) {
                        clearTimeout(error_timeout);
                    }

                    if (continue_after_save) {
                        this.props.nextStep(VjcetService.STAGE_COURSE_PREFERENCE);
                    } else {
                        this.props.logout();
                    }
                } else {
                    this.setState({ failure: "Error: Saving Education Details. Error : " + data.info });
                    setTimeout(() => this.setState({ failure: "" }), 3000);
                }

            }).catch(err => {

                if (error_timeout && error_timeout !== undefined) {
                    clearTimeout(error_timeout);
                }

                if (err.response) {
                    this.setState({ failure: " Failed request:Saving Education Details. Error : " + err.response.data.message });
                } else if (err.request) {
                    console.log("error req " + err.request);
                } else {
                    console.log("something else " + err);
                }
            });
        }

    }

    continue = (e) => {
        e.preventDefault();

        this.save_data(true);
    }

    save_and_exit = (e) => {
        e.preventDefault();
        this.save_data(false);
    }

    back = (e) => {
        e.preventDefault();
        this.props.prevStep(VjcetService.STAGE_GUARDIAN_DETAILS);
    }

    selectChanged = (event) => {

        this.props.updateEducationSelectValue(event);
        // this.setState({ qualification_exam: event.target.value });
    }
    selectedMonthChanged = (event) => {

        this.setState({ qualification_month: event.target.value });
    }

    componentWillUnmount() {
        if (this.education_timeout && this.education_timeout !== undefined) {
            clearTimeout(this.education_timeout);
        }
    }


    render() {
        const { values, appInfo, handleChange, step, formValue } = this.props;
        const { error } = this.state;

        return (
            <div id="wrapper">
                <Header name="container-style" form={formValue} />
                <Container className="container-style">
                    <SubHeader appInfo={appInfo} />
                    <ProgressBar step={step} />
                    <div id="education-details" className="form-style">
                        <div className="board-exam-dt">
                            <h3 className="sub-title1">Board Exam Details</h3>
                            <div className="form-fields">
                                <TextField
                                    label="Qualifying Exam"
                                    onChange={this.selectChanged}
                                    name="qualification_exam"
                                    id="qualification_exam"
                                    required
                                    value={values.qualification_exam}
                                    select InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">bookmark</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                >
                                    {quali_exams.map(option => (
                                        <MenuItem key={option.key} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {error && <div className="error-text">{error.qualification_exam}</div>}
                            </div>
                            <div className="input-inline" >
                                <div className="form-fields">
                                    <TextField
                                        label="Qualifying Exam Month"
                                        onChange={this.selectChanged}
                                        value={values.qualification_month}
                                        name="qualification_month"
                                        id="qualification_month"
                                        required
                                        select InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Icon className="icon-style">calendar_today</Icon>
                                                </InputAdornment>
                                            ),
                                        }}
                                    >
                                        {months.map(option => (
                                            <MenuItem key={option.key} value={option.value}>
                                                {option.short_label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {error && <div className="error-text">{error.qualification_month}</div>}

                                </div>
                                <div className="form-fields">
                                    <TextField
                                        label="Qualifying Exam Year"
                                        onChange={this.selectChanged}
                                        value={values.qualification_year}
                                        name="qualification_year"
                                        id="qualification_year"
                                        required
                                        select InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Icon className="icon-style">calendar_today</Icon>
                                                </InputAdornment>
                                            ),
                                        }}
                                    >
                                        {quali_years.map(option => (
                                            <MenuItem key={option.key} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {error && <div className="error-text">{error.qualification_year}</div>}

                                </div>

                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Qualifying Exam Registration Number"
                                    onChange={handleChange}
                                    value={values.qualification_registration_no}
                                    name="qualification_registration_no"
                                    id="qualification_registration_no"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">description</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.qualification_registration_no}</div>}

                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Qualifying Exam Board"
                                    onChange={this.selectChanged}
                                    value={values.qualification_board}
                                    name="qualification_board"
                                    id="qualification_board"
                                    required
                                    select InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">calendar_today</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                >
                                    <MenuItem key="qb0" value="0">Select Qualification Board</MenuItem>
                                    {values.qualification_exam === 1 && (<MenuItem key="qb1" value={1}>DHSE</MenuItem>)}
                                    {values.qualification_exam === 1 && (<MenuItem key="qb2" value={2}>VHSE</MenuItem>)}
                                    {values.qualification_exam === 1 && (<MenuItem key="qb3" value={3}>THSE</MenuItem>)}
                                    {values.qualification_exam === 2 && (<MenuItem key="qb4" value={4}>CBSE</MenuItem>)}
                                    {values.qualification_exam === 3 && (<MenuItem key="qb5" value={5}>ICSE</MenuItem>)}
                                    {values.qualification_exam === 4 && (<MenuItem key="qb6" value={6}>Others</MenuItem>)}


                                    {/* {quali_board.map(option => (
                                        <MenuItem key={option.key} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))} */}
                                </TextField>
                                {error && <div className="error-text">{error.qualification_board}</div>}
                            </div>
                            {values.qualification_exam === 1 && (
                                <h3 style={{ color: "#000", fontWeight: 500, fontSize: "0.8rem", marginTop: "1 rem", fontStyle: "italic" }}>(*For HSE, Enter Second Year Marks)</h3>

                            )}

                            <div className="form-fields">
                                <TextField
                                    label="Physics Mark"
                                    onChange={handleChange}
                                    value={values.physics_score}
                                    name="physics_score"
                                    id="physics_score"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">description</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.physics_score}</div>}

                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Chemistry Mark"
                                    onChange={handleChange}
                                    value={values.chemistry_score}
                                    name="chemistry_score"
                                    id="chemistry_score"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">description</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.chemistry_score}</div>}

                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Mathematics Mark"
                                    onChange={handleChange}
                                    value={values.maths_score}
                                    name="maths_score"
                                    id="maths_score"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">description</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.maths_score}</div>}
                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="12th School Name"
                                    onChange={handleChange}
                                    value={values.school_name}
                                    name="school_name"
                                    id="school_name"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">description</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.school_name}</div>}

                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="School District"
                                    onChange={handleChange}
                                    value={values.school_district}
                                    name="school_district"
                                    id="school_district"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">description</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.school_district}</div>}

                            </div>

                        </div>
                        <div className="keam-exam-dt column-height">
                            <h3 className="sub-title1">KEAM Exam {VjcetService.APPLICATION_YEAR} Details</h3>
                            {appInfo.quota_id === VjcetService.QUOTA_NRI && (
                                <h3 style={{ color: "#000", fontWeight: 500, fontSize: "0.8rem", fontStyle: "italic" }}>(*KEAM Details are optional for NRI Quota)</h3>
                            )}
                            <div className="form-fields">
                                <TextField
                                    label="KEAM Application No*"
                                    onChange={handleChange}
                                    value={values.keam_application_no}
                                    name="keam_application_no"
                                    id="keam_application_no"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">bookmark</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.keam_application_no}</div>}

                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="KEAM Roll Number*"
                                    onChange={handleChange}
                                    value={values.keam_roll_no}
                                    name="keam_roll_no"
                                    id="keam_roll_no"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">bookmark</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.keam_roll_no}</div>}

                            </div>
                            <div className="form-fields">
							{/*<h3 style={{ color: "#000", fontWeight: 300, fontSize: "0.8rem", marginTop: "1 rem", fontStyle: "italic" }}>(#fill only after the announcement of KEAM Score)</h3> */}
                                <TextField
                                    label="#KEAM Normalized Score with 4 decimal places(e.g 247.1230)"
                                    onChange={handleChange}
                                    value={values.paper_1_score}
                                    name="paper_1_score"
                                    id="paper_1_score"
                                    error={(appInfo.quota_id === 2 || keam_regex.test(values.paper_1_score)) ? false : true}
									
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">description</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.paper_1_score}</div>}

                            </div>
                            {/*<div className="form-fields">
                                <TextField
                                    label="#KEAM Paper II Score with 4 decimal places(e.g 587.1230)"
                                    onChange={handleChange}
                                    value={values.paper_2_score}
                                    name="paper_2_score"
                                    id="paper_2_score"
									
                                    error={(appInfo.quota_id === 2 || keam_regex.test(values.paper_2_score)) ? false : true}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">description</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.paper_2_score}</div>}

                                </div>*/}
                            {/*<div className="form-fields">
                                <TextField
                                    label="KEAM Total Score"
                                    value={values.keam_total_score}
                                    name="keam_total_score"
                                    id="keam_total_score"
                                    aria-readonly
									
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">description</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.keam_total_score}</div>}
                            </div>*/}
                            <h3 style={{ color: "#000", fontWeight: 300, fontSize: "0.8rem", marginTop: "1 rem", fontStyle: "italic" }}>(* Marks should be entered exactly as given in the Entrance Mark list without
rounding-off)</h3>
							{/* <h3 style={{ color: "#000", fontWeight: 300, fontSize: "0.8rem", marginTop: "1 rem", fontStyle: "italic" }}>(☥ fill only after the announcement of KEAM Score)</h3> */}

                        </div>
                    </div>

                    {this.state.loading && <LinearProgress />}

                    {this.state.failure && <Alert variant="filled" severity="error">
                        {this.state.failure}
                    </Alert>}
                    {this.state.success && <Alert variant="filled" severity="success" >
                        {this.state.success}
                    </Alert>}

                    <div className="btn-style">
                        <Btn title='Previous' click={this.back} />
                        <Btn title='Save and Exit' click={this.save_and_exit} />
                        <Btn title='Save and Proceed' click={this.continue} />
                    </div>


                </Container>
                <Footer />
            </div>
        )
    }
}



export default EducationDetails
