import React, { Component } from 'react'
import {
    Container,

} from '@material-ui/core';
import Btn from '../Components/Button'
import ProgressBar from '../Components/ProgressBar'

import Header from '../Components/Header'
import Footer from '../Components/FormFooter'
import VjcetService from '../vjcet-service';
import Contact from '../images/icons/contact.svg';
import SubHeader from '../Components/SubHeader';
import PhoneIcon from '@material-ui/icons/Smartphone';
import Skeleton from '@material-ui/lab/Skeleton';

const months_array = VjcetService.get_months_array();
const quali_boards_array = VjcetService.get_quali_boards_array();
const quali_exams_array = VjcetService.get_quali_exams_array();
const courses_array = VjcetService.get_courses_array();
const religion_array = VjcetService.get_religion_array();
const gender_array = VjcetService.get_gender_array();
const quota_labels = VjcetService.get_quota_array();
export class Summary extends Component {


    constructor(props) {
        super(props);
        this.state = {
            error: {},
            success: "",
            failure: ""
        }
    }

    continue = (e) => {
        e.preventDefault();
        this.props.nextStep(VjcetService.STAGE_PAYMENTS);
    }

    back = (e) => {
        e.preventDefault();
        this.props.prevStep(VjcetService.STAGE_DOCUMENTS);
    }

    download_application = (e) => {
        e.preventDefault();
        VjcetService.download_summary().then((resp) => {
            let data = resp.data;
            if (data.success) {
                VjcetService.open_download_doc(data.link);
            } else {
                this.setState({ failure: "Error: Opening Summary Documents. Error : " + data.info });
                setTimeout(() => this.setState({ failure: "" }), 3000);
            }

        }).catch(err => {
            if (err.response) {
                this.setState({ failure: " Failed request: Opening Summary document. Error : " + err.response.data.message });
                setTimeout(() => this.setState({ failure: "" }), 3000);

            } else if (err.request) {
                console.log("error req " + err.request);
            } else {
                console.log("something else " + err);
            }
        });
    }


    render() {
        const { values, step, formValue } = this.props;
        const basicData = values.basicData;
        const contact = values.contact;
        const guardian = values.guardian;
        const education = values.education;
        const courses = values.courses;
        const documents = values.documents;

        return (
            <div id="wrapper">
                <Header name="container-style" form={formValue} />
                <Container className="container-style">
                    <SubHeader appInfo={basicData} />
                    <ProgressBar step={step} />
                    <h3 className="text-style1" style={h3Style}>Please review your application before submitting payment</h3>
                    <div className="summaryHeader">
                        <div>
                            <p className="text-style1" style={{ marginBottom: "5px" }}>
                                Your Application Number is :
                                <span className="highlight"> {basicData.application_id}</span>
                            </p>
                            <p className="text-style1" style={{ marginBottom: "5px" }}>
                                Application under :
                                    <span className="highlight">
                                    {(quota_labels[basicData.quota_id] && quota_labels[basicData.quota_id] !== undefined) ?
                                        quota_labels[basicData.quota_id] : "N/A"
                                    } Quota
                                </span>
                            </p>
                            <Btn title='Download PDF' click={this.download_application} />
                        </div>
                        <div>
                            {basicData.photo_url ? (
                                <img style={{ width: 150, height: 150, marginBottom: "10px", }} alt="profile" src={basicData.photo_url} />
                            ) : (
                                    <Skeleton variant="rect" width={150} height={150} />
                                )}
                        </div>
                    </div>


                    <div id="basic-data-summary" className="summary-style">
                        <h4><img src={Contact} alt="logo" style={{ width: "50px", paddingRight: "5px" }} /> Personal Information</h4>
                        <ul className="summary-list-style">
                            <li>Applicant Name</li>
                            <li>
                                <span className="text-upper">{basicData.first_name} {basicData.middle_name} {basicData.last_name}</span>

                            </li>
                            <li>DOB</li>
                            <li>
                                {(basicData.dob && basicData.dob !== undefined) ?
                                    new Intl.DateTimeFormat("en-In").format(new Date(basicData.dob)) : "N/A"
                                }
                            </li>
                            <li>Gender</li>
                            <li className="text-upper">
                                {(gender_array[basicData.gender] && gender_array[basicData.gender] !== undefined) ?
                                    gender_array[basicData.gender] : "N/A"
                                }
                            </li>
                            <li>Parent Mobile</li>
                            <li>{guardian.father_mobile ? guardian.father_mobile : guardian.mother_mobile}</li>
                            <li>Applicant Mobile</li>
                            <li>{basicData.applicant_mobile}</li>
                            <li>Religion</li>
                            <li className="text-upper">
                                {(religion_array[basicData.religion_id] && religion_array[basicData.religion_id] !== undefined) ?
                                    religion_array[basicData.religion_id] : "N/A"
                                }
                            </li>
                            <li>Community</li>
                            <li className="text-upper">
                                {(basicData.community && basicData.community !== undefined) ?
                                    basicData.community : "Not Specified"
                                }
                            </li>
                        </ul>

                    </div>
                    <div id="contact-data-summary" className="summary-style">
                        <h4><img src={Contact} alt="logo" style={{ width: "50px", paddingRight: "5px" }} />Contact Information</h4>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                            <div>
                                <p style={pStyle}>Home</p>
                                <ul id="home-summary" className="summary-list-style">
                                    <li>Address Line 1</li>
                                    <li className="text-upper">{contact.home_address_1}</li>
                                    <li>Address Line 2</li>
                                    <li className="text-upper">{contact.home_address_2}</li>
                                    <li>City</li>
                                    <li className="text-upper">{contact.home_address_city}</li>
                                    <li>District</li>
                                    <li className="text-upper">{contact.home_address_district}</li>
                                    <li>Pincode</li>
                                    <li className="text-upper">{contact.home_address_pincode}</li>
                                    <li>State</li>
                                    <li className="text-upper">{contact.home_address_state}</li>
                                    <li>Country</li>
                                    <li className="text-upper">{contact.home_address_country}</li>
                                </ul>
                            </div>
                            <div>
                                <p style={pStyle}>Communication</p>
                                <ul id="contact-summary" className="summary-list-style">
                                    <li>Address Line 1</li>
                                    <li className="text-upper">{contact.communication_address_1}</li>
                                    <li>Address Line 2</li>
                                    <li className="text-upper">{contact.communication_address_2}</li>
                                    <li>City</li>
                                    <li className="text-upper">{contact.communication_address_city}</li>
                                    <li>District</li>
                                    <li className="text-upper">{contact.communication_address_district}</li>
                                    <li>Pincode</li>
                                    <li className="text-upper">{contact.communication_address_pincode}</li>
                                    <li>State</li>
                                    <li className="text-upper">{contact.communication_address_state}</li>
                                    <li>Country</li>
                                    <li className="text-upper">{contact.communication_address_country}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div id="guardian-data-summary" className="summary-style">
                        <h4><img src={Contact} alt="logo" style={{ width: "50px", paddingRight: "5px" }} />Guardian Information</h4>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                            <div>
                                <p style={pStyle}>Parents Information</p>
                                <ul id="parent-info" className="summary-list-style">
                                    <li>Father's Name</li>
                                    <li className="text-upper">{guardian.father_name}</li>
                                    <li>Father's Occupation</li>
                                    <li className="text-upper">{guardian.father_occupation}</li>
                                    <li>Father's Contact Number</li>
                                    <li className="text-upper">{guardian.father_mobile}</li>
                                    <li>Mother's Name</li>
                                    <li className="text-upper">{guardian.mother_name}</li>
                                    <li>Mother's Occupation</li>
                                    <li className="text-upper">{guardian.mother_occupation}</li>
                                    <li>Mother's Contact Number</li>
                                    <li>{guardian.mother_mobile}</li>
                                    <li>Annual Income</li>
                                    <li>{guardian.annual_income}</li>
                                </ul>
                            </div>
                            <div>
                                <p style={pStyle}>Guardian Details</p>
                                <ul id="guardian-info" className="summary-list-style">
                                    <li>Guardian Name</li>
                                    <li className="text-upper">
                                        {(guardian.guardian_name && guardian.guardian_name !== undefined) ?
                                            guardian.guardian_name : "Not Specified"
                                        }
                                    </li>
                                    <li>Relationship to Applicant</li>
                                    <li className="text-upper">
                                        {(guardian.guardian_relationship && guardian.guardian_relationship !== undefined) ?
                                            guardian.guardian_relationship : "Not Specified"
                                        }
                                    </li>
                                    <li>Guradian Conatct Number</li>
                                    <li className="text-upper">
                                        {(guardian.guardian_mobile && guardian.guardian_mobile !== undefined) ?
                                            guardian.guardian_mobile : "Not Specified"
                                        }
                                    </li>
                                    <li>Guardian Address1</li>
                                    <li className="text-upper">
                                        {(guardian.guardian_address_1 && guardian.guardian_address_1 !== undefined) ?
                                            guardian.guardian_address_1 : "Not Specified"
                                        }
                                    </li>
                                    <li>Guardian Address2</li>
                                    <li className="text-upper">
                                        {(guardian.guardian_address_2 && guardian.guardian_address_2 !== undefined) ?
                                            guardian.guardian_address_2 : "Not Specified"
                                        }
                                    </li>
                                    <li>Guardian City</li>
                                    <li className="text-upper">
                                        {(guardian.guardian_address_city && guardian.guardian_address_city !== undefined) ?
                                            guardian.guardian_address_city : "Not Specified"
                                        }
                                    </li>
                                    <li>Postcode</li>
                                    <li className="text-upper">
                                        {(guardian.guardian_address_pincode && guardian.guardian_address_pincode !== undefined) ?
                                            guardian.guardian_address_pincode : "Not Specified"
                                        }
                                    </li>
                                    <li>District</li>
                                    <li className="text-upper">
                                        {(guardian.guardian_address_district && guardian.guardian_address_district !== undefined) ?
                                            guardian.guardian_address_district : "Not Specified"
                                        }
                                    </li>
                                    <li>State</li>
                                    <li className="text-upper">
                                        {(guardian.guardian_address_state && guardian.guardian_address_state !== undefined) ?
                                            guardian.guardian_address_state : "Not Specified"
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div id="academic-summary" className="summary-style">
                        <h4><img src={Contact} alt="logo" style={{ width: "50px", paddingRight: "5px" }} />Academic Information</h4>
                        <ul className="summary-list-style">

                            <li>Qualifying Exam</li>
                            <li className="text-upper">
                                {(quali_exams_array[education.qualification_exam] && quali_exams_array[education.qualification_exam] !== undefined) ?
                                    quali_exams_array[education.qualification_exam] : "N/A"
                                }
                            </li>
                            <li>Month and Year</li>
                            <li className="text-upper">
                                {(months_array[education.qualification_month] && months_array[education.qualification_month] !== undefined) ?
                                    months_array[education.qualification_month] : "N/A"
                                }, {education.qualification_year}
                            </li>
                            <li>University/Board</li>
                            <li className="text-upper">
                                {(quali_boards_array[education.qualification_board] && quali_boards_array[education.qualification_board] !== undefined) ?
                                    quali_boards_array[education.qualification_board] : "N/A"
                                }
                            </li>

                            <li>Register Number</li>
                            <li className="text-upper">{education.qualification_registration_no}</li>
                            <li>Marks- MATHEMATICS</li>
                            <li>{education.maths_score}</li>
                            <li>Marks- PHYSICS</li>
                            <li>{education.physics_score}</li>
                            <li>Marks- CHEMISTRY / EQUIVALENT</li>
                            <li>{education.chemistry_score}</li>
                            <li>12th School name</li>
                            <li className="text-upper">{education.school_name}</li>
                            <li>School District</li>
                            <li className="text-upper">{education.school_district}</li>
                        </ul>
                    </div>
                    <div id="keam-detl-summary" className="summary-style">
                        <h4><img src={Contact} alt="logo" style={{ width: "50px", paddingRight: "5px" }} />KEAM Entrance Test { VjcetService.APPLICATION_YEAR } Details</h4>
                        {basicData.quota_id === VjcetService.QUOTA_NRI && (
                            <h3 style={{ color: "#000", fontWeight: 500, fontSize: "0.8rem", fontStyle: "italic" }}>(*KEAM Details are optional for NRI Quota)</h3>

                        )}
                        <ul id="keam-summary" className="summary-list-style" >
                            <li>Application No</li>
                            <li className="text-upper">
                                {(education.keam_application_no && education.keam_application_no !== undefined) ?
                                    education.keam_application_no : "Not Specified"
                                }
                            </li>
                            <li>Roll No</li>
                            <li className="text-upper">
                                {(education.keam_roll_no && education.keam_roll_no !== undefined) ?
                                    education.keam_roll_no : "Not Specified"
                                }
                            </li>
                            <li>KEAM Normalized Score</li>
                            <li className="text-upper">
                                {(education.paper_1_score && education.paper_1_score !== undefined && education.paper_1_score > -1) ?
                                    education.paper_1_score : "Not Specified"
                                }
                            </li>
                            {/*<li>Paper II - Marks</li>
                            <li className="text-upper">
                                {(education.paper_2_score && education.paper_2_score !== undefined && education.paper_2_score > -1) ?
                                    education.paper_2_score : "Not Specified"
                                }
                            </li>*/}
                            {/*<li>Total Marks</li>
                            <li className="text-upper">
                                {(education.keam_total_score && education.keam_total_score !== undefined && education.keam_total_score > -1) ?
                                    education.keam_total_score : "Not Specified"
                                }
                            </li>*/}
                        </ul>
                    </div>
                    <div id="courses_summary" className="summary-style">
                        <h4><img src={Contact} alt="logo" style={{ width: "50px", paddingRight: "5px" }} />COURSE PREFERENCE</h4>
                        <ul className="summary-list-style">
                            <li>1st Preference : </li>
                            <li className="text-upper">
                                {(courses_array[courses.first_option] && courses_array[courses.first_option] !== undefined) ?
                                    courses_array[courses.first_option] : "N/A"
                                }
                            </li>
                            <li>2nd Preference : </li>
                            <li className="text-upper">
                                {(courses_array[courses.second_option] && courses_array[courses.second_option] !== undefined) ?
                                    courses_array[courses.second_option] : "N/A"
                                }
                            </li>
                            <li>3rd Preference : </li>
                            <li className="text-upper">
                                {(courses_array[courses.third_option] && courses_array[courses.third_option] !== undefined) ?
                                    courses_array[courses.third_option] : "N/A"
                                }
                            </li>
                            <li>4th Preference : </li>
                            <li className="text-upper">
                                {(courses_array[courses.fourth_option] && courses_array[courses.fourth_option] !== undefined) ?
                                    courses_array[courses.fourth_option] : "N/A"
                                }
                            </li>
                        </ul>
                    </div>
                    <div id="document_summary" className="summary-style">
                        <h4>DOCUMENT INFORMATION</h4>
                        {basicData.quota_id === VjcetService.QUOTA_NRI && (
                            <h3 style={{ color: "#000", fontWeight: 500, fontSize: "0.8rem", fontStyle: "italic" }}>(*Entrance marklist and Admit card documents are optional for NRI Quota)</h3>
                        )}
                        <ul className="summary-list-style">

                            <li>Applicant Signature</li>
                            <li>
                                {documents.application_signature_url.length > 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-uploaded">Uploaded</span>
                                    <a rel="noopener noreferrer" target="_blank" href={documents.application_signature_url}>View</a>
                                </div>
                                )}
                                {documents.application_signature_url.length === 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-upload">Not Uploaded</span>

                                </div>
                                )}
                            </li>
                            <li>Parent Signature</li>
                            <li>
                                {documents.parent_signature_url.length > 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-uploaded">Uploaded</span>
                                    <a rel="noopener noreferrer" target="_blank" href={documents.parent_signature_url}>View</a>
                                </div>
                                )}
                                {documents.parent_signature_url.length === 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-upload">Not Uploaded</span>

                                </div>
                                )}
                            </li>
                            <li>10th Certificate</li>
                            <li>
                                {documents.tenth_certificate_url.length > 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-uploaded">Uploaded</span>
                                    <a rel="noopener noreferrer" target="_blank" href={documents.tenth_certificate_url}>View</a>
                                </div>
                                )}
                                {documents.tenth_certificate_url.length === 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-upload">Not Uploaded</span>

                                </div>
                                )}
                            </li>
                            <li>12th Certificate</li>
                            <li>
                                {documents.twelth_certificate_url.length > 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-uploaded">Uploaded</span>
                                    <a rel="noopener noreferrer" target="_blank" href={documents.twelth_certificate_url}>View</a>
                                </div>
                                )}
                                {documents.twelth_certificate_url.length === 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-upload">Not Uploaded</span>

                                </div>
                                )}
                            </li>
                            {basicData.quota_id !== 2 && (<li>Entrance Marklist</li>)}
                            {basicData.quota_id === 2 && (<li>Entrance Marklist (optional)</li>)}
                            <li>
                                {documents.entrance_marklist_url.length > 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-uploaded">Uploaded</span>
                                    <a rel="noopener noreferrer" target="_blank" href={documents.entrance_marklist_url}>View</a>
                                </div>
                                )}
                                {documents.entrance_marklist_url.length === 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-upload">Not Uploaded</span>

                                </div>
                                )}
                            </li>
                            {basicData.quota_id !== 2 && (<li>Entrance Exam Admit card</li>)}
                            {basicData.quota_id === 2 && (<li>Entrance Exam Admit card (optional)</li>)}
                            <li>
                                {documents.entrance_exam_admit_card_url.length > 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-uploaded">Uploaded</span>
                                    <a rel="noopener noreferrer" target="_blank" href={documents.entrance_exam_admit_card_url}>View</a>
                                </div>
                                )}
                                {documents.entrance_exam_admit_card_url.length === 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-upload">Not Uploaded</span>

                                </div>
                                )}
                            </li>
                            <li>Community Certificate</li>
                            <li>
                                {documents.community_certificate_url.length > 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-uploaded">Uploaded</span>
                                    <a rel="noopener noreferrer" target="_blank" href={documents.community_certificate_url}>View</a>
                                </div>
                                )}
                                {documents.community_certificate_url.length === 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-upload">Not Uploaded</span>

                                </div>
                                )}
                            </li>

                        </ul>
                    </div>
                    <div id="declaration" className="summary-style">
                        <h4>Declaration</h4>
                        <p align="justify">
                            I, <span className="text-upper">{basicData.first_name} {basicData.middle_name} {basicData.last_name}</span> do hereby declare that all the information furnished above are true and I do understand that mis-representation of
                            any facts or lack of necessary documents may lead to the rejection of this application. I am ready to join the branch available in the
                            option order as furnished above. I understand that I should submit all ceftificates and documents in orginal at the time of admission,
                            failure of which my admission is liable for cancellation.
                        </p>
                        <ul className="summary-list-style">

                            <li>Applicant Signature</li>
                            <li>
                                {documents.application_signature_url.length > 0 && (
                                    <img style={{ width: 150, height: 150, marginBottom: "10px", }} alt="profile" src={documents.application_signature_url} />
                                )}
                                {documents.application_signature_url.length === 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-upload">Not Uploaded</span>

                                </div>
                                )}
                            </li>
                            <li>Parent Signature</li>
                            <li>
                                {documents.parent_signature_url.length > 0 && (
                                    <img style={{ width: 150, height: 150, marginBottom: "10px", }} alt="profile" src={documents.parent_signature_url} />
                                )}
                                {documents.parent_signature_url.length === 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-upload">Not Uploaded</span>

                                </div>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p className="text-style1">
                            We will review your application. For any queries regarding the application, please contact us via Email at
                            <a href="mailto:admission@vjcet.org " style={linkStyle}><span className="highlight"> admission@vjcet.org </span></a>
                            or <br />Phone<PhoneIcon style={{ fontSize: 15 }} />
                            <a href="tel:9447465399" style={linkStyle}><span className="highlight">9447465399</span></a>,
                            <a href="tel:9656154572" style={linkStyle}><span className="highlight">9656154572</span></a>,
                            <a href="tel:8281352211" style={linkStyle}><span className="highlight">8281352211</span></a>,
                            <a href="tel:8281652211" style={linkStyle}><span className="highlight">8281652211</span></a>
                        </p>
                    </div>

                    <div className="btn-style">
                        <Btn title='Download PDF' click={this.download_application} />
                        <Btn title='Previous' click={this.back} />
                        <Btn title='Confirm & Pay' click={this.continue} />
                    </div>
                </Container>
                <Footer />

            </div >


        )
    }
}

const h3Style = {
    marginTop: "8vh",
    marginBottom: "10px"
}
const pStyle = {
    paddingTop: "10px",
    paddingBottom: "10px",
    fontWeight: 600
}
const linkStyle = {
    textDecoration: "none"
}



export default Summary
