import React from 'react';
import { Container, List, ListItem, ListItemText } from '@material-ui/core';
import course from '../images/course.svg';
import contacts from '../images/contacts.svg';
import details from '../images/details.svg';
import PhoneIcon from '@material-ui/icons/Smartphone';
import ViewDayIcon from '@material-ui/icons/CalendarViewDay';
import LayersIcon from '@material-ui/icons/Layers';
import NewCourse from '../Components/NewCourse';

import './style.css';

const Admission = () => {
	return (
		<div className="bg-style">
			<div id="admission">
				<div className="courses rounded-lg">
					<div className="img-center card-head-style">
						<h3>
							<LayersIcon />
							COURSES OFFERED
						</h3>
					</div>
					<div>
						<List>
							<ListItem>
								<ListItemText primary="Artificial Intelligence & Data Science" />
							</ListItem>
							<ListItem>
								<ListItemText primary="Computer Science & Design" />
							</ListItem>
							<ListItem>
								<ListItemText primary="Civil Engineering*" />
							</ListItem>
							<ListItem>
								<ListItemText primary="Computer Science & Engineering*" />
							</ListItem>
							<ListItem>
								<ListItemText primary="Electronics & Communication Engineering*" />
							</ListItem>
							<ListItem>
								<ListItemText primary="Electrical & Electronics Engineering" />
							</ListItem>	
							<ListItem>
								<ListItemText primary="Information Technology*" />
							</ListItem>
							<ListItem>
								<ListItemText primary="Mechanical Engineering*" />
							</ListItem>
						
						</List>
						<span className="italic text-xs font-semibold">* NBA Accredited Courses.</span>
						<br />
						{/* <span className="italic text-xs font-semibold">✝ Subjected to the approval from Govt. of Kerala.</span> */}
					</div>
				</div>
				<div className="admission-dtl overlay-box rounded-lg">
					<div className="img-center card-head-style">
						<h3>
							<ViewDayIcon />
							ADMISSION DETAILS
						</h3>
					</div>
					<div>
						<List>
							<ListItem>
								<ListItemText className="text-justify" primary="B. Tech Branches – Civil Engineering, Computer Science & Engineering, Electronics & Communication Engineering, Information Technology and Mechanical Engineering - are Accredited by NBA." />
							</ListItem>

							<ListItem>
								<ListItemText
									className="text-justify"
									primary=" NBA accreditation is a benchmark of international quality in engineering studies. Our B. Tech programmes are at par with the degrees of International Technological Universities for higher education and global placements."
								/>
							</ListItem>

							<ListItem>
								<ListItemText
									className="text-justify"
									primary="For Government quota admission, candidates are advised to visit website regularly."
								/>
							</ListItem>
							<ListItem>
								<span>
									<a
										rel="noopener noreferrer"
										href="https://cee.kerala.gov.in"
										target="_blank"
										title="Cee Kerala Website"
									>
										https://cee.kerala.gov.in
									</a>
								</span>
							</ListItem>
						</List>
					</div>
				</div>
				<div className="admission-enq rounded-lg">
					<div className="img-center card-head-style">
						<h3>
							<PhoneIcon /> ADMISSION ENQUIRIES
						</h3>
					</div>

					<List>
						<ListItem>
							<ListItemText>
								Mr. Manu Jose
								<br />
								<a href="tel:8281352211">
									<PhoneIcon style={{ fontSize: 15 }} />
									8281352211
								</a>
								<br />
								<a href="tel:8281652211">
									<PhoneIcon style={{ fontSize: 15 }} />
									8281652211
								</a>
							</ListItemText>
						</ListItem>
						<ListItem>
							<ListItemText>
								Mr. Babu T Chacko
								<br />
								<a href="tel:9656154572">
									<PhoneIcon style={{ fontSize: 15 }} />
									9656154572
								</a>
							</ListItemText>
						</ListItem>
						<ListItem>
							<ListItemText>
								Mr. Sunny Jacob, Administrative Officer
								<br />
								<a href="tel:9447465399">
									<PhoneIcon style={{ fontSize: 15 }} /> 9447465399
								</a>{' '}
								(NRI Enquiries)
							</ListItemText>
						</ListItem>
					</List>
				</div>
			</div>
		</div>
	);
};

export default Admission;
