import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Btn from '../Button';
import './style.css';
import SendIcon from '@material-ui/icons/Send';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import VjcetService from '../../vjcet-service';

export default class InstructionModal extends Component {
	state = {
		modal: { open: false },
	};

	handleOpen = () => {
		this.setState({
			modal: { open: true },
		});
	};

	handleClose = () => {
		this.setState({
			modal: { open: false },
		});
	};

	render() {
		const { modal } = this.state;
		return (
			<div>
				<Btn title="Instructions" click={this.handleOpen} shadow="none" />
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className="modal"
					open={modal.open}
					onClose={this.handleClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={modal.open} className="paper">
						<div id="online-instructions">
							<h3>
								Online Application Submission - Instructions{' '}
								<span className="highlight">(Read Instructions carefully before Applying Online)</span>
							</h3>
							<List className="main-list">
								<ListItem>
									<ListItemIcon>
										<SendIcon />
									</ListItemIcon>
									<ListItemText primary="Applicant should have a valid email ID to fill the application form online."></ListItemText>
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<SendIcon />
									</ListItemIcon>
									<ListItemText>
										Applicant need to create an account using a{' '}
										<span className="highlight">valid email ID</span>. Please note down the{' '}
										<span className="highlight">user ID </span> (a valid email id provided by the
										Applicant) and the <span className="highlight">password</span> for editing the
										application later.
									</ListItemText>
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<SendIcon />
									</ListItemIcon>
									<ListItemText>
										Fill each section and click <span className="highlight">Save and Proceed</span>{' '}
										button to proceed to next section. Data will be saved after each section. Once
										Payment is submitted, you cannot edit the application.
										<br />
										Use the <span className="highlight">Save and Exit</span> button to save the
										current section and log out. You can login at a later time to complete the
										remaining sections.
									</ListItemText>
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<SendIcon />
									</ListItemIcon>
									<ListItemText primary="Applicant may edit the application by using your login details before you finally submit it."></ListItemText>
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<SendIcon />
									</ListItemIcon>
									<ListItemText>
										Applicant must pay the application fee of{' '}
										<span className="highlight">Rs.500/-</span> via Net banking/Credit Card/Debit
										Card while applying online.
									</ListItemText>
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<SendIcon />
									</ListItemIcon>
									<ListItemText primary="Applicant should upload his/her recent passport size photo (Selfies/casual pictures are not entertained)."></ListItemText>
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<SendIcon />
									</ListItemIcon>
									<ListItemText>
										Applicant must upload the scanned copy of following documents (jpeg, pdf or doc
										formats) (each file should not exceed 2MB).<br/>
										<span className="text-base font-semibold text-red-500">Please upload clear and legible copies of the requested documents (Photos of documents with unnecessary backgrounds will be rejected).</span>
										<ul className="sub-list">
											<li>Proof of Date of Birth (10th Certificate or Birth Certificate)</li>
											<li>Signature of Parent</li>
											<li>Signature of Applicant</li>
											<li>+2 Mark list</li>
											<li>KEAM {VjcetService.APPLICATION_YEAR} Admit Card</li>
											<li>KEAM {VjcetService.APPLICATION_YEAR} Score Sheet</li>
											<li>Proof of Community Quota</li>
											<p className="mute-text">
												(* All Christian denominations are eligible to be considered under
												Community Quota. Applicant can claim the same by uploading a letter from
												parish Priest/Village officer)
											</p>
											
										</ul>
									</ListItemText>
									
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<SendIcon />
									</ListItemIcon>
									<ListItemText primary="Note down the Application Number intimated through email on successful submission." />
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<SendIcon />
									</ListItemIcon>
									<ListItemText primary="Applicant need not send the printout of the Online Application Form via post." />
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<SendIcon />
									</ListItemIcon>
									<ListItemText>
										For assistance contact:
										<p>
											<span className="highlight">
												9656154572,9847101711,9605760293,9495654321.
											</span>{' '}
										</p>
									</ListItemText>
								</ListItem>
							</List>
						</div>
					</Fade>
				</Modal>
			</div>
		);
	}
}
