
import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../Components/Header'
import { Container } from '@material-ui/core';
import Footer from '../Components/Footer'
import './style.css'

const Refund = () => {
    return (
        <div>
            <Header />
            <Container className="margin-b">
                <div className="footer-pages-header">
                    <div className="dark-line"></div>
                    <h1 className="title-style">Refund Policy</h1>
                </div>
                <section className="section-styles">
                    <h4>Returns</h4>
                    <p>
                        The application fee is non-refundable.
                    </p>
                </section>
                <section className="section-styles">
                    <h4>Refunds (if applicable)</h4>
                    <p>
                        The application fee is non-refundable.
                    </p>

                </section>
                <section className="section-styles">
                    <h4>Late or missing refunds (if applicable)</h4>
                    <p>
                        N/A
                    </p>
                </section>
                <section className="section-styles">
                    <h4>Sale items (if applicable)</h4>
                    <p>
                        N/A
                    </p>
                </section>
                <section className="section-styles">
                    <h4>Shipping</h4>
                    <p>
                        N/A
                    </p>
                </section>
            </Container>
            <Footer />


        </div>
    )
}


export default Refund;