import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
	TextField,
	MenuItem,
	FormControl,
	FormLabel,
	FormControlLabel,
	RadioGroup,
	Radio,
	Button,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Btn from 'Components/Button';
import Icon from '@material-ui/core/Icon';
import './style.css';
import Joi from 'joi-browser';
import VjcetService from '../../vjcet-service';
import Alert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import Header from 'Components/Header';
import ReCAPTCHA from 'react-google-recaptcha';

export default class RegisterInterest extends Component {
	signup_timeout = null;

	state = {
		account: {
			first_name: '',
			last_name: '',
			gender: '',
			email: '',
			student_phone: '',
			guardian_name: '',
			guardian_phone: '',
			address: '',
			tenth_mark: 0,
			twelfth_school_name: '',
			twelfth_stream: -1,
			interested_branch_1: -1,
			interested_branch_2: -1,
		},
		modal: { open: false },
		errors: {},
		success: '',
		failure: '',
		loading: false,
		gender: VjcetService.get_gender(),
		streams: VjcetService.get_quali_boards(),
		courses: VjcetService.get_course_preferences(),
		error: '',
		captcha: {
			ref: React.createRef(),
			verified: false,
		},
	};

	// constructor(props, ...args) {
	// 	super(props, ...args);
	// 	this.state = {
	// 	  callback: "not fired",
	// 	  value: "[empty]",
	// 	  load: false,
	// 	  expired: "false",
	// 	  recaptchaLoaded: false
	// 	};
	// 	this._reCaptchaRef = React.createRef();
	//   }

	schema = {
		first_name: Joi.string()
			.required()
			.regex(/^[a-zA-Z](\/?[a-zA-Z ])*$/)
			.label('First Name')
			.error(() => {
				return {
					message: 'First Name : (Only letters and spaces allowed. Must start with a letter)',
				};
			}),
		last_name: Joi.string()
			.required()
			.regex(/^[a-zA-Z](\/?[a-zA-Z ])*$/)
			.label('Last Name')
			.error(() => {
				return {
					message: 'Last Name : (Only letters and spaces allowed. Must start with a letter)',
				};
			}),
		gender: Joi.number()
			.greater(0)
			.required()
			.error(() => {
				return {
					message: 'Gender not selected.',
				};
			}),
		email: Joi.string()
			.email({ minDomainAtoms: 2 })
			.required()
			.label('Email')
			.error(() => {
				return {
					message: 'Invalid email address',
				};
			}),
		student_phone: Joi.string()
			.required()
			.regex(/^\+?\d{10,15}$/gm)
			.label('Student Number')
			.error(() => {
				return {
					message: 'Student Number : (Only numbers and + allowed)',
				};
			}),
		guardian_phone: Joi.string()
			.optional()
			.regex(/^\+?\d{10,15}$/gm)
			.label('Guardian Number')
			.error(() => {
				return {
					message: 'Guardian Number : (Only numbers and + allowed)',
				};
			}),
		guardian_name: Joi.string()
			.required()
			.regex(/^[a-zA-Z](\/?[a-zA-Z ])*$/)
			.label('Guardian Name')
			.error(() => {
				return {
					message: 'Guardian Name : (Only letters and spaces allowed. Must start with a letter)',
				};
			}),
		address: Joi.string()
			.required()
			.label('Address')
			.error(() => {
				return {
					message: 'Address field is required',
				};
			}),
		tenth_mark: Joi.number()
			.precision(2)
			.greater(0)
			.less(101)
			.required()
			.label('Tenth Mark')
			.error(() => {
				return {
					message: '10th Marks must be between 0 and upto 100.',
				};
			}),
		twelfth_school_name: Joi.string()
			.required()
			.label('12th School Name')
			.regex(/^[a-zA-Z](\/?[a-zA-Z \-])*$/)
			.error(() => {
				return {
					message: 'School Name : (Only letters and spaces allowed. Must start with a letter)',
				};
			}),
		interested_branch_1: Joi.number()
			.greater(0)
			.required()
			.error(() => {
				return {
					message: 'Interested branch not selected.',
				};
			}),
		interested_branch_2: Joi.number()
			.allow(-1)
			.optional()
			.error(() => {
				return {
					message: 'Interested branch not selected.',
				};
			}),
		twelfth_stream: Joi.number()
			.greater(0)
			.error(() => {
				return {
					message: '12th Stream not selected.',
				};
			}),
		token: Joi.string().optional().label('Captcha token'),
	};

	handleOpen = () => {
		this.setState({
			modal: { open: true },
		});
	};

	handleClose = () => {
		this.setState({
			modal: { open: false },
		});
	};

	validate = () => {
		const options = { abortEarly: false };
		const { error } = Joi.validate(this.state.account, this.schema, options);

		//console.log(error);
		if (!error) return null;
		const errors = {};
		for (let item of error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	};

	validateProperty = ({ name, value }) => {
		const { error } = Joi.validate(this.state.account, this.schema);
		return error ? error.details[0].message : null;
	};

	//events assocaited with form and input fields
	handleChange = async ({ currentTarget: input }) => {
		const account = { ...this.state.account };
		let errors = { ...this.state.errors };
		account[input.name] = input.value;
		errors = {};
		await this.setState({ account, errors });

		const errorMessage = this.validateProperty(input);

		if (errorMessage) {
			errors[input.name] = errorMessage;
		} else {
			delete errors[input.name];
		}
		this.setState({ account, errors });
	};

	update_input_value = async (evt) => {
		var account = { ...this.state.account };
		let errors = { ...this.state.errors };
		let input = evt.currentTarget;
		account[input.name] = input.value;
		errors = {};
		await this.setState({ account, errors });
	};

	on_captcha_change = async (value) => {
		let captcha = { ...this.state.captcha };
		captcha.verified = value ? true : false;

		// console.log('Captcha value:', value);
		await this.setState({ captcha });
	};

	handleSubmit = async (e) => {
		const re_captcha_ref = { ...this.state.captcha.ref };
		let re_captcha_verified = { ...this.state.captcha.verified };
		e.preventDefault();

		const errors = this.validate();
		this.setState({ errors: errors || {} });
		if (errors) return;
		let token = this.state.account.token;
		// if (!this.state.captcha.verified) {
		re_captcha_ref.current.execute();
		token = await re_captcha_ref.current.executeAsync();
		//}

		// console.log('current state is ' + this.state.captcha.verified);

		// console.log('token is ' + token);

		if (!this.setState.loading) {
			this.setState({ loading: true });
			this.signup_timeout = setTimeout(() => this.setState({ loading: false }), 30000);

			let obj = this.state.account;
			obj.token = token;

			VjcetService.register_interest(obj)
				.then((resp) => {
					const data = resp.data;
					this.setState({ loading: false });
					re_captcha_ref.current.reset();
					re_captcha_verified = false;
					this.setState({ re_captcha_verified });
					// console.log('handling error response ' + data.success);
					if (data.success) {
						this.setState({
							success:
								'Thank you for registering your interest. We will be in touch soon. We will redirect you back to the homepage shortly',
						});

						setTimeout(() => {
							this.props.history.push('/');
						}, 5000);
					} else if (!data.success && data.code === 200) {
						this.setState({
							failure: data.info,
						});
						setTimeout(() => this.setState({ failure: '' }), 3000);
					}
				})
				.catch((err) => {
					re_captcha_ref.current.reset();

					re_captcha_verified = false;
					this.setState({ re_captcha_verified });

					console.log('handling error response ' + err);
					this.setState({ loading: false });
					if (err.response) {
						this.setState({
							failure: ' Failed request: Saving signup info : ' + err.response.data.message,
						});
						setTimeout(() => this.setState({ failure: '' }), 3000);
					} else if (err.request) {
						console.log('error req ' + err.request);
					} else {
						console.log('something else ' + err);
					}
				});
		}
	};

	componentWillUnmount() {
		if (this.signup_timeout && this.signup_timeout !== undefined) {
			clearTimeout(this.signup_timeout);
		}
	}

	render() {
		const { account, modal, errors, success, failure } = this.state;
		return (
			<div id="register-interest" className="bg-gray-100 text-white-100">
				<Header />
				<div className="max-w-screen-xl py-5 px-8 grid gap-8 grid-cols-1 md:px-12 lg:px-16 xl:px-32 mx-auto  bg-white text-gray-900 rounded-xl shadow-lg mt-0 text-white">
					<h2 className="text-4xl lg:text-5xl font-bold leading-tight">
						B. Tech Admission 2024 - Register Interest
					</h2>
					<form
						className="w-full"
						id="register-interest-form"
						onSubmit={this.handleSubmit}
						noValidate
						autoComplete="off"
					>
						<div className="flex flex-wrap -mx-3 mb-1 ">
							<div className="w-full md:w-1/2 px-3 mb-1 ">
								<span className="uppercase text-sm text-gray-600 font-bold">First Name</span>
								<input
									className="w-full ring-1 ring-gray-300 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
									type="text"
									name="first_name"
									value={this.state.account.first_name}
									onChange={(evt) => this.update_input_value(evt)}
									placeholder="First Name"
								/>
								{errors && <div className="error-text">{errors.first_name}</div>}
							</div>
							<div className="w-full md:w-1/2 px-3 mb-1 ">
								<span className="uppercase text-sm text-gray-600 font-bold">Last Name</span>
								<input
									className="w-full ring-1 ring-gray-300 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
									type="text"
									name="last_name"
									value={this.state.account.last_name}
									onChange={(evt) => this.update_input_value(evt)}
									placeholder="Last Name"
								/>
								{errors && <div className="error-text">{errors.last_name}</div>}
							</div>
						</div>
						<div className="flex flex-wrap -mx-3 mb-1 ">
							<div className="w-full md:w-1/2 px-3 mb-1 ">
								<span className="uppercase text-sm text-gray-600 font-bold">Gender</span>
								<div className="relative">
									<select
										className="w-full ring-1 ring-gray-300 bg-white text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
										id="grid-state"
										name="gender"
										value={this.state.account.gender}
										onChange={(evt) => this.update_input_value(evt)}
									>
										{this.state.gender.map((option) => (
											<option key={option.key} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
								{errors && <div className="error-text">{errors.gender}</div>}
							</div>
							<div className="w-full md:w-1/2 px-3 mb-1 ">
								<span className="uppercase text-sm text-gray-600 font-bold">Email</span>

								<input
									className="w-full ring-1 ring-gray-300 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
									type="email"
									placeholder="Email"
									name="email"
									value={this.state.account.email}
									onChange={(evt) => this.update_input_value(evt)}
								/>
								{errors && <div className="error-text">{errors.email}</div>}
							</div>
						</div>
						<div className="flex flex-wrap -mx-3 mb-1 ">
							<div className="w-full md:w-1/2 px-3 mb-1 ">
								<span className="uppercase text-sm text-gray-600 font-bold">Student Phone</span>
								<input
									className="w-full ring-1 ring-gray-300 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
									type="text"
									placeholder="Student Phone"
									name="student_phone"
									value={this.state.account.student_phone}
									onChange={(evt) => this.update_input_value(evt)}
								/>
								{errors && <div className="error-text">{errors.student_phone}</div>}
							</div>
							<div className="w-full md:w-1/2 px-3 mb-1 ">
								<span className="uppercase text-sm text-gray-600 font-bold">Guardian Name</span>
								<input
									className="w-full ring-1 ring-gray-300 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
									type="text"
									placeholder="Guardian Name"
									name="guardian_name"
									value={this.state.account.guardian_name}
									onChange={(evt) => this.update_input_value(evt)}
								/>
								{errors && <div className="error-text">{errors.guardian_name}</div>}
							</div>
						</div>
						<div className="flex flex-wrap -mx-3 mb-1 ">
							<div className="w-full md:w-1/2 px-3 mb-1 ">
								<span className="uppercase text-sm text-gray-600 font-bold">Guardian Phone</span>
								<input
									className="w-full ring-1 ring-gray-300 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
									type="text"
									placeholder="Guardian Phone"
									name="guardian_phone"
									value={this.state.account.guardian_phone}
									onChange={(evt) => this.update_input_value(evt)}
								/>
								{errors && <div className="error-text">{errors.guardian_phone}</div>}
							</div>
							<div className="w-full md:w-1/2 px-3 mb-1 ">
								<span className="uppercase text-sm text-gray-600 font-bold">10th Percentage Marks</span>
								<input
									className="w-full ring-1 ring-gray-300 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
									type="text"
									placeholder="10th Percentage Marks"
									name="tenth_mark"
									value={this.state.account.tenth_mark}
									onChange={(evt) => this.update_input_value(evt)}
								/>
								{errors && <div className="error-text">{errors.tenth_mark}</div>}
							</div>
						</div>
						<div className="flex flex-wrap -mx-3 mb-1">
							<div className="w-full px-3 mb-1 ">
								<span className="uppercase text-sm text-gray-600 font-bold">Address</span>
								<textarea
									className="w-full h-32 ring-1 ring-gray-300 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
									name="address"
									value={this.state.account.address}
									onChange={(evt) => this.update_input_value(evt)}
								></textarea>
								{errors && <div className="error-text">{errors.address}</div>}
							</div>
						</div>
						<div className="flex flex-wrap -mx-3 mb-1 ">
							<div className="w-full md:w-1/2 px-3 mb-1 ">
								<span className="uppercase text-sm text-gray-600 font-bold">12th School Name</span>
								<input
									className="w-full ring-1 ring-gray-300 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
									type="text"
									placeholder="12th School Name"
									name="twelfth_school_name"
									value={this.state.account.twelfth_school_name}
									onChange={(evt) => this.update_input_value(evt)}
								/>
								{errors && <div className="error-text">{errors.twelfth_school_name}</div>}
							</div>
							<div className="w-full md:w-1/2 px-3 mb-1 ">
								<span className="uppercase text-sm text-gray-600 font-bold">12th Stream</span>
								<div className="relative">
									<select
										className="w-full ring-1 ring-gray-300 bg-white text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
										id="twelfth_stream"
										name="twelfth_stream"
										value={this.state.account.twelfth_stream}
										onChange={(evt) => this.update_input_value(evt)}
									>
										{this.state.streams.map((option) => (
											<option key={option.key} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
									{errors && <div className="error-text">{errors.twelfth_stream}</div>}
								</div>
							</div>
						</div>
						<div className="flex flex-wrap -mx-3 mb-1 ">
							<div className="w-full md:w-1/2 px-3 mb-1 ">
								<span className="uppercase text-sm text-gray-600 font-bold">Branch Preference 1</span>
								<div className="relative">
									<select
										className="w-full ring-1 ring-gray-300 bg-white text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
										id="interested_branch_1"
										name="interested_branch_1"
										value={this.state.account.interested_branch_1}
										onChange={(evt) => this.update_input_value(evt)}
									>
										{this.state.courses.map((option) => (
											<option key={option.key} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
									{errors && <div className="error-text">{errors.interested_branch_1}</div>}
								</div>
							</div>
							<div className="w-full md:w-1/2 px-3 mb-1 ">
								<span className="uppercase text-sm text-gray-600 font-bold">Branch Preference 2</span>
								<div className="relative">
									<select
										className="w-full ring-1 ring-gray-300 bg-white  text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
										id="interested_branch_2"
										name="interested_branch_2"
										value={this.state.account.interested_branch_2}
										onChange={(evt) => this.update_input_value(evt)}
									>
										{this.state.courses.map((option) => (
											<option key={option.key} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
									{errors && <div className="error-text">{errors.interested_branch_2}</div>}
								</div>
							</div>
						</div>
						{this.state.loading && <LinearProgress />}

						{this.state.failure && (
							<Alert variant="filled" severity="error">
								{this.state.failure}
							</Alert>
						)}
						{this.state.success && (
							<Alert variant="filled" severity="success">
								{this.state.success}
							</Alert>
						)}

						<ReCAPTCHA
							sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
							onChange={this.on_captcha_change}
							size="invisible"
							ref={this.state.captcha.ref}
						/>
						<div className="mt-8">
							<button
								id="register-interest"
								disabled={this.state.loading}
								className="uppercase text-sm font-bold tracking-wide bg-indigo-500 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
							>
								Submit
							</button>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
