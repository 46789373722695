import React from 'react';
import Registration from './Registration';
import Home from './Home';
import Email from './email';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Timeline from './Components/Timeline';
import { ProtectedRoute } from './Guards/protected.route';
import ResetPsw from './EmailTemplate/resetPassword';
import Placement from './PlacementPage';
import About from './FooterPages/about';
import Contact from './FooterPages/contact';
import Privacy from './FooterPages/privacy';
import Refund from './FooterPages/refund';
import Service from './FooterPages/serviceTerms';
import ApplicationStatus from './Home/ApplicationStatus';
import RegisterInterest from './pages/RegisterInterest';

function App() {
	return (
		<Router>
			<div className="App">
				<Switch>
					<Route path="/" exact render={(props) => <Home {...props} />} />
					<ProtectedRoute path="/form/:a_id" component={Registration} />
					<ProtectedRoute path="/timeline" component={Timeline} />
					<Route path="/email_verify" render={props => (<Email {...props} />)} />
					<Route path="/reset_password" render={props => (<ResetPsw {...props} />)} />
					<Route path="/placement" component={Placement} />
					<Route path="/status" component={ApplicationStatus} />
					<Route path="/about" component={About} />
					<Route path="/contact" component={Contact} />
					<Route path="/privacy" component={Privacy} />
					<Route path="/refund" component={Refund} />
					<Route path="/service" component={Service} />
					<Route path="/register-interest" component={RegisterInterest} />
				</Switch>
			</div>
		</Router>
	);
}

export default App;
