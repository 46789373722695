import React, { Component } from 'react'
import {
    Container, TextField
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import logo from '../../images/logoVJCET.png'
import VjcetService from '../../vjcet-service';
import Icon from '@material-ui/core/Icon';
import Joi from 'joi-browser';
import Btn from '../../Components/Button'
import './style.css'
import Alert from '@material-ui/lab/Alert';
import Login from '../../Components/Login'
import EmailContact from '../emailContact'


export class ResetPasswordEmail extends Component {

    state = {
        account: { password: "", password_confirmation: "", token: "" },
        history: this.props.history,
        showMessage: false,
        errors: {},
        success: "",
        failure: ""
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token') || "";

        if (token.length > 0) {
            const account = { ...this.state.account };
            account["token"] = token;
            this.setState({ account });
        } else {
            this.setState({ failure: "Invalid token link. Please check link in the email or contact the Admin support team : " })
        }

    }

    //for validation
    schema = {
        password: Joi.string().min(8).max(15).required().label("Password"),
        password_confirmation: Joi.string().required().valid(Joi.ref('password')).options({ language: { any: { allowOnly: 'must match password' } } }),
        token: Joi.string().required().label("Token"),
    }

    validate = () => {
        const options = { abortEarly: false };
        const { error } = Joi.validate(this.state.account, this.schema, options);
        if (!error) return null;
        const errors = {};
        for (let item of error.details) {
            errors[item.path[0]] = item.message;
        }
        return errors;
    }

    validateProperty = ({ name, value }) => {
        const { error } = Joi.validate(this.state.account, this.schema);
        return error ? error.details[0].message : null;
    }

    //events assocaited with form and input fields
    handleChange = async ({ currentTarget: input }) => {

        const account = { ...this.state.account };
        let errors = { ...this.state.errors };
        account[input.name] = input.value;
        errors = {};
        await this.setState({ account, errors });

        const errorMessage = this.validateProperty(input);

        if (errorMessage) {
            errors[input.name] = errorMessage;
        } else {
            delete errors[input.name];
        }
        this.setState({ account, errors });
    }

    handleSubmit = (e) => {


        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) return;
        const account = { ...this.state.account };

        VjcetService.reset_password(account).then((resp) => {

            let data = resp.data;
            if (data.success) {
                this.setState({ success: "Password has been reset. Please Login with the new details" })

            } else {
                this.setState({ failure: "Reset password failed. Error : " + data.info })
            }

        }).catch(err => {
            if (err.response) {
                this.setState({ failure: " Failed request: Reset password failed. Error : " + err.response.data.message });
            } else if (err.request) {
                console.log("error req " + err.request);
            } else {
                console.log("something else " + err);
            }
        });
    }

    render() {


        return (

            <div id="email-verify" >

                <Container className="container-style" style={style1}>
                    <div className="logo-email">
                        <img src={logo} alt="logo" />
                    </div>

                    <form onSubmit={this.handleSubmit} id="reset-psw-form">
                        <div className="reset-psw" >

                            <TextField
                                label="Password"
                                name="password"
                                id="password"
                                type="password"
                                autoComplete="new-password"
                                placeholder="Password of your choice"
                                onChange={this.handleChange}
                                value={this.state.password}
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">lock</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {this.state.errors && <div className="error-text">{this.state.errors.password}</div>}

                            <TextField
                                label="Confirm Password"
                                name="password_confirmation"
                                id="password_confirmation"
                                type="password"
                                autoComplete="confirm-password"
                                placeholder="Confirm Password"
                                onChange={this.handleChange}
                                value={this.state.password_confirmation}
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">lock</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {this.state.errors && <div className="error-text">{this.state.errors.password_confirmation}</div>}

                            <div style={marginStyle}>

                                <div >
                                    <Btn type="submit" title="Submit" value="submit" id="login-btn" />
                                </div>

                            </div>
                        </div>
                        
                    </form>

                    {this.state.failure && <Alert variant="filled" severity="error">
                        {this.state.failure}
                    </Alert>}
                    {this.state.success && <Alert variant="filled" severity="success" >
                        {this.state.success}
                        <div style={{ paddingTop: "10px" }}>
                            <Login history={this.state.history} />
                        </div>
                    </Alert>}  
                    <EmailContact/>                 

                </Container>
            </div >




        )
    }
}

const style1 = {
    display: 'grid',
    gridTemplateColumns: '100vw',
    marginTop: "5vh",
}
const pStyle = {
    marginTop: "20vh",
}
const marginStyle = {
    marginTop: "5vh"
}
const btnStyle = {
    color: '#fff',
    backgroundColor: '#9d0055',
    minWidth: '150px',
    height: '50px',
    fontSize: '14px',
    fontWeight: '700',
    borderRadius: '30px',
    borderStyle: 'none'
}


export default ResetPasswordEmail
