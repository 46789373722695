import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const VjcetService = {

	APPLICATION_YEAR: '2025',
	ACADEMIC_YEAR: ' 2025-26 ',
	ACADEMIC_YEAR_TITLE: 'B.Tech Admission 2025',

	STAGE_BASIC: 1,
	STAGE_CONCTACTS: 2,
	STAGE_GUARDIAN_DETAILS: 3,
	STAGE_EDUCATION: 4,
	STAGE_COURSE_PREFERENCE: 5,
	STAGE_DOCUMENTS: 6,
	STAGE_SUMMARY: 7,
	STAGE_PAYMENTS: 8,
	STAGE_SUBMISSION: 9,

	STATUS_INACTIVE: 0,
	STATUS_DRAFT: 1,
	STATUS_PAYMENT_DONE: 2,
	STATUS_ALLOTMENT_OFFERED: 3,
	STATUS_ALLOTMENT_ACCEPTED: 4,
	STATUS_ALLOTMENT_REJECTED: 5,
	STATUS_ALLOTMENT_WAITINGLIST: 6,
	STATUS_ALLOTMENT_PAID: 7,
	STATUS_ALLOTMENT_CONFIRMED: 8,
	STATUS_ALLOTMENT_EXPIRED: 9,
	STATUS_ALLOTMENT_CANCELLED: 10,

	QUOTA_MANAGEMENT: 1,
	QUOTA_NRI: 2,
	MAX_FILE_SIZE: 2097152,

	phone_regex: /^\+?\d{10,15}$/gm,

	get_gender() {
		return [
			{
				key: 'g1',
				value: -1,
				label: 'Select Gender',
			},
			{
				key: 'g2',
				value: 1,
				label: 'Female',
			},
			{
				key: 'g3',
				value: 2,
				label: 'Male',
			},
		];
	},

	get_gender_array() {
		return ['N/A', 'Female', 'Male'];
	},

	get_quota_types() {
		return [
			{
				key: 'q1',
				value: 0,
				label: 'Select quota',
			},
			{
				key: 'q2',
				value: 1,
				label: 'Management',
			},
			{
				key: 'q3',
				value: 2,
				label: 'NRI',
			},
		];
	},

	get_quota_array() {
		return ['N/A', 'Management', 'NRI'];
	},

	get_months() {
		return [
			{
				key: 'm0',
				value: -1,
				short_label: 'Select Month',
				label: 'Select Month',
			},
			{
				key: 'm1',
				value: 1,
				short_label: 'Jan',
				label: 'January',
			},
			{
				key: 'm2',
				value: 2,
				short_label: 'Feb',
				label: 'February',
			},
			{
				key: 'm3',
				value: 3,
				short_label: 'Mar',
				label: 'March',
			},
			{
				key: 'm4',
				value: 4,
				short_label: 'April',
				label: 'April',
			},
			{
				key: 'm5',
				value: 5,
				short_label: 'May',
				label: 'May',
			},
			{
				key: 'm6',
				value: 6,
				short_label: 'June',
				label: 'June',
			},
			{
				key: 'm7',
				value: 7,
				short_label: 'July',
				label: 'July',
			},
			{
				key: 'm8',
				value: 8,
				short_label: 'Aug',
				label: 'August',
			},
			{
				key: 'm9',
				value: 9,
				short_label: 'Sept',
				label: 'September',
			},
			{
				key: 'm10',
				value: 10,
				short_label: 'Oct',
				label: 'October',
			},
			{
				key: 'm11',
				value: 11,
				short_label: 'Nov',
				label: 'November',
			},
			{
				key: 'm12',
				value: 12,
				short_label: 'Dec',
				label: 'December',
			},
		];
	},

	get_months_array() {
		return [
			'N/A',
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];
	},

	get_quali_boards() {
		return [
			{
				key: 'qb0',
				value: 0,
				label: 'Select Qualification Board',
			},
			{
				key: 'qb1',
				value: 1,
				label: 'DHSE',
				parent_id: 1,
			},
			{
				key: 'qb2',
				value: 2,
				label: 'VHSE',
				parent_id: 1,
			},
			{
				key: 'qb3',
				value: 3,
				label: 'THSE',
				parent_id: 1,
			},
			{
				key: 'qb4',
				value: 4,
				label: 'CBSE',
				parent_id: 2,
			},
			{
				key: 'qb5',
				value: 5,
				label: 'ICSE',
				parent_id: 2,
			},
			{
				key: 'qb6',
				value: 6,
				label: 'Others',
				parent_id: 3,
			},
		];
	},

	get_quali_boards_array() {
		return ['N/A', 'DHSE', 'VHSE', 'THSE', 'CBSE', 'ICSE', 'Others'];
	},

	get_quali_exams() {
		return [
			{
				key: 'q1',
				value: 0,
				label: 'Select Qualification Exam',
			},
			{
				key: 'q2',
				value: 1,
				label: 'HSE Kerala',
			},
			{
				key: 'q3',
				value: 2,
				label: 'AISSCE(CBSE)',
			},
			{
				key: 'q4',
				value: 3,
				label: 'CISCE(ICSE)',
			},
			{
				key: 'q5',
				value: 4,
				label: 'Others',
			},
		];
	},

	get_quali_exams_array() {
		return ['N/A', 'HSE Kerala', 'AISSCE(CBSE)', 'CISCE(ICSE)', 'Others'];
	},

	get_quali_years() {
		let quali_array = [
			{
				key: 'qy0',
				value: 0,
				label: 'Select Qualification year',
			},
		];
		let i;
		for (i = 2006; i <= 2025; i++) {
			quali_array.push({
				key: 'qy' + i,
				value: i,
				label: '' + i,
			});
		}

		return quali_array;
	},

	get_course_preferences() {
		return [
			{
				key: 'c0',
				value: -1,
				label: 'Select Course',
			},
			{
				key: 'c1',
				value: 7,
				label: 'Artificial Intelligence and Data Science',
			},
			{
				key: 'c2',
				value: 1,
				label: 'Civil Engineering',
			},			
			{
				key: 'c8',
				value: 8,
				label: 'Computer Science & Design',
			},

			{
				key: 'c3',
				value: 2,
				label: 'Computer Science and Engineering',
			},
			{
				key: 'c4',
				value: 4,
				label: 'Electronics and Communication Engineering',
			},
			{
				key: 'c5',
				value: 3,
				label: 'Electrical and Electronics Engineering',
			},
			{
				key: 'c6',
				value: 5,
				label: 'Information Technology',
			},
			{
				key: 'c7',
				value: 6,
				label: 'Mechanical Engineering',
			},
			
		];
	},

	get_courses_array() {
		return [
			'N/A',
			'Civil Engineering',
			'Computer Science and Engineering',
			'Electrical and Electronics Engineering',
			'Electronics and Communication Engineering',
			'Information Technology',
			'Mechanical Engineering',
			'Artificial Intelligence',
			'Computer Science & Design'
		];
	},

	get_religion_array() {
		return ['N/A', 'Christian', 'Hindu', 'Muslim', 'Other'];
	},

	allotment_quota_arr() {
		return ['N/A', 'OPEN QUOTA', 'CHRISTIAN QUOTA'];
	},

	set_local_storage: function (key, value) {
		return localStorage.setItem(key, value);
	},

	get_local_storage: function (key) {
		return localStorage.getItem(key);
	},

	remove_local_storage: function (key) {
		return localStorage.removeItem(key);
	},

	get_application_id() {
		return this.get_local_storage('a_id') || -1;
	},

	is_authenticated() {
		let token = this.get_local_storage('access_token') || '';
		// console.log("token " + token);

		return token.length > 0 ? true : false;
	},

	get_access_token_header() {
		let token = this.get_local_storage('access_token') || '';
		if (token && token.length > 0) {
			return {
				headers: { Authorization: `Bearer ${token}` },
			};
		} else {
			return false;
		}
	},

	get_formdata_access_token_header() {
		let token = this.get_local_storage('access_token') || '';
		if (token && token.length > 0) {
			return {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'multipart/form-data',
				},
			};
		} else {
			return false;
		}
	},

	set_access_token: function (token) {
		axios.defaults.headers.common = { Authorization: `bearer ${token}` };
		return this.set_local_storage('access_token', token);
	},

	async get_courses(value) {
		try {
			const response = await axios.get(apiUrl + 'courses');

			return response;
		} catch (e) {
			console.log(e);
		}
	},

	async signup(valueObj) {
		try {
			const response = await axios.post(apiUrl + 'admissions/applicant/signup', valueObj);
			return response;
		} catch (e) {
			console.log(e);
		}
	},

	async login(valueObj) {
		try {
			const response = await axios.post(apiUrl + 'admissions/login', valueObj);
			return response;
		} catch (e) {
			console.log(e);
		}
	},

	async application_status(valueObj) {
		try {
			const response = await axios.post(apiUrl + 'admissions/applicant/summary_dl', valueObj);
			return response;
		} catch (e) {
			console.log(e);
		}
	},

	async load_applicant_data() {
		try {
			let applicant_id = this.get_application_id();

			let config = this.get_access_token_header();

			return await axios.get(apiUrl + 'admissions/applicant/' + applicant_id + '/summary', config);
		} catch (e) {
			console.log(e);
		}
	},
	async load_timeline_data() {
		try {
			let applicant_id = this.get_application_id();

			let config = this.get_access_token_header();

			return await axios.get(apiUrl + 'admissions/applicant/' + applicant_id + '/timeline', config);
		} catch (e) {
			console.log(e);
		}
	},

	async submit_applicant_basic_info(data) {
		try {
			let applicant_id = this.get_application_id();

			let config = this.get_formdata_access_token_header();

			let formData = new FormData();
			formData.append('first_name', data.first_name);
			formData.append('middle_name', data.middle_name);
			formData.append('last_name', data.last_name);
			formData.append('aadhar', data.aadhar);
			formData.append('dob', data.dob);
			formData.append('stage', data.stage);
			formData.append('gender', data.gender);
			formData.append('religion_id', data.religion_id);
			formData.append('applicant_mobile', data.applicant_mobile);
			formData.append('title', data.title);
			formData.append('id', data.id);
			formData.append('photo', data.photo);
			formData.append('quota_id', data.quota_id);
			formData.append('community', data.community);
			return await axios.post(apiUrl + 'admissions/applicant/' + applicant_id + '/basic', formData, config);
		} catch (e) {
			console.log(e);
		}
	},

	async submit_applicant_contact_info(valueObj) {
		let applicant_id = this.get_application_id();
		let config = this.get_access_token_header();

		try {
			return await axios.post(apiUrl + 'admissions/applicant/' + applicant_id + '/contacts', valueObj, config);
		} catch (e) {
			return false;
		}
	},

	async submit_applicant_guardian_info(valueObj) {
		let applicant_id = this.get_application_id();
		let config = this.get_access_token_header();

		return axios.post(apiUrl + 'admissions/applicant/' + applicant_id + '/parent', valueObj, config);
	},

	async submit_applicant_education_info(valueObj) {
		let applicant_id = this.get_application_id();
		let config = this.get_access_token_header();

		try {
			return await axios.post(apiUrl + 'admissions/applicant/' + applicant_id + '/education', valueObj, config);
		} catch (e) {
			return false;
		}
	},


	async submit_applicant_keam_info(valueObj) {
		// let applicant_id = this.get_application_id();
		// let config = this.get_access_token_header();

		// try {
		// 	return await axios.post(apiUrl + 'admissions/applicant/' + applicant_id + '/education', valueObj, config);
		// } catch (e) {
		// 	return false;
		// }

		let applicant_id = this.get_application_id();

		let config = this.get_formdata_access_token_header();

		try {
			return await axios.post(apiUrl + 'admissions/applicant/' + applicant_id + '/keam-score', valueObj, config);
		} catch (e) {
			return false;
		}


	},

	async submit_applicant_photo(valueObj){
		let applicant_id = this.get_application_id();

		let config = this.get_formdata_access_token_header();

		try {
			return await axios.post(apiUrl + 'admissions/applicant/' + applicant_id + '/update-photos', valueObj, config);
		} catch (e) {
			return false;
		}
	},

	async submit_applicant_course_preferences(valueObj) {
		let applicant_id = this.get_application_id();
		let config = this.get_access_token_header();

		try {
			return await axios.post(apiUrl + 'admissions/applicant/' + applicant_id + '/options', valueObj, config);
		} catch (e) {
			return false;
		}
	},

	async submit_applicant_allotment_acceptance(valueObj) {
		let applicant_id = this.get_application_id();
		let config = this.get_access_token_header();

		try {
			return await axios.post(
				apiUrl + 'admissions/applicant/' + applicant_id + '/allotment/accept',
				valueObj,
				config,
			);
		} catch (e) {
			return false;
		}
	},

	async submit_applicant_documents_info(valueObj) {
		let applicant_id = this.get_application_id();

		let config = this.get_formdata_access_token_header();

		try {
			return await axios.post(apiUrl + 'admissions/applicant/' + applicant_id + '/documents', valueObj, config);
		} catch (e) {
			return false;
		}
	},

	async submit_applicant_summary_info(valueObj) {
		let applicant_id = this.get_application_id();
		let config = this.get_access_token_header();

		return await axios.post(apiUrl + 'admissions/applicant/' + applicant_id + '/education', valueObj, config);
	},

	submit_applicant_payment_info: function (valueObj) {
		let applicant_id = this.get_application_id();
		let config = this.get_access_token_header();

		if (config) {
			return axios.put(apiUrl + 'admissions/applicant/' + applicant_id + '/payment', valueObj, config);
		} else {
			return false;
		}
	},

	async download_summary_for_status() {
		let applicant_id = this.get_application_id();
		let config = this.get_access_token_header();

		if (config) {
			return await axios.get(apiUrl + 'admissions/applicant/' + applicant_id + '/link', config);
		} else {
			return false;
		}
	},

	async download_summary() {
		let applicant_id = this.get_application_id();
		let config = this.get_access_token_header();

		if (config) {
			return await axios.get(apiUrl + 'admissions/applicant/' + applicant_id + '/link', config);
		} else {
			return false;
		}
	},

	open_download_doc(token) {
		let download_url = apiUrl + 'admissions/applicant/download/' + token;
		window.open(download_url, '_blank');
	},

	async forgot_password_link(email) {
		return await axios.post(apiUrl + 'admissions/applicant/password_reset_link', { email: email });
	},

	async reset_password(obj) {
		return await axios.post(apiUrl + 'admissions/applicant/password_reset', obj);
	},

	async sign_out() {
		let config = this.get_access_token_header();

		return axios.get(apiUrl + 'admissions/logout', config);
	},

	async verify_email(token) {
		return axios.post(apiUrl + 'admissions/applicant/verify-email', { token: token });
	},

	async register_interest(obj) {
		return axios.post(apiUrl + 'admissions/enquiry', obj);
	},
};

export default VjcetService;
