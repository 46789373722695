import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Btn from '../Button'
import Icon from '@material-ui/core/Icon';
import './style.css'
import VjcetService from '../../vjcet-service';
import Joi from 'joi-browser';
import ForgotPassword from '../ForgotPassword'




export default class LoginModal extends Component {
    state = {
        account: { email: '', password: '', },
        modal: { open: false },
        errors: {},
        loginErr: "",
    }
    schema = {
        email: Joi.string().required().label("Email"),
        password: Joi.string().required().label("Password"),

    }


    handleOpen = () => {
        this.setState({
            modal: { open: true }
        })

    };

    handleClose = () => {
        this.setState({
            modal: { open: false }
        })

    };
    validate = () => {

        const options = { abortEarly: false };
        const { error } = Joi.validate(this.state.account, this.schema, options);

        //console.log(error);
        if (!error) return null;
        const errors = {};
        for (let item of error.details) {
            errors[item.path[0]] = item.message;
        }
        return errors;
    }
    validateProperty = ({ name, value }) => {
        const obj = { [name]: value };
        const schema = { [name]: this.schema[name] }
        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null;

    }

    handleChange = ({ currentTarget: input }) => {

        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);

        if (errorMessage) {
            errors[input.name] = errorMessage;
        } else {
            delete errors[input.name];
        }

        const account = { ...this.state.account };
        account[input.name] = input.value;
        this.setState({ account, errors });

    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) return;

        let obj = {
            username: this.state.account.email,
            password: this.state.account.password
        }

        VjcetService.login(obj).then((resp) => {

            if (resp && resp !== undefined) {
                const data = resp.data;
                //console.log(data);
                if (data.success) {
                    let access_token = data.access_token || "";
                    let applicant_id = data.user.applicant_id || -1;
                    VjcetService.set_local_storage("a_id", applicant_id);
                    if (access_token.length > 0) {

                        VjcetService.set_access_token(access_token);
                        this.props.history.push(`/form/${applicant_id}`);
                    }

                } else if (!data.success && data.code === 200) {
                    this.setState({
                        loginErr: data.info,
                    });
                }
                else {

                    VjcetService.remove_local_storage("access_token");
                    VjcetService.remove_local_storage("a_id");
                    this.setState({
                        loginErr: data.info,
                    });

                }
            } else {
                VjcetService.remove_local_storage("access_token");
                VjcetService.remove_local_storage("a_id");
                this.setState({
                    loginErr: "Please enter valid username and passsword",
                });
            }

        });
    }

    render() {
        const { account, modal, errors } = this.state;
        return (
            <div>

                {/* <Btn title="Login" click={this.handleOpen} /> */}

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modal"
                    open={modal.open}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={modal.open} className="paper">
                        <form onSubmit={this.handleSubmit} id="login-form">
                            <TextField
                                label="email"
                                name="email"
                                id="email"
                                type="email"
                                value={account.email}
                                autoFocus
                                onChange={this.handleChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">email</Icon>
                                        </InputAdornment>

                                    ),
                                }} />
                            {errors && <div className="error-text">{errors.email}</div>}
                            <TextField
                                label="password"
                                name="password"
                                id="psw"
                                type="password"
                                value={account.password}
                                onChange={this.handleChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">lock</Icon>
                                        </InputAdornment>

                                    ),
                                }} />
                            {errors && <div className="error-text">{errors.password}</div>}
                            {this.state.loginErr && <div className="error-text">{this.state.loginErr}</div>}
                            <ForgotPassword />
                            <div className="btn-style">
                                <Btn type="submit" title="Submit" value="submit" id="login-btn" />
                            </div>


                        </form>
                    </Fade>
                </Modal>
            </div>
        );

    }


}
