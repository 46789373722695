import React, { Component } from 'react';
import Btn from '../Button';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import DescriptionIcon from '@material-ui/icons/Description';
import EmailIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import EditIcon from '@material-ui/icons/Edit';
import RepeatIcon from '@material-ui/icons/CheckCircle';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Header from '../Header'
import {
    Container,

} from '@material-ui/core';
import VjcetService from '../../vjcet-service';



export default class FormTimeline extends Component {
    state = {
        signup: { state: 0, date: "", title: "", description: "" },
        email: { state: 0, date: "", title: "", description: "" },
        contacts: { state: 0, date: "", title: "", description: "" },
        documents: { state: 0, date: "", title: "", description: "" },
        payment: { state: 0, date: "", title: "", description: "" },
        form: true,
    }
    componentDidMount() {

        VjcetService.load_timeline_data().then((resp) => {
            if (resp && resp !== undefined) {
                const data = resp.data;

                if (data.success) {
                    this.setState({
                        signup: data.data[0],
                        email: data.data[1],
                        contacts: data.data[2],
                        documents: data.data[3],
                        payment: data.data[4]
                    })
                }
            }

        })
    }


    download_application = (e) => {
        e.preventDefault();
        VjcetService.download_summary().then((resp) => {
            let data = resp.data;
            if (data.success) {
                VjcetService.open_download_doc(data.link);
            } else {
                this.setState({ failure: "Error: Opening Summary Documents. Error : " + data.info });
                setTimeout(() => this.setState({ failure: "" }), 3000);
            }

        }).catch(err => {
            if (err.response) {
                this.setState({ failure: " Failed request: Opening Summary document. Error : " + err.response.data.message });
                setTimeout(() => this.setState({ failure: "" }), 3000);

            } else if (err.request) {
                console.log("error req " + err.request);
            } else {
                console.log("something else " + err);
            }
        });
    }


    render() {
        const { signup, email, contacts, payment } = this.state

        return (
            <div id="wrapper" style={{ height: "100vh" }} className="container-style">
                <Header form={this.state.form} />
                <Container className="container-style" id="status">
                    <Timeline align="alternate">
                        <TimelineItem style={signup.state === 1 ? listStyle : noListStyle}>
                            <TimelineOppositeContent>
                                <Typography variant="body2" >
                                    {signup.date}
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="secondary">
                                    <DescriptionIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} style={useStyles.paper}>
                                    <Typography variant="h6" component="h1">
                                        Account Signed Up
                                </Typography>
                                    <Typography>Waiting for  Approval</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem style={email.state === 1 ? listStyle : noListStyle}>
                            <TimelineOppositeContent>
                                <Typography variant="body2">
                                    {email.date}
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary">
                                    <EmailIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} style={useStyles.paper}>
                                    <Typography variant="h6" component="h1">
                                        Email Verified
                                    </Typography>
                                    <Typography>Can start filling up the form!</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem style={contacts.state === 1 ? listStyle : noListStyle}>
                            <TimelineSeparator>
                                <TimelineDot color="primary" variant="outlined">
                                    <EditIcon />
                                </TimelineDot>
                                <TimelineConnector style={useStyles.secondaryTail} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} style={useStyles.paper}>
                                    <Typography variant="body2" >
                                        {contacts.date}
                                    </Typography>
                                    <Typography variant="h6" component="h1">
                                        Started working on Application form
                                    </Typography>
                                    <Typography>Continues...</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem style={document.state === 1 ? listStyle : noListStyle}>
                            <TimelineSeparator>
                                <TimelineDot color="secondary">
                                    <RepeatIcon />
                                </TimelineDot>
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} style={useStyles.paper}>
                                    <Typography variant="body2" >
                                        {document.date}
                                    </Typography>
                                    <Typography variant="h6" component="h1">
                                        Finished Application Form
                                    </Typography>
                                    <Typography>Awaiting Payment</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem style={payment.state === 1 ? listStyle : noListStyle}>
                            <TimelineSeparator>
                                <TimelineDot color="secondary">
                                    <RepeatIcon />
                                </TimelineDot>
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} style={useStyles.paper}>
                                    <Typography variant="body2" >
                                        {payment.date}
                                    </Typography>
                                    <Typography variant="h6" component="h1">
                                        Payment Submitted
                                    </Typography>
                                    <Typography></Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>

                    <div className="btn-style">
                        <Btn title='Download PDF' click={this.download_application} />
                    </div>

                </Container>
            </div>

        );
    }


}
const listStyle = {
    color: "#000",
}
const noListStyle = {
    color: "#9d9d9d",
    opacity: 0.5
}
const useStyles = {
    paper: {
        padding: '6px 16px',
    },
    secondaryTail: {
        backgroundColor: "blue",
    },
};
