import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
	table: {
		minWidth: 650,
		fontWeight: 700,
	},
	th: {
		fontWeight: 700,
	},
});
const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

function createData(code, name, seats) {
	return { code, name, seats };
}

const rows = [
	createData('AD', 'Artificial Intelligence and Data Science', 60),
	createData('CE', 'Civil Engineering', 60),
	createData('CG', 'Computer Science & Design', 60),
	createData('CS', 'Computer Science and Engineering', 180),
	createData('EE', 'Electrical and Electronics Engineering', 60),
	createData('EC', 'Electronics and Communication Engineering', 60),
	createData('IT', 'Information Technology', 60),
	createData('ME', 'Mechanical Engineering', 60),

];

export default function Programmes() {
	const classes = useStyles();

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
					<StyledTableRow>
						<StyledTableCell className={classes.th}>Course Code</StyledTableCell>
						<StyledTableCell className={classes.th}>Course</StyledTableCell>
						<StyledTableCell className={classes.th}>Seats</StyledTableCell>
					</StyledTableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<StyledTableRow key={row.code}>
							<TableCell component="th" scope="row">
								{row.code}
							</TableCell>
							<TableCell>{row.name}</TableCell>
							<TableCell>{row.seats}</TableCell>
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
			{/* <span className="ml-2 italic text-xs font-semibold">✝ Subject to the approval from Govt. of Kerala</span> */}
		</TableContainer>
	);
}
