import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';
import VjcetService from '../../vjcet-service';
import Alert from '@material-ui/lab/Alert';


export default function ForgotPassword() {
    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = React.useState();
    const [success, setSuccess] = React.useState();
    const [failure, setFailure] = React.useState();


    const handleClickOpen = () => {
        setOpen(true);
    };

    const forgot_password_link = (e) => {
        e.preventDefault();
        setFailure("");
        setSuccess("");
        VjcetService.forgot_password_link(email).then((resp) => {
            let data = resp.data;
            if (data.success) {
                setSuccess("We have sent an email with a reset Password link. Please check your inbox/SPAM folders. The email link is valid for 24 hours.")
            } else {
                setFailure("There was an error sending password reset email ." + data.info)
            }

        });
    };

    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const useStyles = makeStyles({
        root: {
            color: " #9d0055",
        },

    });
    const classes = useStyles();

    return (
        <div>
            <Button classes={{ root: classes.root }} onClick={handleClickOpen}>
                <HelpIcon /> Forgot Password
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Reset your Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter your username or email address, we will send you a link to login.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        value={email}
                        onChange={handleChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} classes={{ root: classes.root }} >
                        Cancel
                    </Button>
                    <Button onClick={forgot_password_link} classes={{ root: classes.root }} >
                        Send me a link
                    </Button>
                </DialogActions>
                {failure && <Alert variant="filled" severity="error">
                    {failure}
                </Alert>}
                {success && <Alert variant="filled" severity="success" >
                    {success}
                </Alert>}
            </Dialog>
        </div>
    );
}
