import React, { Component } from 'react'
import {
    Container,
    TextField,
    InputAdornment,
    MenuItem
} from '@material-ui/core';
import Btn from '../Components/Button'
import ProgressBar from '../Components/ProgressBar'
import Icon from '@material-ui/core/Icon';
import VjcetService from '../vjcet-service';
import Skeleton from '@material-ui/lab/Skeleton';
import Header from '../Components/Header';
import SubHeader from '../Components/SubHeader';
import Footer from '../Components/FormFooter'
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';


const religion = [
    {
        key: "r1",
        value: -1,
        label: "Select Religion"
    },
    {
        key: "r2",
        value: 1,
        label: "Christian"
    },
    {
        key: "r3",
        value: 2,
        label: "Hindu"
    },
    {
        key: "r4",
        value: 3,
        label: "Muslim"
    },
    {
        key: "r5",
        value: 4,
        label: "Other"
    }
];


const gender = VjcetService.get_gender();
const quotas = VjcetService.get_quota_types();


export class BasicDetails extends Component {


    basic_timeout = null;

    constructor(props) {
        super(props);
        this.state = {
            error: this.props.errors,
            profile_photo: null,
            photo: null,
            gender: this.props.gender || -1,
            first_name: this.props.first_name,
            success: "",
            failure: ""
        }
    }


    validate = () => {

        const error = {};
        const { values } = this.props;

        const phone_regex = /^\+?\d{10,15}$/gm;

        let applicant_mobile = values.applicant_mobile || "";

        if (values.photo_url === "") {
            error.photo_url = "Applicant Photo is required";
        }

        if (values.first_name === "") {
            error.first_name = "First name is required";
        }

        if (values.last_name === "") {
            error.last_name = "Last name is required";
        }

        if (values.dob === "") {
            error.dob = "Date of birth is required";
        }
        // if (values.community === "") {
        //     error.community = "Community is required";
        // }

        if (values.religion_id === "" || values.religion_id <= 0) {
            error.religion_id = "Religion is required";
        }
        if (values.quota_id === "" || values.quota_id <= 0) {
            error.quota_id = "Please select a quota ";
        }

        if (values.gender === "" || values.gender <= 0) {
            error.gender = "Gender is required";
        }
        // if (values.email === "") {
        //     error.email = "Email is required";
        // }
        // console.log("phone number " + values.applicant_mobile + " " + applicant_mobile + (!phone_regex.test(values.applicant_mobile)));
        if (applicant_mobile === "" || applicant_mobile === undefined) {
            error.applicant_mobile = "Mobile number is required";
        } else if (!phone_regex.test(applicant_mobile)) {
            error.applicant_mobile = "Invalid phone number, please check";
        }
        return Object.keys(error).length === 0 ? null : error;
    }

    save_data = (continue_after_save) => {

        const { values } = this.props;

        const error = this.validate();
        this.setState({
            error
        });
        let error_timeout = setTimeout(() => this.setState({ error: {} }), 3000);
        if (error) return;

        if (!this.state.loading) {

            this.setState({ loading: true });
            this.basic_timeout = setTimeout(() => this.setState({ loading: false }), 30000);
            let data = values;

            VjcetService.submit_applicant_basic_info(data).then((resp) => {

                let data = resp.data;
                if (data.success) {

                    if (error_timeout && error_timeout !== undefined) {
                        clearTimeout(error_timeout);
                    }

                    if (continue_after_save) {
                        this.props.nextStep(VjcetService.STAGE_CONCTACTS);
                    } else {
                        this.props.logout();
                    }
                } else {
                    this.setState({ failure: "Error: Saving Basic info. Error : " + data.info });
                }

            }).catch(err => {

                if (error_timeout && error_timeout !== undefined) {
                    clearTimeout(error_timeout);
                }

                if (err.response) {
                    this.setState({ failure: " Failed request: Saving Basic info. Error : " + err.response.data.message });
                } else if (err.request) {
                    console.log("error req " + err.request);
                } else {
                    console.log("something else ");
                }
            });

        }


    }

    continue = (e) => {

        e.preventDefault();
        this.save_data(true);

    }

    save_and_exit = (e) => {

        e.preventDefault();
        this.save_data(false);
    }


    componentWillUnmount() {
        if (this.basic_timeout && this.basic_timeout !== undefined) {
            clearTimeout(this.basic_timeout);
        }
    }

    render() {
        const { step, formValue } = this.props;

        const { values, handleChange, profileImageChanged, updateSelectValue } = this.props;
        const { error } = this.state;

        return (
            <div id="wrapper">
                <Header name="container-style" form={formValue} />
                <Container className="container-style">

                    <SubHeader appInfo={values} />
                    <ProgressBar step={step} />
                    <h3 className="sub-title1">Basic Information</h3>
                    <form id="basic-details" className="form-style" onSubmit={this.continue} noValidate autoComplete="off">
                        <input type="hidden" value="mr" name="title" id="title" />
                        

                        <div className="profile-img-style" style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            {values.photo_url ? (
                                <img style={{ width: 150, height: 150 }} alt="profile" src={values.photo_url} />
                            ) : (
                                    <Skeleton variant="rect" width={150} height={150} />
                                )}
                            {values.photo_error && (<div className="error-text">{values.photo_error}</div>)}
                            <label htmlFor="img-upload" className="label-style">Choose file</label>
                            <input type="file" id="img-upload" name="file" accept="image/png,image/jpeg,image/jpg" onChange={profileImageChanged} style={{ position: "relative", top: "5px", left: "40px", display: "none" }} />
                            <span className="text-base font-semibold text-red-500">Please upload a clear photo.</span>
                            {error && <div className="error-text">{error.photo_url}</div>}
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}>
                            <div className="form-fields">
                                <TextField
                                    label="First Name"
                                    className="input-inline"
                                    onChange={handleChange}
                                    value={values.first_name}
                                    name="first_name"
                                    id="first_name"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">person</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.first_name}</div>}
                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Middle Name"
                                    onChange={handleChange}
                                    value={values.middle_name}
                                    name="middle_name"
                                    id="middle_name"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">person</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Last Name"
                                    onChange={handleChange}
                                    value={values.last_name}
                                    name="last_name"
                                    id="last_name"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">person</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.last_name}</div>}
                            </div>
                        </div>

                        {/* <div className="form-fields">
                            <TextField
                                label="Aadhar No"
                                onChange={handleChange}
                                value={values.aadhar}
                                name="aadhar"
                                id="aadhar"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">person</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text">{error.aadhar}</div>}

                        </div> */}

                        <div className="form-fields">
                            <TextField
                                id="dob"
                                type="date"
                                name="dob"
                                onChange={handleChange}
                                value={values.dob}
                                format={'DD-MM-YYYY'}
                                label="Date of Birth"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">calendar_today</Icon>
                                        </InputAdornment>
                                    ),
                                    inputProps: { max: "2010-12-31" }
                                }}
                            />
                            {error && <div className="error-text">{error.dob}</div>}

                        </div>
                        <div className="form-fields">
                            <TextField
                                label="Phone"
                                id="applicant_mobile"
                                name="applicant_mobile"
                                required
                                onChange={handleChange}
                                value={values.applicant_mobile}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">phone</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text">{error.applicant_mobile}</div>}
                        </div>
                        {/* <div className="form-fields">
                            <TextField
                                label="Email"
                                id="email"
                                name="email"
                                required
                                onChange={handleChange}
                                defaultValue={values.email}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">mail</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text">{error.email}</div>}

                        </div> */}
                        <div className="form-fields">
                            <TextField
                                id="gender"
                                label="Gender"
                                name="gender"
                                required
                                onChange={updateSelectValue}
                                value={values.gender}
                                select InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">person</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            >
                                {gender.map(option => (
                                    <MenuItem key={option.key} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {error && <div className="error-text">{error.gender}</div>}
                        </div>
                        <div className="form-fields">
                            <TextField
                                id="religion_id"
                                label="Religion"
                                name="religion_id"
                                required
                                onChange={updateSelectValue}
                                value={values.religion_id}
                                select InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">account_balance</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            >
                                {religion.map(option => (
                                    <MenuItem key={option.key} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {error && <div className="error-text">{error.religion_id}</div>}
                        </div>
                        <div className="form-fields">
                            <TextField
                                id="community"
                                label="Community"
                                name="community"
                                onChange={handleChange}
                                value={values.community}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">account_balance</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {error && <div className="error-text">{error.community}</div>}
                        </div>
                        <div className="form-fields">
                            <TextField
                                id="quota_id"
                                label="Quota"
                                name="quota_id"
                                required
                                onChange={updateSelectValue}
                                value={values.quota_id}
                                select InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className="icon-style">account_balance</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            >
                                {quotas.map(option => (
                                    <MenuItem key={option.key} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {error && <div className="error-text">{error.quota_id}</div>}
                        </div>
                    </form>

                    {this.state.loading && <LinearProgress />}

                    {this.state.failure && <Alert variant="filled" severity="error">
                        {this.state.failure}
                    </Alert>}
                    {this.state.success && <Alert variant="filled" severity="success" >
                        {this.state.success}
                    </Alert>}


                    <div className="btn-style">
                        <Btn title='Save and Exit' click={this.save_and_exit} />
                        <Btn title='Save and Proceed' click={this.continue} />
                    </div>
                </Container>
                <Footer />
            </div >


        )
    }
}



export default BasicDetails
