import React, { Component } from 'react';
import { Container } from '@material-ui/core';
import line from '../images/icons/line.png';
import Signup from '../Components/Signup';
import Login from '../Components/Login';
import Instructions from '../Components/OnlineInstructions';
import VjcetService from '../vjcet-service';

export class FirstPage extends Component {
	continue = (e) => {
		e.preventDefault();
		this.props.nextStep();
	};

	render() {
		const { history } = this.props;

		return (
			<Container className="container-style" id="reg-form-home">
				
				 <div className="header-style">
					<h1 className="title-style1">Online Application {VjcetService.ACADEMIC_YEAR_TITLE}</h1>
				</div>

				 <div className="first-page">
					<div>
						<p className="text-style1">Returning Users - Login to Continue Application</p>
						<div className="btn-style1">
							<Login history={history} />
						</div>
					</div>
					<div className="img-style1">
						<img src={line} alt="line" />
					</div>
					<div>
						<p className="text-style1">First-time Users - Create an account to start a new application.</p>
						<p className="text-style1">
							<span style={{ fontStyle: 'italic', fontSize: '0.8rem' }}>
								(*Please read the Instructions before filling the Application Form)
							</span>
						</p>
					 	<div className="btn-style1" style={{ display: 'inline-block' }}>
							<Signup /> 
							<Instructions />
						</div>
					</div>
				</div> 
			</Container>
		);
	}
}

export default FirstPage;
