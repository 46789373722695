import React, { Component } from 'react';
import { Container, TextField, InputAdornment } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Btn from '../Components/Button';
import ProgressBar from '../Components/ProgressBar';
import Header from '../Components/Header';
import Footer from '../Components/FormFooter';
import { withRouter } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import VjcetService from '../vjcet-service';
import LinearProgress from '@material-ui/core/LinearProgress';
import VJCETScanCode from '../images/vjcscancode.jpg';
import WarningIcon from '../images/icons/warning.png';

const courses = VjcetService.get_courses_array();
const quotas = VjcetService.allotment_quota_arr();

export class Allocation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: {},
			success: '',
			failure: '',
			allotment_balance: 0,
			allotment_paid: 0,
			allotment_accepted: true,
			allotment_branch: '',
			allotment_quota: '',
			allotment_made: true,
			allotment_deposit_reference: '',
			disable_btn: false,
		};
	}

	ref_submit = (e) => {
		const { appInfo } = this.props;
		let data = { allotment_deposit_reference: appInfo.allotment_deposit_reference };
		this.setState({ disable_btn: true });
		VjcetService.submit_applicant_allotment_acceptance(data)
			.then((resp) => {
				this.setState({ disable_btn: false });
				let data = resp.data;
				if (data.success) {
					this.setState({ success: ' Your payment reference has been recorded: ' });
					//setTimeout(() => window.location.reload(), 2000);
				} else {
					this.setState({ failure: 'Error: Saving  Allocation Payment info. Error : ' + data.info });
				}
			})
			.catch((err) => {
				if (err.response) {
					this.setState({
						failure: ' Failed request: Allocation Payment. Error : ' + err.response.data.message,
					});
				} else if (err.request) {
					console.log('error req ' + err.request);
				} else {
					console.log('something else ');
				}
			});
	};

	accept_allotment = (e) => {
		let data = { allotment_status: VjcetService.STATUS_ALLOTMENT_ACCEPTED };
		this.setState({ disable_btn: true });
		VjcetService.submit_applicant_allotment_acceptance(data)
			.then((resp) => {
				this.setState({ disable_btn: false });
				let data = resp.data;
				if (data.success) {
					this.setState({ Success: ' Your Acceptance has been recorded, please proceed with payment : ' });
					setTimeout(() => window.location.reload(), 1500);
				} else {
					this.setState({ failure: 'Error: Saving  Allocation Acceptance info. Error : ' + data.info });
				}
			})
			.catch((err) => {
				if (err.response) {
					this.setState({
						failure: ' Failed request: Allocation Acceptance. Error : ' + err.response.data.message,
					});
				} else if (err.request) {
					console.log('error req ' + err.request);
				} else {
					console.log('something else ');
				}
			});
	};

	reject_allotment = (e) => {
		let data = { allotment_status: VjcetService.STATUS_ALLOTMENT_REJECTED };
		this.setState({ disable_btn: true });
		VjcetService.submit_applicant_allotment_acceptance(data)
			.then((resp) => {
				this.setState({ disable_btn: false });
				let data = resp.data;
				if (data.success) {
					this.setState({ Success: 'We have updated our records with your choice.' });
					setTimeout(() => window.location.reload(), 1500);
				} else {
					this.setState({ failure: 'Error: Saving  Allocation Decline info. Error : ' + data.info });
				}
			})
			.catch((err) => {
				if (err.response) {
					this.setState({
						failure: ' Failed request: Allocation Decline. Error : ' + err.response.data.message,
					});
				} else if (err.request) {
					console.log('error req ' + err.request);
				} else {
					console.log('something else ');
				}
			});
	};
	render() {
		const { appInfo, handleChange, step, formValue } = this.props;
		let allocted_branch =
			courses[appInfo.allotment_branch_id] && courses[appInfo.allotment_branch_id] !== undefined
				? courses[appInfo.allotment_branch_id]
				: 'N/A';
		let allotment_quota = (quotas[appInfo.allotment_quota_id] && quotas[appInfo.allotment_quota_id] !== undefined) ? quotas[appInfo.allotment_quota_id] : "N/A";

		return (
			<div id="wrapper" className="height">
				<Header name="container-style" form={formValue} />
				<Container className="container-style">
					<ProgressBar step={step} />
					<h3 className="title-style1"><span className="highlight"> ATTENTION </span></h3>
					<div className="allocation_section">
						<p className="text-style1">
							Your Application Number is <span className="highlight">{appInfo.application_id}</span>.
						</p>
					</div>
					{(appInfo.allotment_made && appInfo.status === VjcetService.STATUS_ALLOTMENT_OFFERED) ? (
                        <div className="allocation_section">
                            <p className="text-style1">
                                After reviewing your application you have been provisionally Allotted in <span className="highlight">{allocted_branch}</span> under <span className="highlight">{allotment_quota}</span>.
                                <br />
                            </p>
                        </div>

                    ) : ""}
					{appInfo.allotment_made && appInfo.status === VjcetService.STATUS_ALLOTMENT_CONFIRMED ? (
						<div className="allocation_section">
							<p className="text-style1">
								Your provisional admission to <span className="highlight">{allocted_branch}</span>{' '}
								branch is confirmed and you have paid Rs.
								<span className="highlight">{appInfo.allotment_paid}</span> towards the Tuition fee for
								the first semester.
							</p>
							<br />
							<p className="text-style1">
								{/* To view Management Quota admission schedule, click to download the <a className="download_button " href="/ADMISSION-SCHEDULE-MQ-2.pdf" target="_blank"><b>MANAGEMENT QUOTA ADMISSION SCHEDULE</b></a> */}
								Admission completion date will be intimated later.
							</p>
						</div>
					) : (
						''
					)}
					{appInfo.allotment_made &&
					(appInfo.status === VjcetService.STATUS_ALLOTMENT_EXPIRED ||
						appInfo.status === VjcetService.STATUS_ALLOTMENT_REJECTED ||
						appInfo.status === VjcetService.STATUS_ALLOTMENT_CANCELLED) ? (
						<div className="allocation_section">
							<p className="text-style1">
								Unfortunately, the allotment offer has been either expired or rejected.
								<br />
							</p>
						</div>
					) : (
						''
					)}
					{appInfo.allotment_made &&
					appInfo.status === VjcetService.STATUS_ALLOTMENT_ACCEPTED &&
					appInfo.quota_id !== VjcetService.QUOTA_NRI ? (
						<div className="allocation_section">
							<p className="text-style1">
								You have got provisional admission to{' '}
								<span className="highlight">{allocted_branch}</span> branch. You have paid Rs.
								<span className="highlight">{appInfo.allotment_paid}</span> towards Tuition Fee. You are
								directed to pay the balance fee of Rs.
								<span className="highlight">{appInfo.allotment_balance}</span> immediately after the  {' '}
								<span className="highlight"> second phase govt. allotment</span>. Failure to pay will result in
								cancellation of provisional admission.
								<br />
							</p>
						</div>
					) : (
						''
					)}
					{appInfo.allotment_made && appInfo.status === VjcetService.STATUS_ALLOTMENT_OFFERED
						?  <div className="allocation_section">
						       <p className="highlight">CONFIRM YOUR ALLOTMENT BEFORE 5:00 PM, 22nd July 2024, ELSE ALLOTMENT WILL BE CANCELLED.</p>
						       <p className="text-style1">Please review the allocation and mark your choice using the buttons below.</p>
						       <div className="button_bar">
						           <Btn disabled={this.state.disable_btn} title='Accept' click={this.accept_allotment} />
						           <Btn disabled={this.state.disable_btn} title='Decline' click={this.reject_allotment} />
						       </div>
						       {this.state.disable_btn && <LinearProgress />}
						   </div>
						  
						: ''}

					{appInfo.status === VjcetService.STATUS_ALLOTMENT_WAITINGLIST ? (
						<div className="allocation_section">
							<p className="text-style1">
								You are in the waiting list. Visit College Admissions website daily for updates.
							</p>
						</div>
					) : (
						''
					)}

					{appInfo.allotment_made &&
					appInfo.status === VjcetService.STATUS_ALLOTMENT_ACCEPTED &&
					appInfo.quota_id !== VjcetService.QUOTA_NRI ? (
						<div className="allocation_section text-style1 ">
							{/* <p>To confirm your allotment please pay the required deposit amount to the College Bank details given below. Once payment is done, please provide the payment reference using the form below.</p> */}
							{/* <p className="highlight">PLEASE MAKE THE PAYMENT AND PROVIDE PAYMENT REFERENCE BY 12pm, 23th SEPTEMBER 2020.</p> */}							
							
							{/*<p>
								<span className='highlightwarning'> 
									<span className='textblink'>								
										<img src={WarningIcon} alt="Very Important" style={{ width: "30px", padding: "2px" }} /> 
									</span>Please quote your name and application number in the bank transaction reference/remarks.
								</span>
							</p>

							<br />	<br />							

							<p><span className="highlight">Bank Account Details for payment through NEFT</span></p>

							<p>Name of the Account Holder : PRINCIPAL</p>

							<p>Name of the Bank - South Indian Bank</p>

							<p>Name of the Branch - Vazhakulam - IFSC Code : SIBL0000335</p>

							<p>Account Number : 0335053000001199</p>
							
							<h3 className="title-style1"><span className="highlight"> OR </span>
							<p><center><img src={VJCETScanCode} alt="scan QR Code" style={{ width: "600px", paddingRight: "5px" }} /></center></p>
							</h3>
					*/}
						</div>
					) : (
						''
					)}
					{appInfo.allotment_made && (appInfo.status === VjcetService.STATUS_ALLOTMENT_REJECTED) ? (

                        <div className="allocation_section">
                            <p className="text-style1">Thank you for your application. We are sorry you have declined to accept the alloted option.</p>
                            <p className="text-style1">If you have made the choice in error, please contact us immediately using the details below.</p>
                        </div>
                    ) : ""}
                    {appInfo.allotment_made && (appInfo.status === VjcetService.STATUS_ALLOTMENT_PAID && appInfo.allotment_balance < 1) ? (

                        <div className="allocation_section">
                            <p className="text-style1">Your allocation payment reference is <span className="highlight">{appInfo.allotment_deposit_reference}</span></p>
                            <br />
                            <p className="text-style1">We will review the information and be in touch with next steps.</p>
                            <p className="text-style1">If you updated the value in error, please contact us immediately using the details below.</p>
                        </div>
                    ) : ""}
					{appInfo.allotment_made &&
					appInfo.status === VjcetService.STATUS_ALLOTMENT_ACCEPTED &&
					appInfo.quota_id !== VjcetService.QUOTA_NRI &&
					appInfo.allotment_balance > 0 ? (
						<div className="allocation_section">
							{/*<p className="title-style1">Enter your payment deposit reference </p>
							<div className="allocation_section_form">
								<TextField
									label="Bank Deposit Ref.No"
									onChange={handleChange}
									value={appInfo.allotment_deposit_reference}
									name="allotment_deposit_reference"
									id="allotment_deposit_reference"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Icon className="icon-style">person</Icon>
											</InputAdornment>
										),
									}}
								/>
								<Btn disabled={this.state.disable_btn} title="Submit" click={this.ref_submit} />
								
							</div>
							{this.state.disable_btn && <LinearProgress />}
								*/}
						</div>
					) : (
						''
					)}
					{appInfo.allotment_made &&
					appInfo.status === VjcetService.STATUS_ALLOTMENT_ACCEPTED &&
					appInfo.quota_id === VjcetService.QUOTA_NRI ? (
						<div className="allocation_section">
							<p className="text-style1">
								You have got provisional admission to{' '}
								<span className="highlight">{allocted_branch}</span> branch under{' '}
								<span className="highlight">NRI Quota</span>. You have paid Rs.
								<span className="highlight">{appInfo.allotment_paid}</span> towards 1st year fees.
								<br />
							</p>
							<br />
							<p className="text-style1">
								You shall <span className="highlight">report with parent or guardian</span> on the date
								and time mentioned through your email/SMS to complete the admission procedure. The list
								of the documents to be produced in original at the time of admission are given below.
							</p>
							<p className="text-style1">
								<ol style={{ margin: '10px 0px 10px 5vw' }}>
									<li>SSLC Book / Xth Marklist.</li>
									<li>XIIth Mark list.</li>
									{/*<li>XIIth Pass Certificate (for CBSE / ICSE).</li>*/}
									<li>Transfer Certificate from the institution last studied in original.</li>
									<li>
										Course and Conduct Certificate in original from the institution last studied.
									</li>
									<li>Passport size photographs (2Nos).</li>
									<li>Migration Certificate.</li>
									<li>Eligibility Certificate (Other than DHSE, CBSE & ICSE).</li>
									<li>Copy of Passport and Visa of NRI Sponsor.</li>
									<li>
										NRI undertaking from the Sponsor.{' '}
										<a href="/NRI-Undertaking.pdf" target="_blank">
											{' '}
											<b>Download NRI undertaking form</b>
										</a>
									</li>
								</ol>
							</p>
							<p className="text-style1">
								At the time of admission, the candidate should remit the{' '}
								<span className="highlight">remaining fee</span> of Rs.
								<span className="highlight">{appInfo.allotment_balance}</span> as per the fee structure
								either by cash or DD drawn in favor of ‘The Principal, Viswajyothi College of
								Engineering and Technology, Vazhakulam’ payable at Muvattupuzha. Payment can also be
								done through online mode <span className="highlight">(before 30th July 2024)</span>{' '}
								using the following Bank Account Details,
							</p>
							<div className="allocation_section text-style1 ">
								{/* <p>To confirm your allotment please pay the required deposit amount of <span className="highlight">Rs 75,000/-</span> to the College Bank deatils given below. Once payment is done, please provide the payment reference using the form below.</p> */}
								{/* <p className="highlight">PLEASE MAKE THE PAYMENT AND PROVIDE PAYMENT REFERENCE BY 12pm, 23th SEPTEMBER 2020.</p> */}

								<p>
									<span className='highlightwarning'> 
										<span className='textblink'>								
											<img src={WarningIcon} alt="Very Important" style={{ width: "30px", padding: "2px" }} /> 
										</span>Please quote your name and application number in the bank transaction reference/remarks.
									</span>
								</p>

								<br />
								<p>Bank Account Details for payment through NEFT,</p>

								<p>Name of the Account Holder : PRINCIPAL</p>

								<p>Name of the Bank - South Indian Bank</p>

								<p>Name of the Branch - Vazhakulam - IFSC Code : SIBL0000335</p>

								<p>Account Number : 0335053000001199</p>
																
								<h3 className="title-style1"><span className="highlight"> OR </span>
								<p><center><img src={VJCETScanCode} alt="scan QR Code" style={{ width: "600px", paddingRight: "5px" }} /></center></p>
								</h3>
							</div>
							<p className="text-style1">
								NOTE:
								<br />
								<span style={{ paddingLeft: '20px' }}>
									Admission process may continue up to 2 to 3 hours from the scheduled time. It will
									be on a first come first serve manner. Your full cooperation is solicited.
								</span>
							</p>
						</div>
					) : (
						''
					)}

					{this.state.failure && (
						<div className="allocation_section">
							<Alert variant="filled" severity="error">
								{this.state.failure}
							</Alert>
						</div>
					)}
					{this.state.success && (
						<div className="allocation_section">
							<Alert variant="filled" severity="success">
								{this.state.success}
							</Alert>
						</div>
					)}

					<div className="allocation_section">
						<p className="text-style1">
							For any queries regarding your application, please contact us email via at{' '}
							<span className="highlight">{appInfo.vjcet_admission_email}</span> or phone{' '}
							<span className="highlight">{appInfo.vjcet_admission_tel}</span>.
							<br /> Please use the Application Number for any communication regarding your application.
						</p>
					</div>

					
				</Container>
				<Footer />
			</div>
		);
	}
}

export default withRouter(Allocation);
