import React, { Component } from 'react'
import {
    Container,
    TextField,
    InputAdornment,

} from '@material-ui/core';
import Btn from '../Components/Button'
import ProgressBar from '../Components/ProgressBar'
import Icon from '@material-ui/core/Icon';
import Header from '../Components/Header'
import VjcetService from '../vjcet-service';
import Footer from '../Components/FormFooter'
import SubHeader from '../Components/SubHeader';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';



export class GuardianDetails extends Component {

    guardian_timeout = null;

    state = {
        error: this.props.errors,
        success: "",
        failure: ""
    }

    validate = () => {

        const error = {};
        const { values } = this.props;
        const phone_regex = /^\+?\d{10,15}$/gm;
        const mother_phone_regex = /^\+?\d{10,15}$/gm;
        const guardian_phone_regex = /^\+?\d{10,15}$/gm;

        if (values.father_name === "") {
            error.father_name = "Father's name is required";
        }
        if (values.father_occupation === "") {
            error.father_occupation = "Father's Occupation is required";
        }

        if (values.father_mobile === '' || values.father_mobile === undefined) {
            error.father_mobile = "Father's mobile is required";
        } else if (!phone_regex.test(values.father_mobile)) {
            error.father_mobile = "Invalid Father's phone number, please check";
        }

        if (values.mother_name === "") {
            error.mother_name = "Mother's Name is required";
        }
        if (values.mother_occupation === "") {
            error.mother_occupation = "Mother's occupation is required";
        }
        if (values.mother_mobile === "" || values.mother_mobile === undefined) {
            error.mother_mobile = "Mother's mobile is required";
        } else if (!mother_phone_regex.test(values.mother_mobile)) {
            error.mother_mobile = "Invalid Mother's phone number, please check";
        }

        if (values.annual_income === "") {
            error.annual_income = "Annual Income is required";
        }
        return Object.keys(error).length === 0 ? null : error;
    }

    save_data = (continue_after_save) => {

        const { values } = this.props;

        const error = this.validate();
        this.setState({
            error
        });
        let error_timeout = setTimeout(() => this.setState({ error: {} }), 3000);
        if (error) return;

        if (!this.state.loading) {
            const data = values;
            this.setState({ loading: true });
            this.guardian_timeout = setTimeout(() => this.setState({ loading: false }), 30000);

            VjcetService.submit_applicant_guardian_info(data).then((resp) => {

                let data = resp.data;
                if (data.success) {

                    if (error_timeout && error_timeout !== undefined) {
                        clearTimeout(error_timeout);
                    }

                    if (continue_after_save) {
                        this.props.nextStep(VjcetService.STAGE_EDUCATION);
                    } else {
                        this.props.logout();
                    }
                } else {
                    this.setState({ failure: "Error: Saving Guardian Details. Error : " + data.info });
                }

            }).catch(err => {

                if (error_timeout && error_timeout !== undefined) {
                    clearTimeout(error_timeout);
                }

                if (err.response) {
                    this.setState({ failure: " Failed request:Saving Guardian Details. Error : " + err.response.data.message });
                } else if (err.request) {
                    console.log("error req " + err.request);
                } else {
                    console.log("something else " + err);
                }
            });
        }
    }

    continue = (e) => {
        e.preventDefault();
        this.save_data(true);
    }

    save_and_exit = (e) => {
        e.preventDefault();
        this.save_data(false);
    }

    back = (e) => {
        e.preventDefault();
        this.props.prevStep(VjcetService.STAGE_CONCTACTS);
    }

    componentWillUnmount() {
        if (this.guardian_timeout && this.guardian_timeout !== undefined) {
            clearTimeout(this.guardian_timeout);
        }
    }


    render() {
        const { values, appInfo, handleChange, step, formValue } = this.props;
        const { error } = this.state;
        return (
            <div id="wrapper">
                <Header name="container-style" form={formValue} />
                <Container className="container-style" id="contact-container">
                    <SubHeader appInfo={appInfo} />
                    <ProgressBar step={step} />
                    <div id="parent-details" className="form-style">
                        <div className="permanent-add">
                            <h3 className="sub-title1">Parent Details</h3>
                            <div className="form-fields">
                                <TextField
                                    label="Father's Name"
                                    name="father_name"
                                    id="father_name"
                                    required
                                    value={values.father_name}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">home</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.father_name}</div>}

                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Father's Occupation"
                                    name="father_occupation"
                                    id="father_occupation"
                                    required
                                    value={values.father_occupation}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">location_on</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.father_occupation}</div>}

                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Father's Mobile"
                                    name="father_mobile"
                                    id="father_mobile"
                                    value={values.father_mobile}
                                    required
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">location_on</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.father_mobile}</div>}

                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Mother's Name"
                                    name="mother_name"
                                    id="mother_name"
                                    required
                                    value={values.mother_name}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">location_on</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.mother_name}</div>}

                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Mother's Occupation"
                                    name="mother_occupation"
                                    id="mother_occupation"
                                    required
                                    onChange={handleChange}
                                    value={values.mother_occupation}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">location_on</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.mother_occupation}</div>}
                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Mother's mobile"
                                    name="mother_mobile"
                                    id="mother_mobile"
                                    required
                                    value={values.mother_mobile}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">location_on</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.mother_mobile}</div>}

                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Annual Family Income"
                                    name="annual_income"
                                    id="annual_income"
                                    required
                                    value={values.annual_income}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">location_on</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {error && <div className="error-text">{error.annual_income}</div>}
                            </div>

                        </div>
                        <div className="guardian-dt">
                            <h3 className="sub-title1">Local Guardian Details(Optional)</h3>
                            <div className="form-fields">
                                <TextField
                                    label="Guardian's Name"
                                    name="guardian_name"
                                    id="guardian_name"
                                    value={values.guardian_name}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">home</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Relationship to Applicant"
                                    name="guardian_relationship"
                                    id="guardian_relationship"
                                    value={values.guardian_relationship}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">location_on</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Guardian's Mobile"
                                    name="guardian_mobile"
                                    id="guardian_mobile"
                                    value={values.guardian_mobile}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">location_on</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Address line 1"
                                    name="guardian_address_1"
                                    id="guardian_address_1"
                                    value={values.guardian_address_1}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">location_on</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Address line 2"
                                    name="guardian_address_2"
                                    id="guardian_address_2"
                                    value={values.guardian_address_2}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">location_on</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="input-inline">
                                <TextField
                                    label="City"
                                    name="guardian_address_city"
                                    id="guardian_address_city"
                                    value={values.guardian_address_city}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">location_on</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <TextField
                                    label="District"
                                    name="guardian_address_district"
                                    id="guardian_address_district"
                                    value={values.guardian_address_district}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">location_on</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                            </div>

                            <div className="input-inline">
                                <TextField
                                    label="Pincode"
                                    name="guardian_address_pincode"
                                    id="guardian_address_pincode"
                                    value={values.guardian_address_pincode}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">location_on</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <TextField
                                    label="State"
                                    name="guardian_address_state"
                                    id="guardian_address_state"
                                    value={values.guardian_address_state}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">location_on</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {this.state.loading && <LinearProgress />}

                    {this.state.failure && <Alert variant="filled" severity="error">
                        {this.state.failure}
                    </Alert>}
                    {this.state.success && <Alert variant="filled" severity="success" >
                        {this.state.success}
                    </Alert>}

                    <div className="btn-style">
                        <Btn title='Previous' click={this.back} />
                        <Btn title='Save and Exit' click={this.save_and_exit} />
                        <Btn title='Save and Proceed' click={this.continue} />
                    </div>
                </Container>
                <Footer />

            </div>


        )
    }
}



export default GuardianDetails
