import React, { Component } from 'react'
import {
    Container,
    TextField,
    InputAdornment,
    Avatar,
    Button, IconButton
} from '@material-ui/core';
import Btn from '../Components/Button'
import document from '../images/icons/document.png'
import ProgressBar from '../Components/ProgressBar'
import Icon from '@material-ui/core/Icon';
import Header from '../Components/Header'
import Footer from '../Components/FormFooter'
import SubHeader from '../Components/SubHeader';
import VjcetService from '../vjcet-service';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';


export class DocumentDetails extends Component {

    doc_timeout = null;

    constructor(props) {
        super(props);
        this.state = {
            error: {},
            application_signature: null,
            parent_signature: null,
            tenth_certificate: null,
            twelth_certificate: null,
            entrance_marklist: null,
            entrance_exam_admit_card: null,
            community_certificate: null,
            success: "",
            failure: ""
        }
    }

    validate = () => {

        const error = {};
        const { values, appInfo } = this.props;

        if (values.application_signature_url === '') {
            error.application_signature = "Applicant signature is required";
        }

        if (values.parent_signature_url === '') {
            error.parent_signature = "Parent signature is required";
        }

        if (values.tenth_certificate_url === '') {
            error.tenth_certificate = "10th certificate is required";
        }

        if (values.twelth_certificate_url === '') {
            error.twelth_certificate = "12th certificate is required";
        }

        //if not NRI entrance marklist and card are required
        if (appInfo.quota_id !== VjcetService.QUOTA_NRI) {

            if (values.entrance_marklist_url === '') {
                 error.entrance_marklist = "Entrance marklist is required";
            }

            if (values.entrance_exam_admit_card_url === '') {
                error.entrance_exam_admit_card = "Entance exam admit card is required";
            }

        }


        return Object.keys(error).length === 0 ? null : error;
    }


    save_data = (continue_after_save) => {

        const { values } = this.props;
        const error = this.validate();
        this.setState({
            error
        });
        let error_timeout = setTimeout(() => this.setState({ error: {} }), 3000);
        if (error) return;
        // const data = values;
        if (!this.state.loading) {

            this.setState({ loading: true });
            this.doc_timeout = setTimeout(() => this.setState({ loading: false }), 300000);

            let formData = new FormData();
            if (values.application_signature && values.application_signature !== undefined) {
                formData.append('application_signature', values.application_signature);
            }

            if (values.parent_signature && values.parent_signature !== undefined) {
                formData.append('parent_signature', values.parent_signature);
            }

            if (values.tenth_certificate && values.tenth_certificate !== undefined) {
                formData.append('tenth_certificate', values.tenth_certificate);
            }

            if (values.twelth_certificate_url && values.twelth_certificate_url !== undefined) {
                formData.append('twelfth_certificate', values.twelth_certificate);
            }

            if (values.entrance_marklist && values.entrance_marklist !== undefined) {
                formData.append('entrance_marklist', values.entrance_marklist);
            }

            if (values.entrance_exam_admit_card && values.entrance_exam_admit_card !== undefined) {
                formData.append('entrance_exam_admit_card', values.entrance_exam_admit_card);
            }

            if (values.community_certificate && values.community_certificate !== undefined) {
                formData.append('community_certificate', values.community_certificate);
            }


            VjcetService.submit_applicant_documents_info(formData).then((resp) => {

                let data = resp.data;
                if (data.success) {

                    if (error_timeout && error_timeout !== undefined) {
                        clearTimeout(error_timeout);
                    }

                    if (continue_after_save) {
                        this.props.nextStep(VjcetService.STAGE_SUMMARY);
                    } else {
                        this.props.logout();
                    }
                } else {
                    this.setState({ failure: "Error: Saving Applicant Documents. Error : " + data.info });
                }

            }).catch(err => {

                if (error_timeout && error_timeout !== undefined) {
                    clearTimeout(error_timeout);
                }

                if (err.response) {
                    this.setState({ failure: " Failed request:  Saving Applicant Documents. Error : " + err.response.data.message });
                } else if (err.request) {
                    console.log("error req " + err.request);
                } else {
                    console.log("something else " + err);
                }
            });
        }

    }

    continue = (e) => {
        e.preventDefault();
        this.save_data(true);
    }

    save_and_exit = (e) => {
        e.preventDefault();
        this.save_data(false);
    }

    back = (e) => {
        e.preventDefault();
        this.props.prevStep(VjcetService.STAGE_COURSE_PREFERENCE);
    }

    componentWillUnmount() {
        if (this.doc_timeout && this.doc_timeout !== undefined) {
            clearTimeout(this.doc_timeout);
        }
    }

    doc_selected = (doc_type, e) => {

        switch (doc_type) {

            case 1:
                this.setState({ application_signature: e.target.files[0] });
                this.props.documentChangeHandler(e.target.files[0])
                break;

            case 2:
                this.setState({ parent_signature: e.target.files[0] });
                break;

            case 3:
                this.setState({ tenth_certificate: e.target.files[0] });
                break;

            case 4:
                this.setState({ twelfth_certificate: e.target.files[0] });
                break;

            case 5:
                this.setState({ entrance_marklist: e.target.files[0] });
                break;

            case 6:
                this.setState({ entrance_exam_admit_card: e.target.files[0] });
                break;


            case 7:
                this.setState({ community_certificate: e.target.files[0] });
                break;

            default:
                break;

        }

    }
    render() {
        const { values, appInfo, step, documentChangeHandler, formValue } = this.props;
        const { error } = this.state;

        return (
            <div id="wrapper" >
                <Header name="container-style" form={formValue} />
                <Container className="container-style">
                    <SubHeader appInfo={appInfo} />
                    <ProgressBar step={step} />
                    <h3 className="sub-title1">Documents</h3><br/>
                    <span className="text-base font-semibold text-yellow-500">*Please ensure all uploaded documents are clear and legible.</span>
                    <div id="document-details" className="form-style" >
                        <div className="form-fields">
                            <div className="btn btn-file">
                                <IconButton aria-label="delete">
                                    <Avatar alt="Remy Sharp" src={document} className="icon-style" />
                                </IconButton>
                                <span className="placeholder-style mb-2">Applicant Signature(jpeg/png only)*</span>

                                {values.application_signature_url.length > 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-uploaded">File Selected</span>
                                    <label for="application_signature" className="label-style">Choose file</label>
                                    <input
                                        type="file"
                                        id="application_signature"
                                        name="application_signature"
                                        value={this.state.application_signature}
                                        accept="image/png,image/jpeg,image/jpg"
                                        onChange={documentChangeHandler}
                                        style={{ display: "none" }}
                                    />
                                </div>
                                )}
                                {values.application_signature_url.length === 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-upload">Please Upload</span>
                                    <label for="application_signature" className="label-style">Choose file</label>
                                    <input
                                        type="file"
                                        id="application_signature"
                                        name="application_signature"
                                        value=""
                                        accept="image/png,image/jpeg,image/jpg"
                                        onChange={documentChangeHandler}
                                        style={{ display: "none" }}
                                    />
                                </div>
                                )}

                            </div>
                            {error && <div className="error-text">{error.application_signature}</div>}
                            {values.application_signature_error && (<div className="error-text">{values.application_signature_error}</div>)}

                        </div>
                        <div className="form-fields">
                            <div className="btn btn-file">
                                <IconButton aria-label="delete">
                                    <Avatar alt="Remy Sharp" src={document} className="icon-style" />
                                </IconButton>
                                <span className="placeholder-style">Parent Signature(jpeg/png only)*</span>
                                {values.parent_signature_url.length > 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-uploaded">File Selected</span>
                                    <label for="parent_signature" className="label-style">Choose file</label>
                                    <input
                                        type="file"
                                        id="parent_signature"
                                        name="parent_signature"
                                        value=""
                                        accept="image/png,image/jpeg,image/jpg"
                                        onChange={documentChangeHandler}
                                        style={{ display: "none" }}
                                    />
                                </div>
                                )}
                                {values.parent_signature_url.length === 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-upload">Please Upload</span>
                                    <label for="parent_signature" className="label-style">Choose file</label>
                                    <input
                                        type="file"
                                        id="parent_signature"
                                        name="parent_signature"
                                        value=""

                                        accept="image/png,image/jpeg,image/jpg"
                                        onChange={documentChangeHandler}
                                        style={{ display: "none" }}
                                    />
                                </div>
                                )}
                            </div>
                            {error && <div className="error-text">{error.parent_signature}</div>}
                            {values.parent_signature_error && (<div className="error-text">{values.parent_signature_error}</div>)}

                        </div>
                        <div className="form-fields">
                            <div className="btn btn-file">
                                <IconButton aria-label="delete">
                                    <Avatar alt="Remy Sharp" src={document} className="icon-style" />
                                </IconButton>
                                <span className="placeholder-style">10th certificate*</span>
                                {values.tenth_certificate_url.length > 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-uploaded">File Selected</span>
                                    <label for="tenth_certificate" className="label-style">Choose file</label>
                                    <input
                                        type="file"
                                        id="tenth_certificate"
                                        name="tenth_certificate"
                                        value=""
                                        accept="image/png,image/jpeg,image/jpg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        onChange={documentChangeHandler}
                                        style={{ display: "none" }}
                                    />
                                </div>
                                )}
                                {values.tenth_certificate_url.length === 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-upload">Please Upload</span>
                                    <label for="tenth_certificate" className="label-style">Choose file</label>
                                    <input
                                        type="file"
                                        id="tenth_certificate"
                                        name="tenth_certificate"
                                        value=""
                                        accept="image/png,image/jpeg,image/jpg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        onChange={documentChangeHandler}
                                        style={{ display: "none" }}
                                    />
                                </div>
                                )}
                            </div>
                            {error && <div className="error-text">{error.tenth_certificate}</div>}
                            {values.tenth_certificate_error && (<div className="error-text">{values.tenth_certificate_error}</div>)}

                        </div>
                        <div className="form-fields">
                            <div className="btn btn-file">
                                <IconButton aria-label="delete">
                                    <Avatar alt="Remy Sharp" src={document} className="icon-style" />
                                </IconButton>
                                <span className="placeholder-style">12th Certificate*</span>
                                {values.twelth_certificate_url.length > 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-uploaded">File Selected</span>
                                    <label for="twelth_certificate" className="label-style">Choose file</label>
                                    <input
                                        type="file"
                                        id="twelth_certificate"
                                        name="twelth_certificate"
                                        value=""
                                        accept="image/png,image/jpeg,image/jpg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        onChange={documentChangeHandler}
                                        style={{ display: "none" }}
                                    />
                                </div>
                                )}
                                {values.twelth_certificate_url.length === 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-upload">Please Upload</span>
                                    <label for="twelth_certificate" className="label-style">Choose file</label>
                                    <input
                                        type="file"
                                        id="twelth_certificate"
                                        name="twelth_certificate"
                                        value=""
                                        accept="image/png,image/jpeg,image/jpg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        onChange={documentChangeHandler}
                                        style={{ display: "none" }}
                                    />
                                </div>
                                )}
                            </div>
                            {error && <div className="error-text">{error.twelth_certificate}</div>}
                            {values.twelth_certificate_error && (<div className="error-text">{values.twelth_certificate_error}</div>)}

                        </div>

                        <div className="form-fields">
                            <div className="btn btn-file">
                                <IconButton aria-label="delete">
                                    <Avatar alt="Remy Sharp" src={document} className="icon-style" />
                                </IconButton>
                                {appInfo.quota_id !== VjcetService.QUOTA_NRI && (<span className="placeholder-style">Entrance Marklist*</span>)}
                                {appInfo.quota_id === VjcetService.QUOTA_NRI && (<span className="placeholder-style">Entrance Marklist (*optional for NRI Quota)</span>)}

                                {values.entrance_marklist_url.length > 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-uploaded">File Selected</span>
                                    <label for="entrance_marklist" className="label-style">Choose file</label>
                                    <input
                                        type="file"
                                        id="entrance_marklist"
                                        name="entrance_marklist"
                                        value=""
					
                                        accept="image/png,image/jpeg,image/jpg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        onChange={documentChangeHandler}
                                        style={{ display: "none" }}
                                    />
                                </div>
                                )}
                                {values.entrance_marklist_url.length === 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-upload">Please Upload </span>
                                    <label for="entrance_marklist" className="label-style">Choose file</label>
                                    <input
                                        type="file"
                                        id="entrance_marklist"
                                        name="entrance_marklist"
                                        value=""
							
                                        accept="image/png,image/jpeg,image/jpg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        onChange={documentChangeHandler}
                                        style={{ display: "none" }}
                                    />
                                </div>
                                )}
                            </div>
                            {error && <div className="error-text">{error.entrance_marklist}</div>}
                            {values.entrance_marklist_error && (<div className="error-text">{values.entrance_marklist_error}</div>)}
                        </div>



                        <div className="form-fields">
                            <div className="btn btn-file">
                                <IconButton aria-label="delete">
                                    <Avatar alt="Remy Sharp" src={document} className="icon-style" />
                                </IconButton>

                                {appInfo.quota_id !== VjcetService.QUOTA_NRI && (<span className="placeholder-style">Entrance Exam Admit Card*</span>)}
                                {appInfo.quota_id === VjcetService.QUOTA_NRI && (<span className="placeholder-style">Entrance Exam Admit Card (*optional for NRI Quota)*</span>)}
                                {values.entrance_exam_admit_card_url.length > 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-uploaded">File Selected</span>
                                    <label for="entrance_exam_admit_card" className="label-style">Choose file</label>
                                    <input
                                        type="file"
                                        id="entrance_exam_admit_card"
                                        name="entrance_exam_admit_card"
                                        value=""
                                        accept="image/png,image/jpeg,image/jpg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        onChange={documentChangeHandler}
                                        style={{ display: "none" }}
                                    />
                                </div>
                                )}
                                {values.entrance_exam_admit_card_url.length === 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-upload">Please Upload</span>
                                    <label for="entrance_exam_admit_card" className="label-style">Choose file</label>
                                    <input
                                        type="file"
                                        id="entrance_exam_admit_card"
                                        name="entrance_exam_admit_card"
                                        value=""
                                        accept="image/png,image/jpeg,image/jpg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        onChange={documentChangeHandler}
                                        style={{ display: "none" }}
                                    />
                                </div>
                                )}
                            </div>
                            {error && <div className="error-text">{error.entrance_exam_admit_card}</div>}
                            {values.entrance_exam_admit_card_error && (<div className="error-text">{values.entrance_exam_admit_card_error}</div>)}
                        </div>

                        <div className="form-fields">
                            <div className="btn btn-file">
                                <IconButton aria-label="delete">
                                    <Avatar alt="Remy Sharp" src={document} className="icon-style" />
                                </IconButton>
                                <span className="placeholder-style">Community Certificate</span>
                                {values.community_certificate_url.length > 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-uploaded">File Selected</span>
                                    <label for="community_certificate" className="label-style">Choose file</label>
                                    <input
                                        type="file"
                                        id="community_certificate"
                                        name="community_certificate"
                                        value=""
                                        accept="image/png,image/jpeg,image/jpg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        onChange={documentChangeHandler}
                                        style={{ display: "none" }}
                                    />
                                </div>
                                )}
                                {values.community_certificate_url.length === 0 && (<div className="btn-file-status">
                                    <span className="file-status-to-upload">Please Upload</span>
                                    <label for="community_certificate" className="label-style">Choose file</label>
                                    <input
                                        type="file"
                                        id="community_certificate"
                                        name="community_certificate"
                                        value=""
                                        accept="image/png,image/jpeg,image/jpg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        onChange={documentChangeHandler}
                                        style={{ display: "none" }}
                                    />
                                </div>
                                )}
                            </div>
                            {error && <div className="error-text">{error.community_certificate}</div>}
                            {values.community_certificate_error && (<div className="error-text">{values.community_certificate_error}</div>)}
                        </div>
                    </div>

                    {this.state.loading && <LinearProgress />}
                    {this.state.loading && <div>Depending on the total file size, the file upload may take some time to complete.</div>}

                    {this.state.failure && <Alert variant="filled" severity="error">
                        {this.state.failure}
                    </Alert>}
                    {this.state.success && <Alert variant="filled" severity="success" >
                        {this.state.success}
                    </Alert>}


                    <div className="btn-style">
                        <Btn title='Previous' click={this.back} />
                        <Btn title='Save and Exit' click={this.save_and_exit} />
                        <Btn title='Save and Proceed' click={this.continue} />
                    </div>
                </Container>
                <Footer />

            </div>


        )
    }
}



export default DocumentDetails
