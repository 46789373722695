import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { TextField, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, Button } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Btn from '../Button'
import Icon from '@material-ui/core/Icon';
import './style.css'
import Joi from 'joi-browser';
import VjcetService from '../../vjcet-service';
import Alert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FlashAutoRounded } from '@material-ui/icons';




export default class SignupModal extends Component {

	signup_timeout = null;

	state = {
		account: { first_name: "", last_name: "",  email: "", phone: "",password: "", password_confirmation: "", quota_id: "", application_year: VjcetService.APPLICATION_YEAR },
		modal: { open: false },
		errors: {},
		success: "",
		failure: "",
		loading: false,
		disable_btn: false,
	}

	schema = {
		first_name: Joi.string().required().label("First Name"),
		last_name: Joi.string().required().label("Last Name"),
		email: Joi.string().email({ minDomainAtoms: 2 }).required().label("Email"),
		phone: Joi.string().required().label("Mobile Number"),
		
		password: Joi.string().min(8).max(15).required().label("Password"),
		password_confirmation: Joi.any().valid(Joi.ref('password')).required().options({ language: { any: { allowOnly: 'must match password' } } }),
		quota_id: Joi.string().required().label("Quota"),
		application_year: Joi.string(),

	}

	handleOpen = () => {
		this.setState({
			account: { first_name: "", last_name: "",  email: "",phone: "", password: "", password_confirmation: "", quota_id: "", application_year: "2025" },
			disable_btn: false,
			success:"",
			modal: { open: true }
		});
	};

	handleClose = () => {
		this.setState({
			modal: { open: false }
		});
	};


	validate = () => {

		const options = { abortEarly: false };
		const { error } = Joi.validate(this.state.account, this.schema, options);

		//console.log(error);
		if (!error) return null;
		const errors = {};
		for (let item of error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	}

	validateProperty = ({ name, value }) => {
		const { error } = Joi.validate(this.state.account, this.schema);
		return error ? error.details[0].message : null;
	}

	//events assocaited with form and input fields
	handleChange = async ({ currentTarget: input }) => {

		const account = { ...this.state.account };
		let errors = { ...this.state.errors };
		account[input.name] = input.value;
		errors = {};
		await this.setState({ account, errors });

		const errorMessage = this.validateProperty(input);

		if (errorMessage) {
			errors[input.name] = errorMessage;
		} else {
			delete errors[input.name];
		}
		this.setState({ account, errors });
	}
	close_modal = async (e) => {

		this.setState({
			modal: { open: false },
			account: { first_name: "", last_name: "", phone: "", email: "", password: "", password_confirmation: "", quota_id: "", application_year: "2025" },
			errors: {},
			success: "",
			failure: ""

		})
	}

	handleSubmit = async (e) => {

		e.preventDefault();
		const errors = this.validate();
		this.setState({ errors: errors || {} });
		if (errors) return;
		//this.setState({ disable_btn: true });
		if (!this.setState.loading) {

			this.setState({ loading: true });
			this.setState({ disable_btn: true });

			this.signup_timeout = setTimeout(() => this.setState({ loading: false }), 30000);

			let obj = {
				first_name: this.state.account.first_name,
				last_name: this.state.account.last_name,
				phone: this.state.account.phone,
				email: this.state.account.email,
				password: this.state.account.password,
				password_confirmation: this.state.account.password_confirmation,
				application_year: this.state.account.application_year,
				quota_id: this.state.account.quota_id,
			};

			VjcetService.signup(obj).then((resp) => {
				const data = resp.data;
				this.setState({ loading: false });
				if (data.success) {

					this.setState({
						success: "Thank you for registering. Your application will be considered as late application. An email with a verification link has been sent to your email. You will need to verify your email before being able to proceed with your application. The verification link is only valid for 24 hours. If you haven't received an email in the next few hours, please check your SPAM folder."
					});
					this.setState({ disable_btn: true });
					// setTimeout(() => this.close_modal(), 30000);

				} else if (!data.success && data.code === 200) {
					this.setState({
						failure: data.info
					});
					setTimeout(() => this.setState({ failure: "" }), 3000);
					this.setState({ disable_btn: false });
				}
			}).catch(err => {
				this.setState({ loading: false });
				if (err.response) {
					this.setState({ failure: " Failed request: Saving signup info : " + err.response.data.message });
					setTimeout(() => this.setState({ failure: "" }), 3000);
				} else if (err.request) {
					console.log("error req " + err.request);
				} else {
					console.log("something else " + err);
				}
				this.setState({ disable_btn: false });
			});
		}
	}

	componentWillUnmount() {
		if (this.signup_timeout && this.signup_timeout !== undefined) {
			clearTimeout(this.signup_timeout);
		}

	}

	render() {
		const { account, modal, errors, success, failure } = this.state;
		return (
			<div>
			
				{/* <Btn title="Signup" click={this.handleOpen} /> */}
 			

				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className="modal"
					open={modal.open}
					onClose={this.handleClose}
					closeAfterTransition

					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={modal.open}>
						<form className="paper" id="signup-form" onSubmit={this.handleSubmit} noValidate autoComplete="off">
							<FormControl component="fieldset">
								<FormLabel component="h1" >Online Application {VjcetService.APPLICATION_YEAR}</FormLabel>
								<FormLabel component="h5" >Please select the Quota you are applying under:</FormLabel>
								<RadioGroup row aria-label="position" name="quota_id" id="quota_id" onChange={this.handleChange} defaultValue="top" className="radio-label-style">
									<FormControlLabel value="1" control={<Radio color="primary" />} label="Management" />
									<FormControlLabel value="2" control={<Radio color="primary" />} label="NRI/NRI Sponsored" />
								</RadioGroup>
							</FormControl>
							{errors && <div className="error-text">{errors.quota_id}</div>}

							<TextField
								label="First Name"
								name="first_name"
								id="first_name"
								type="text"
								placeholder="First Name"
								onChange={this.handleChange}
								defaultValue={account.first_name}
								required
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Icon className="icon-style">lock</Icon>
										</InputAdornment>
									),
								}}
							/>
							{errors && <div className="error-text">{errors.first_name}</div>}

							{/* <TextField
								label="Middle Name"
								name="middle_name"
								id="middle_name"
								type="text"
								placeholder="Middle Name"
								onChange={this.handleChange}
								defaultValue={account.middle_name}
								required
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Icon className="icon-style">person</Icon>
										</InputAdornment>
									),
								}}
							/> */}


							<TextField
								label="Last Name"
								name="last_name"
								id="last_name"
								type="text"
								placeholder="Last Name"
								onChange={this.handleChange}
								defaultValue={account.last_name}
								required
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Icon className="icon-style">person</Icon>
										</InputAdornment>
									),
								}}
							/>
							{errors && <div className="error-text">{errors.last_name}</div>}

							<TextField
								label="email"
								name="email"
								id="email"
								type="email"
								placeholder="Email Address"
								onChange={this.handleChange}
								defaultValue={account.email}
								required
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Icon className="icon-style">mail</Icon>
										</InputAdornment>
									),
								}}
							/>
							{errors && <div className="error-text">{errors.email}</div>}

							<TextField
								label="Phone number"
								name="phone"
								id="phone"
								type="number"
								placeholder="Mobile number"
								onChange={this.handleChange}
								defaultValue={account.phone}
								required
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Icon className="icon-style">phone</Icon>
										</InputAdornment>
									),
								}}
							/>
							{errors && <div className="error-text">{errors.phone}</div>}

							<TextField
								label="Password"
								name="password"
								id="password"
								type="password"
								autoComplete="new-password"
								placeholder="Password of your choice"
								onChange={this.handleChange}
								defaultValue={account.password}
								required
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Icon className="icon-style">lock</Icon>
										</InputAdornment>
									),
								}}
							/>
							{errors && <div className="error-text">{errors.password}</div>}

							<TextField
								label="Confirm Password"
								name="password_confirmation"
								id="password_confirmation"
								type="password"
								autoComplete="confirm-password"
								placeholder="Password of your choice"
								onChange={this.handleChange}
								required
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Icon className="icon-style">lock</Icon>
										</InputAdornment>
									),
								}}
							/>
							{errors && <div className="error-text">{errors.password_confirmation}</div>}


							{this.state.loading && <LinearProgress />}

							{this.state.failure && <Alert variant="filled" severity="error">
								{this.state.failure}
							</Alert>}

							{
								success && <Alert variant="filled" severity="success" onClose={this.close_modal}>
									{success}
								</Alert>
							}

							<input type="hidden" value={account.application_year} name="application_year" id="application_year" />
							<div className="btn-style">
								<Btn type="submit" title="Submit" disabled={this.state.disable_btn}  value="submit" id="submit-btn" />
							</div>


						</form>
					</Fade>
				</Modal>


			</div>
		);

	}


}
