import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import VjcetService from '../vjcet-service';


export const ProtectedRoute = ({ component: Component, ...rest }) => {

	return (
		<Route {...rest}
			render={props => {
				if (VjcetService.is_authenticated()) {
					return <Component {...props} />;
				} else {
					return (
						<Redirect
							to={{
								pathname: "/",
								state: {
									from: props.location
								}
							}}
						/>
					);
				}
			}}
		/>
	);
};
