import React, { Component } from 'react'
import {
    Container,
    TextField,
    InputAdornment,
    MenuItem,
	IconButton,
	Avatar
} from '@material-ui/core';
import Btn from '../Components/Button'
import ProgressBar from '../Components/ProgressBar'
import Header from '../Components/Header'
import Footer from '../Components/FormFooter'
import { withRouter } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import VjcetService from '../vjcet-service';
import Icon from '@material-ui/core/Icon';
import { FormatBoldTwoTone } from '@material-ui/icons';
import document from '../images/icons/document.png';
const keam_regex = /^\d+\.\d{4}$/;

export class Submission extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: {},
            success: "",
            failure: "",
			entrance_marklist: null,
			application_signature: null,
			showMessage: false,

        }


    }


	validate = () => {

		const error = {};
        const { values, appInfo } = this.props;

		if (appInfo.quota_id === VjcetService.QUOTA_MANAGEMENT) {
           
            if (values.paper_1_score === "") {
                error.paper_1_score = "KEAM Paper1 is required";
            } else if (!keam_regex.test(values.paper_1_score)) {
                error.paper_1_score = "Please enter the marks as in marksheet with 4 decimal places(e.g 587.1230)"
            }
            if (values.paper_2_score === "") {
                error.paper_2_score = "KEAM Paper2 is required";
            } else if (!keam_regex.test(values.paper_2_score)) {
                error.paper_2_score = "Please enter the marks as in marksheet with 4 decimal places (e.g 587.1230)"
            }

        }

        return Object.keys(error).length === 0 ? null : error;

	}

    continue = (e) => {
        e.preventDefault();
        this.props.history.push('/');
    }

    back = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

	doc_selected = (doc_type, e) => {

        switch (doc_type) {

            case 1:
                this.setState({ application_signature: e.target.files[0] });
                this.props.documentChangeHandler(e.target.files[0])
                break;

            case 2:
                this.setState({ parent_signature: e.target.files[0] });
                break;

            case 3:
                this.setState({ tenth_certificate: e.target.files[0] });
                break;

            case 4:
                this.setState({ twelfth_certificate: e.target.files[0] });
                break;

            case 5:
                this.setState({ entrance_marklist: e.target.files[0] });
                break;

            case 6:
                this.setState({ entrance_exam_admit_card: e.target.files[0] });
                break;


            case 7:
                this.setState({ community_certificate: e.target.files[0] });
                break;

            default:
                break;

        }

	}

    download_application = (e) => {
        e.preventDefault();
        VjcetService.download_summary().then((resp) => {
            let data = resp.data;
            if (data.success) {
                VjcetService.open_download_doc(data.link);
            } else {
                this.setState({ failure: "Error: Opening Summary Documents. Error : " + data.info });
                setTimeout(() => this.setState({ failure: "" }), 3000);
            }

        }).catch(err => {
            if (err.response) {
                this.setState({ failure: " Failed request: Opening Summary document. Error : " + err.response.data.message });
                setTimeout(() => this.setState({ failure: "" }), 3000);

            } else if (err.request) {
                console.log("error req " + err.request);
            } else {
                console.log("something else " + err);
            }
        });
    }

	update_documents = (e) => {
		e.preventDefault();
		const { appInfo, values, documents } = this.props;

        const error = this.validate();
        this.setState({
            error
        });
        let error_timeout = setTimeout(() => this.setState({ error: {} }), 3000);
        if (error) return;

        if (!this.state.loading) {
			this.setState({ loading: true });
            this.education_timeout = setTimeout(() => this.setState({ loading: false }), 30000);

            const data = values;
			let formData = new FormData();

            if (documents.application_signature && documents.application_signature !== undefined) {
                formData.append('application_signature', documents.application_signature);
            }

			if (documents.parent_signature && documents.parent_signature !== undefined) {
                formData.append('parent_signature', documents.parent_signature);
            }
			if (appInfo.photo && appInfo.photo !== undefined) {
                formData.append('photo', appInfo.photo);
            }

			VjcetService.submit_applicant_photo(formData).then((resp) => {

                let data = resp.data;
                if (data.success) {

                    if (error_timeout && error_timeout !== undefined) {
                        clearTimeout(error_timeout);
                    }

                  
                } else {
                    this.setState({ failure: "Error: Saving Education Details. Error : " + data.info });
                    setTimeout(() => this.setState({ failure: "" }), 3000);
                }

            }).catch(err => {

                if (error_timeout && error_timeout !== undefined) {
                    clearTimeout(error_timeout);
                }

                if (err.response) {
                    this.setState({ failure: " Failed request:Saving Education Details. Error : " + err.response.data.message });
                } else if (err.request) {
                    console.log("error req " + err.request);
                } else {
                    console.log("something else " + err);
                }
            });
		}
	}

	update_keam_score = (e) => {
        e.preventDefault();
		const { values, documents } = this.props;
	

        const error = this.validate();
        this.setState({
            error
        });
        let error_timeout = setTimeout(() => this.setState({ error: {} }), 3000);
        if (error) return;

        if (!this.state.loading) {
			this.setState({ loading: true });
            this.education_timeout = setTimeout(() => this.setState({ loading: false }), 30000);

            const data = values;
			let formData = new FormData();

            if (documents.entrance_marklist && documents.entrance_marklist !== undefined) {
                formData.append('entrance_marklist', documents.entrance_marklist);
            }

			if(values.paper_1_score && values.paper_1_score !== undefined){
				formData.append('paper_1_score', values.paper_1_score);
			}

			if(values.paper_2_score && values.paper_2_score !== undefined){
				formData.append('paper_2_score', values.paper_2_score);
			}
			
			VjcetService.submit_applicant_keam_info(formData).then((resp) => {

                let data = resp.data;
                if (data.success) {
					console.log('success updated.')
                    if (error_timeout && error_timeout !== undefined) {
                        clearTimeout(error_timeout);
                    }

					this.setState({ showMessage: true });
					setTimeout(() => this.setState({ showMessage: false }), 3000);
                  
                  
                } else {
                    this.setState({ failure: "Error: Saving Education Details. Error : " + data.info });
                    setTimeout(() => this.setState({ failure: "" }), 3000);
                }

            }).catch(err => {

                if (error_timeout && error_timeout !== undefined) {
                    clearTimeout(error_timeout);
                }

                if (err.response) {
                    this.setState({ failure: " Failed request:Saving Education Details. Error : " + err.response.data.message });
                } else if (err.request) {
                    console.log("error req " + err.request);
                } else {
                    console.log("something else " + err);
                }
            });
		}
       
    }

    render() {
        // const { appInfo, step, formValue } = this.props;

		const { values, appInfo, handleChange, documentChangeHandler,documents,step, formValue,profileImageChanged } = this.props;
        const { error } = this.state;

		// console.log(appInfo);

        return (
            <div id="wrapper" className="height">
                <Header name="container-style" form={formValue} />
                <Container className="container-style">
                    <ProgressBar step={step} />

					<div>
						<h3 className="font-semibold text-xl my-5 text-center">Thank you for submitting your application</h3>
						<p className="text-style1">
							Your Application Number is <span className="highlight">{appInfo.application_id}</span>.
						</p>
						{/* <p className="text-style1 text-red-400">
						Once the KEAM 2024 score is published, Management quota (Non NRI) applicants need to update the KEAM score and related attachments in your login.
						</p> */}

						<p className="text-style1">
							We will review your application and contact you if we require any further details.
						</p>
						<p>
							For any queries regarding your application , please contact us email via at <span className="highlight">{appInfo.vjcet_admission_email}</span> or phone <span className="highlight">{appInfo.vjcet_admission_tel}</span>.
							<br /> Please use the Application Number for any communication regarding your application.
						</p>


						{this.state.failure && <Alert variant="filled" severity="error">
							{this.state.failure}
						</Alert>}
						{this.state.success && <Alert variant="filled" severity="success" >
							{this.state.success}
						</Alert>}

					
		{/*KEAM mark second time entry start...............*/}
				
						{/*appInfo.quota_id === VjcetService.QUOTA_MANAGEMENT && ( 
						
						<div id="education-details" className="w-full">

							

						<div className="w-full">
							

							<h3 className="mt-5 font-extrabold">KEAM Exam {VjcetService.APPLICATION_YEAR} Details</h3>
							<div className="mt-4 mb-6 italic font-semibold text-base text-gray-400">

								** Please update your KEAM 21 score below if its not already done and showing. Your application will not be considered untill you have updated the KEAM 21 score. Please download your application as PDF below to confim all your details are correct.	
							</div>

							{appInfo.quota_id === VjcetService.QUOTA_NRI && (
								<h3 style={{ color: "#000", fontWeight: 500, fontSize: "0.8rem", fontStyle: "italic" }}>(*KEAM Details are optional for NRI Quota)</h3>
							)}
							
							<div className="flex w-full flex-row flex-wrap">
							<div className="form-fields w-1/2 pr-4 flex-1">
								<TextField
									label="KEAM Paper I Score with 4 decimal places (e.g 587.1230)"
									onChange={handleChange}
									value={values.paper_1_score}
									name="paper_1_score"
									id="paper_1_score"
									error={(appInfo.quota_id === 2 || keam_regex.test(values.paper_1_score)) ? false : true}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Icon className="icon-style">description</Icon>
											</InputAdornment>
										),
									}}
								/>
								{error && <div className="error-text">{error.paper_1_score}</div>}

							</div>
							<div className="form-fields w-1/2 pr-4 flex-1">
								<TextField
									label="KEAM Paper II Score with 4 decimal places(e.g 587.1230)"
									onChange={handleChange}
									value={values.paper_2_score}
									name="paper_2_score"
									id="paper_2_score"
									error={(appInfo.quota_id === 2 || keam_regex.test(values.paper_2_score)) ? false : true}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Icon className="icon-style">description</Icon>
											</InputAdornment>
										),
									}}
								/>
								{error && <div className="error-text">{error.paper_2_score}</div>}

							</div>
							</div>

							<div className="flex w-full flex-row flex-wrap">
							<div className="form-fields w-1/2 pr-4 flex-col">

								{appInfo.quota_id !== VjcetService.QUOTA_NRI && (<span className="text-gray-500 font-normal text-xs">Entrance Marklist</span>)}
								
								<div className="btn btn-file">
									<IconButton aria-label="delete" className="mt-2" >
										<Avatar alt="Remy Sharp" src={document} className="icon-style" />
									</IconButton>
									{documents.entrance_marklist_url.length > 0 && (<div className="btn-file-status">
										<span className="file-status-to-uploaded">File Selected</span>
										<label for="entrance_marklist" className="label-style">Choose file</label>
										<input
											type="file"
											id="entrance_marklist"
											name="entrance_marklist"
											value=""
											accept="image/png,image/jpeg,image/jpg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
											onChange={documentChangeHandler}
											style={{ display: "none" }}
										/>
									</div>
									)}
									{documents.entrance_marklist_url.length === 0 && (<div className="btn-file-status">
										<span className="file-status-to-upload">Please Upload</span>
										<label for="entrance_marklist" className="label-style">Choose file</label>
										<input
											type="file"
											id="entrance_marklist"
											name="entrance_marklist"
											value=""
											accept="image/png,image/jpeg,image/jpg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
											onChange={documentChangeHandler}
											style={{ display: "none" }}
										/>
									</div>
									)}
								</div>
                            	{error && <div className="error-text">{error.entrance_marklist}</div>}
                            	{values.entrance_marklist_error && (<div className="error-text">{values.entrance_marklist_error}</div>)}
                        	</div>
							<div className="form-fields w-1/2 pr-4 flex-1 mt-5 pl-8">
								<Btn title='Update KEAM Score' click={this.update_keam_score} />
							</div>

							

						</div>
						{this.state.showMessage ? (

								<div>
									< div class="p-4 text-lg text-white bg-green-400 m-3 w-full rounded-md">
										<span >Your KEAM 21 score has been updated. Download the latest copy of your application below.</span>
										
									</div >
								</div>

						) : (
								<p></p>
						)}
								{this.state.failure && 
									<Alert variant="filled" severity="error">
										{this.state.failure}
									</Alert>}
								</div>
							</div>
						)*/}
						
		{/*KEAM mark second time entry end...............*/}

						{/* <div id="documents-details" className="w-full mt-8">
						<div className="w-full">
							
							<h3 className="mt-5 font-extrabold">Re-upload Signature and Photo</h3>
							<div className="mt-4 mb-6 italic font-semibold text-base text-gray-400">
								** If the College management advices you to upload proper photos, please use the below form to upload them.	
							</div>

							<div className="flex w-full flex-row flex-wrap">
								<div className="form-fields w-1/2 pr-4 flex-col">
									{appInfo.quota_id !== VjcetService.QUOTA_NRI && (<span className="text-gray-500 font-normal text-xs">Applicant Signature(jpeg/png only)</span>)}
									<div className="btn btn-file">
										<IconButton aria-label="delete">
											<Avatar alt="Remy Sharp" src={document} className="icon-style" />
										</IconButton>
																	
										<div className="btn-file-status">
											{documents.application_signature_sel === null && (<span 		className="file-status-to-upload">Choose file to upload</span>)}
											
											{documents.application_signature_sel === true && (<span 		className="file-status-to-uploaded">File Selected</span>)}


											<label for="application_signature" className="label-style">Choose file</label>
											<input
												type="file"
												id="application_signature"
												name="application_signature"
												value=""
												accept="image/png,image/jpeg,image/jpg"
												onChange={documentChangeHandler}
												style={{ display: "none" }}
											/>
										</div>
										
									</div>
								</div>
								<div className="form-fields w-1/2 pr-4 flex-col">
									{appInfo.quota_id !== VjcetService.QUOTA_NRI && (<span className="text-gray-500 font-normal text-xs">Parent Signature(jpeg/png only)</span>)}
									
									<div className="btn btn-file">
									<IconButton aria-label="delete">
										<Avatar alt="Remy Sharp" src={document} className="icon-style" />
									</IconButton>
										<div className="btn-file-status">
										{documents.parent_signature_sel === true && 
										   (<span className="file-status-to-uploaded">File Selected</span> )}

										{documents.parent_signature_sel === null && 
										   (<span className="file-status-to-upload">Choose file to upload</span> )}	

											<label for="parent_signature" className="label-style">Choose file</label>
											<input
												type="file"
												id="parent_signature"
												name="parent_signature"
												value=""
												accept="image/png,image/jpeg,image/jpg"
												onChange={documentChangeHandler}
												style={{ display: "none" }}
											/>
										</div>
										
									
                           			</div>
								</div>

							</div>

							<div className="flex w-full flex-row flex-wrap">
								<div className="form-fields w-1/2 pr-4 flex-col">
								<span className="text-gray-500 font-normal text-xs">Applicant Photo(jpeg/png only)
								</span>
									
								<div className="btn btn-file">
									<IconButton aria-label="delete">
										<Avatar alt="Remy Sharp" src={document} className="icon-style" />
									</IconButton>
									<div className="btn-file-status">
										{appInfo.photo_sel === null && (<span className="file-status-to-upload">Please Upload</span>)}

										{appInfo.photo_sel === true && (<span className="file-status-to-uploaded">Photo Selected</span>)}

											<label for="photo" className="label-style">Choose file</label>
											<input
												type="file"
												id="photo"
												name="photo"
												value=""

												accept="image/png,image/jpeg,image/jpg"
												onChange={profileImageChanged}
												style={{ display: "none" }}
											/>
									</div>
									</div>

								</div>
								
								<div className="form-fields w-1/2 pr-4 flex-1 mt-5 pl-8">
									<Btn title='Update documents' click={this.update_documents} />
								</div>
							</div>

						</div>
						</div>
					
 */}

						<div className="btn-style">
							<Btn title='Download PDF' click={this.download_application} />
							<Btn title='Close' click={this.continue} />

						</div>
						

					</div>
                </Container>
			
                <Footer />

            </div>

        )
    }
}



export default withRouter(Submission);
