import React from 'react'
import { Button } from '@material-ui/core';
import './style.css'

const Btn = (props) => {
    const btn_type = props.type !== undefined ? props.type : "text";
    const shadow = props.shadow !== undefined ? props.shadow : "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)";
    const background = props.background !== undefined ? props.background : "#9d0055";
    const radius = props.radius !== undefined ? props.radius : "30px";
	const height = props.height !== undefined ? props.height : "50px";
	const fontSize = props.fontSize !== undefined ? props.fontSize : "14px";
	const marginLeft = props.marginLeft !== undefined ? props.marginLeft : "5px";

    const btnStyles = {
        color: '#fff',
        backgroundColor: background,
        minWidth: '150px',
        height: height,
        fontSize: fontSize,
        fontWeight: '700',
        borderRadius: radius,
        boxShadow: shadow,
		marginLeft: marginLeft,

    }
    return (
        <Button variant="contained" className="btn-hover-style" style={btnStyles} onClick={props.click} type={props.type} disabled={props.disabled}>
            {props.title}
        </Button>

    )
}



export default Btn
