import React from 'react'
import { Container } from '@material-ui/core';
import logo from '../../images/logos/logo.png'
import fb from '../../images/icons/fb.png'
import insta from '../../images/icons/insta.png'
import twitter from '../../images/icons/twitter.png'
import youtube from '../../images/icons/youtube.png'
import './style.css'
import { NavLink } from 'react-router-dom'

const Footer = () => {
	const date = new Date();
	const year = date.getFullYear();

    return (
        <div id="page-footer" style={footerStyle}>
            <Container >
                <div id="footer-content">
                    <div className="logo">
                        <img src={logo} alt="logo" />
                    </div>

                    <div>
                        <div className="social-media-icons">
                            <a href="https://www.facebook.com/vjcet.ac.in" target="_blank" rel="noopener noreferrer">
                                <img src={fb} alt="facebook" />
                            </a>
                            <a href="https://www.instagram.com/vjcet_vazhakulam" target="_blank" rel="noopener noreferrer">
                                <img src={insta} alt="instagram" />
                            </a>
                            <a href="https://twitter.com/ViswajyothiT" target="_blank" rel="noopener noreferrer">
                                <img src={twitter} alt="twitter" />
                            </a>
                            <a href="https://www.youtube.com/channel/UCYlW0xiYPFl3R_wiownjQRw" target="_blank" rel="noopener noreferrer">
                                <img src={youtube} alt="youtube" />
                            </a>


                        </div>
                    </div>
                    <div style={{ display: "grid" }} className="footer-page-link-style">
                        <NavLink to="/about">
                            About Us
                        </NavLink>
                        <NavLink to="/contact">
                            Contact Us
                        </NavLink>
                        <NavLink to="/privacy">
                            Privacy Statement
                        </NavLink>
                        <NavLink to="/refund">
                            Refund Policy
                        </NavLink>
                        <NavLink to="/service">
                            Terms of Service
                        </NavLink>
                    </div>
                </div>
                <div className="footer-text-style">
                    <p>&#169; { year } Viswajyothi College of Engineering and Technology. All rights reserved.</p>
                </div>



            </Container>
        </div>
    )
}

const footerStyle = {
    background: 'linear-gradient(#9D0055,#270015)',

}

export default Footer