import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, ButtonGroup, Container } from '@material-ui/core';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import PlacementPDF from '../pdf/placementPdf';
import RecruitmentTable from './RecruitmentTable';
import './style.css';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'inline-block',

		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

const columns = [
	{ id: 'number', label: 'Number', minWidth: 170 },
	{ id: 'company', label: 'Company', minWidth: 100 },
	{
		id: 'year',
		label: 'Year',
		minWidth: 170,
		align: 'right',
	},
	{
		id: 'branch',
		label: 'Branch',
		minWidth: 170,
		align: 'right',
		format: (value) => value.toLocaleString('en-US'),
	},
	{
		id: 'students',
		label: 'No of Students',
		minWidth: 170,
		align: 'right',
	},
];
const columns_companywise = [
	{ id: 'number', label: 'Number', minWidth: 170 },
	{ id: 'company', label: 'Company', minWidth: 100 },	
	{ id: 'year', label: 'Year', minWidth: 170,	align: 'right',	},
	{ id: 'students', label: 'No of Students', minWidth: 170,align: 'right',},
];

const rows1 = [
	{ number: 1, company: 'Infosys', year: 2020, branch: 'IT', students: 5 },
	{ number: 2, company: 'Infosys', year: 2020, branch: 'EEE', students: 3 },
	{ number: 3, company: 'Infosys', year: 2020, branch: 'CE', students: 3 },
	{ number: 4, company: 'Infosys', year: 2020, branch: 'ME', students: 4 },
	{ number: 5, company: 'Infosys', year: 2020, branch: 'EC', students: 4 },
	{ number: 6, company: 'Infosys', year: 2020, branch: 'CS', students: 31 },
	{ number: 7, company: 'TCS', year: 2020, branch: 'IT', students: 2 },
	{ number: 8, company: 'TCS', year: 2020, branch: 'EEE', students: 2 },
	{ number: 9, company: 'TCS', year: 2020, branch: 'CE', students: 6 },
	{ number: 10, company: 'TCS', year: 2020, branch: 'ME', students: 4 },
	{ number: 11, company: 'TCS', year: 2020, branch: 'EC', students: 6 },
	{ number: 12, company: 'TCS', year: 2020, branch: 'CS', students: 28 },
	{ number: 13, company: 'IBS', year: 2020, branch: 'IT', students: 1 },
	{ number: 14, company: 'IBS', year: 2020, branch: 'ME', students: 1 },
	{ number: 15, company: 'IBS', year: 2020, branch: 'CS', students: 11 },
	{ number: 16, company: 'Amazon', year: 2020, branch: 'CE', students: 1 },
	{ number: 17, company: 'Amazon', year: 2020, branch: 'EC', students: 2 },
	{ number: 18, company: 'Amazon', year: 2020, branch: 'CS', students: 1 },
	{ number: 19, company: 'UST', year: 2020, branch: 'IT', students: 2 },
	{ number: 20, company: 'UST', year: 2020, branch: 'EC', students: 5 },
	{ number: 21, company: 'UST', year: 2020, branch: 'CS', students: 2 },
	{ number: 22, company: 'Cognizat', year: 2020, branch: 'ME', students: 5 },
	{ number: 23, company: 'Cognizat', year: 2020, branch: 'CS', students: 1 },
	{ number: 24, company: 'Wipro', year: 2020, branch: 'EC', students: 3 },
	{ number: 25, company: 'Wipro', year: 2020, branch: 'CS', students: 5 },
	{ number: 26, company: 'IBM', year: 2020, branch: 'CS', students: 1 },
	{ number: 27, company: 'E & Y', year: 2020, branch: 'CS', students: 2 },
	{ number: 28, company: 'Hexaware', year: 2020, branch: 'EEE', students: 2 },
	{ number: 29, company: 'Hexaware', year: 2020, branch: 'EC', students: 11 },
	{ number: 30, company: 'Hexaware', year: 2020, branch: 'CS', students: 6 },
	{ number: 31, company: 'Experion', year: 2020, branch: 'EC', students: 2 },
	{ number: 32, company: 'Experion', year: 2020, branch: 'CS', students: 1 },
	{ number: 33, company: 'Sutherland Global', year: 2020, branch: 'EEE', students: 7 },
	{ number: 34, company: 'Sutherland Global', year: 2020, branch: 'CE', students: 4 },
	{ number: 35, company: 'Sutherland Global', year: 2020, branch: 'ME', students: 9 },
	{ number: 36, company: 'Sutherland Global', year: 2020, branch: 'EC', students: 22 },
	{ number: 37, company: 'Sutherland Global', year: 2020, branch: 'CS', students: 9 },
	{ number: 38, company: 'Mindcurv Technology Solutions', year: 2020, branch: 'IT', students: 1 },
	{ number: 39, company: 'Verse Innovations', year: 2020, branch: 'CS', students: 1 },
	{ number: 40, company: 'Pratian Technologies', year: 2020, branch: 'IT', students: 10 },
	{ number: 41, company: 'Pratian Technologies', year: 2020, branch: 'EEE', students: 1 },
	{ number: 42, company: 'Pratian Technologies', year: 2020, branch: 'EC', students: 10 },
	{ number: 43, company: 'Pratian Technologies', year: 2020, branch: 'CS', students: 21 },
	{ number: 44, company: 'Indoshell Mould Limited', year: 2020, branch: 'EEE', students: 5 },
	{ number: 45, company: 'Indoshell Mould Limited', year: 2020, branch: 'ME', students: 12 },
	{ number: 46, company: 'Silver Peak Global', year: 2020, branch: 'IT', students: 1 },
	{ number: 47, company: 'Silver Peak Global', year: 2020, branch: 'ME', students: 8 },
	{ number: 48, company: 'Silver Peak Global', year: 2020, branch: 'EC', students: 4 },
	{ number: 49, company: '6d Technologies', year: 2020, branch: 'IT', students: 1 },
	{ number: 50, company: '6d Technologies', year: 2020, branch: 'EC', students: 2 },
	{ number: 51, company: '6d Technologies', year: 2020, branch: 'CS', students: 3 },
	{ number: 52, company: 'NMS Works', year: 2020, branch: 'CS', students: 9 },
	{ number: 53, company: 'Quest', year: 2020, branch: 'IT', students: 1 },
	{ number: 54, company: 'Quest', year: 2020, branch: 'EC', students: 3 },
	{ number: 55, company: 'Quest', year: 2020, branch: 'CS', students: 2 },
	{ number: 56, company: 'G10X', year: 2020, branch: 'EEE', students: 1 },
	{ number: 57, company: 'G10X', year: 2020, branch: 'EC', students: 1 },
	{ number: 58, company: 'G10X', year: 2020, branch: 'CS', students: 1 },
	{ number: 59, company: 'Innovature Labs', year: 2020, branch: 'CS', students: 1 },
	{ number: 60, company: 'Mistral Solutions', year: 2020, branch: 'CS', students: 1 },
	{ number: 61, company: 'Image Infosystems', year: 2020, branch: 'IT', students: 1 },
	{ number: 62, company: 'Image Infosystems', year: 2020, branch: 'CS', students: 1 },
	{ number: 63, company: 'Zerone Consulting Pvt. Ltd.', year: 2020, branch: 'IT', students: 4 },
	{ number: 64, company: 'Daivik Technologies', year: 2020, branch: 'IT', students: 2 },
	{ number: 65, company: 'Daivik Technologies', year: 2020, branch: 'CS', students: 2 },
];
const rows2 = [
	{ number: 1, company: 'Infosys', year: 2019, branch: 'IT', students: 5 },
	{ number: 2, company: 'Infosys', year: 2019, branch: 'EEE', students: 3 },
	{ number: 3, company: 'Infosys', year: 2019, branch: 'CE', students: 3 },
	{ number: 4, company: 'Infosys', year: 2019, branch: 'ME', students: 4 },
	{ number: 5, company: 'Infosys', year: 2019, branch: 'EC', students: 4 },
	{ number: 6, company: 'Infosys', year: 2019, branch: 'CS', students: 31 },
	{ number: 7, company: 'TCS', year: 2019, branch: 'IT', students: 2 },
	{ number: 8, company: 'TCS', year: 2019, branch: 'EEE', students: 2 },
	{ number: 9, company: 'TCS', year: 2019, branch: 'CE', students: 6 },
	{ number: 10, company: 'TCS', year: 2019, branch: 'ME', students: 4 },
	{ number: 11, company: 'TCS', year: 2019, branch: 'EC', students: 6 },
	{ number: 12, company: 'TCS', year: 2019, branch: 'CS', students: 28 },
	{ number: 13, company: 'IBS', year: 2019, branch: 'IT', students: 1 },
	{ number: 14, company: 'IBS', year: 2019, branch: 'ME', students: 1 },
	{ number: 15, company: 'IBS', year: 2019, branch: 'CS', students: 11 },
	{ number: 16, company: 'Amazon', year: 2019, branch: 'CE', students: 1 },
	{ number: 17, company: 'Amazon', year: 2019, branch: 'EC', students: 2 },
	{ number: 18, company: 'Amazon', year: 2019, branch: 'CS', students: 1 },
	{ number: 19, company: 'UST', year: 2019, branch: 'IT', students: 2 },
	{ number: 20, company: 'UST', year: 2019, branch: 'EC', students: 5 },
	{ number: 21, company: 'UST', year: 2019, branch: 'CS', students: 2 },
];

const rows2021 = [
	{ number: 1, company: 'ZERONE', year: 2021, branch: 'CSE', students: 3 },
	{ number: 2, company: 'VVDN', year: 2021, branch: 'EEE', students: 1 },
	{ number: 3, company: 'VIT', year: 2021, branch: 'IT', students: 3 },
	{ number: 4, company: 'TCS', year: 2021, branch: 'ME', students: 2 },
	{ number: 5, company: 'TCS', year: 2021, branch: 'IT', students: 2 },
	{ number: 6, company: 'TCS', year: 2021, branch: 'CSE', students: 34 },
	{ number: 7, company: 'TCS', year: 2021, branch: 'EEE', students: 4 },
	{ number: 8, company: 'TCS', year: 2021, branch: 'ECE', students: 11 },
	{ number: 9, company: 'TCS', year: 2021, branch: 'CIVIL', students: 4 },
	{ number: 10, company: 'SUTHERLAND GLOBAL - VOICE', year: 2021, branch: 'IT', students: 4 },
	{ number: 11, company: 'SUTHERLAND GLOBAL - VOICE', year: 2021, branch: 'ECE', students: 4 },
	{ number: 12, company: 'SUTHERLAND GLOBAL - VOICE', year: 2021, branch: 'CIVIL', students: 4 },
	{ number: 13, company: 'NMS WORKS', year: 2021, branch: 'CSE', students: 5 },
	{ number: 14, company: 'MITSOGO', year: 2021, branch: 'CSE', students: 1 },
	{ number: 15, company: 'INFOSYS', year: 2021, branch: 'IT', students: 1 },
	{ number: 16, company: 'INFOSYS', year: 2021, branch: 'ME', students: 2 },
	{ number: 17, company: 'INFOSYS', year: 2021, branch: 'CSE', students: 12 },
	{ number: 18, company: 'INFOSYS', year: 2021, branch: 'EEE', students: 2 },
	{ number: 19, company: 'INFOSYS', year: 2021, branch: 'ECE', students: 6 },
	{ number: 20, company: 'INFOSYS', year: 2021, branch: 'CIVIL', students: 6 },
	{ number: 21, company: 'IMAGE INFO SYSTEM', year: 2021, branch: 'CSE', students: 3 },
	{ number: 22, company: 'GUIDEHOUSE', year: 2021, branch: 'CSE', students: 4 },
	{ number: 23, company: 'GADGEON', year: 2021, branch: 'CSE', students: 2 },
	{ number: 24, company: 'FACTWEAVERS', year: 2021, branch: 'CSE', students: 2 },
	{ number: 25, company: 'EXPERION', year: 2021, branch: 'CSE', students: 11 },
	{ number: 26, company: 'ELEATION', year: 2021, branch: 'ME', students: 3 },
	{ number: 27, company: 'ELEATION', year: 2021, branch: 'CIVIL', students: 2 },
	{ number: 28, company: 'SUTHERLAND(NON-VOICE)', year: 2021, branch: 'CIVIL', students: 12 },
	{ number: 29, company: 'SUTHERLAND(NON-VOICE)', year: 2021, branch: 'CSE', students: 12 },
	{ number: 30, company: 'SUTHERLAND(NON-VOICE)', year: 2021, branch: 'ECE', students: 17 },
	{ number: 31, company: 'SUTHERLAND(NON-VOICE)', year: 2021, branch: 'EEE', students: 1 },
	{ number: 32, company: 'FACE', year: 2021, branch: 'CIVIL', students: 1 },
	{ number: 33, company: 'FACE', year: 2021, branch: 'EEE', students: 2 },
	{ number: 34, company: 'FACE', year: 2021, branch: 'ECE', students: 3 },
	{ number: 35, company: 'FACE', year: 2021, branch: 'ME', students: 1 },
	{ number: 36, company: '6D Technologies', year: 2021, branch: 'CSE', students: 1 },
	{ number: 37, company: '6D Technologies', year: 2021, branch: 'IT', students: 1 },
	{ number: 38, company: 'Quest Global', year: 2021, branch: 'CSE', students: 4 },
	{ number: 39, company: 'Quest Global', year: 2021, branch: 'ECE', students: 4 },
	{ number: 40, company: 'Quest Global', year: 2021, branch: 'EEE', students: 4 },
	{ number: 41, company: 'Quest Global', year: 2021, branch: 'IT', students: 1 },
	{ number: 42, company: 'Envestnet', year: 2021, branch: 'CSE', students: 2 },
	{ number: 43, company: 'Envestnet', year: 2021, branch: 'IT', students: 1 },
	{ number: 44, company: 'SUTHERLAND(NON-VOICE)', year: 2021, branch: 'IT', students: 3 },
	{ number: 45, company: 'SUTHERLAND(NON-VOICE)', year: 2021, branch: 'ME', students: 9 },
	{ number: 46, company: 'WILEYS', year: 2021, branch: 'CSE', students: 1 },
];
const rows2022 = [
	{number: '01',company: 'Tata Elxsi',year: '2022',branch: 'CSE',students: '03'},
	{number: '02',company: 'Face Prep',year: '2022',branch: 'CIVIL',students: '01'},
	{number: '03',company: 'Face Prep',year: '2022',branch: 'ME',students: '06'},
	{number: '04',company: 'Face Prep',year: '2022',branch: 'ECE',students: '01'},
	{number: '05',company: 'Capgemini',year: '2022',branch: 'ECE',students: '02'},
	{number: '06',company: 'Capgemini',year: '2022',branch: 'ME',students: '01'},
	{number: '07',company: 'Capgemini',year: '2022',branch: 'IT',students: '03'},
	{number: '08',company: 'Capgemini',year: '2022',branch: 'EEE',students: '01'},
	{number: '09',company: 'Capgemini',year: '2022',branch: 'ECE',students: '01'},
	{number: '10',company: 'Capgemini',year: '2022',branch: 'CSE',students: '06'},
	{number: '11',company: 'Mind Tree',year: '2022',branch: 'CSE',students: '01'},
	{number: '12',company: 'VVDN',year: '2022',branch: 'ECE',students: '01'},
	{number: '13',company: '6 D Technologies',year: '2022',branch: 'IT',students: '01'},
	{number: '14',company: '6 D Technologies',year: '2022',branch: 'EEE',students: '01'},
	{number: '15',company: 'Midtree',year: '2022',branch: 'CSE',students: '01'},
	{number: '16',company: 'Elitmus',year: '2022',branch: 'CSE',students: '02'},
	{number: '17',company: 'TCS',year: '2022',branch: 'IT',students: '06'},
	{number: '18',company: 'TCS',year: '2022',branch: 'EEE',students: '03'},
	{number: '19',company: 'TCS',year: '2022',branch: 'CIVIL',students: '12'},
	{number: '20',company: 'TCS',year: '2022',branch: 'ME',students: '11'},
	{number: '21',company: 'TCS',year: '2022',branch: 'ECE',students: '13'},
	{number: '22',company: 'TCS',year: '2022',branch: 'CSE',students: '35'},    
	{number: '23',company: 'Guidehouse',year: '2022',branch: 'IT',students: '02'},
	{number: '24',company: 'Guidehouse',year: '2022',branch: 'ME',students: '02'},
	{number: '25',company: 'Guidehouse',year: '2022',branch: 'ECE',students: '03'}, 
	{number: '26',company: 'Guidehouse',year: '2022',branch: 'CSE',students: '03'},
	{number: '27',company: 'Zoho Corporation',year: '2022',branch: 'CSE',students: '01'},
	{number: '28',company: 'Indoshell',year: '2022',branch: 'ME',students: '06'},
	{number: '29',company: 'Sutherland Global',year: '2022',branch: 'EEE',students: '07'},
	{number: '30',company: 'Sutherland Global',year: '2022',branch: 'IT',students: '04'},
	{number: '31',company: 'Sutherland Global',year: '2022',branch: 'CIVIL',students: '19'},
	{number: '32',company: 'Sutherland Global',year: '2022',branch: 'ME',students: '19'},
	{number: '33',company: 'Sutherland Global',year: '2022',branch: 'ECE',students: '20'},
	{number: '34',company: 'Sutherland Global',year: '2022',branch: 'CSE',students: '18'}, 
	{number: '35',company: 'IBS',year: '2022',branch: 'IT',students: '02'},
	{number: '36',company: 'IBS',year: '2022',branch: 'ECE',students: '03'},
	{number: '37',company: 'IBS',year: '2022',branch: 'CSE',students: '25'},
	{number: '38',company: 'UST Global',year: '2022',branch: 'IT',students: '03'}, 
	{number: '39',company: 'UST Global',year: '2022',branch: 'EEE',students: '03'},
	{number: '40',company: 'UST Global',year: '2022',branch: 'ECE',students: '06'},
	{number: '41',company: 'UST Global',year: '2022',branch: 'CSE',students: '16'},
	{number: '42',company: 'WIPRO',year: '2022',branch: 'IT',students: '02'},
	{number: '43',company: 'WIPRO',year: '2022',branch: 'ECE',students: '01'},
	{number: '44',company: 'WIPRO',year: '2022',branch: 'CSE',students: '21'},
	{number: '45',company: 'Quest Global',year: '2022',branch: 'IT',students: '03'},
	{number: '46',company: 'Quest Global',year: '2022',branch: 'EEE',students: '01'},    
	{number: '47',company: 'Quest Global',year: '2022',branch: 'ECE',students: '05'},    
	{number: '48',company: 'Quest Global',year: '2022',branch: 'CSE',students: '11'},    
	{number: '49',company: 'Aspire',year: '2022',branch: 'EEE',students: '01'},    
	{number: '50',company: 'Aspire',year: '2022',branch: 'ECE',students: '03'},    
	{number: '51',company: 'Aspire',year: '2022',branch: 'CSE',students: '07'},    
	{number: '52',company: 'INFOSYS',year: '2022',branch: 'CIVIL',students: '06'},    
	{number: '53',company: 'INFOSYS',year: '2022',branch: 'ECE',students: '05'},    
	{number: '54',company: 'INFOSYS',year: '2022',branch: 'IT',students: '03'},    
	{number: '55',company: 'INFOSYS',year: '2022',branch: 'CSE',students: '32'},     
	{number: '56',company: 'INFOSYS',year: '2022',branch: 'ME',students: '05'},    
	{number: '57',company: 'Virtusa',year: '2022',branch: 'CSE',students: '10'},    
	{number: '58',company: 'Experion',year: '2022',branch: 'EEE',students: '01'},    
	{number: '59',company: 'Experion',year: '2022',branch: 'CSE',students: '09'},    
	{number: '60',company: 'Qburst',year: '2022',branch: 'IT',students: '02'},    
	{number: '61',company: 'Qburst',year: '2022',branch: 'CSE',students: '06'},   
	{number: '62',company: 'IBM',year: '2022',branch: 'IT',students: '4'},    
	{number: '63',company: 'IBM',year: '2022',branch: 'ECE',students: '02'},    
	{number: '64',company: 'IBM',year: '2022',branch: 'CSE',students: '03'},    
	{number: '65',company: 'Gadgeon Smart Systems',year: '2022',branch: 'CSE',students: '03'},    
	{number: '66',company: 'TECH MAHINDRA Cerium',year: '2022',branch: 'ECE',students: '02'},    
	{number: '67',company: 'Marlabs',year: '2022',branch: 'CSE',students: '01'},    
	{number: '68',company: 'Envestnet',year: '2022',branch: 'CSE',students: '01'},

];
const rows2023 = [
	{ number: 1, company: 'TCS', year: 2023, branch: 'CSE', students: 14 },
	{ number: 2, company: 'TCS', year: 2023, branch: 'EEE', students: 4 },
	{ number: 3, company: 'TCS', year: 2023, branch: 'IT', students: 1 },
	{ number: 4, company: 'TCS', year: 2023, branch: 'ME', students: 5 },
	{ number: 5, company: 'TCS', year: 2023, branch: 'ECE', students: 3 },
	{ number: 6, company: 'TCS', year: 2023, branch: 'CIVIL', students: 2 },
	{ number: 7, company: 'Infosys', year: 2023, branch: 'CSE', students: 12 },
	{ number: 8, company: 'Infosys', year: 2023, branch: 'ECE', students: 6 },
	{ number: 9, company: 'Infosys', year: 2023, branch: 'EEE', students: 2 },
	{ number: 10, company: 'Infosys', year: 2023, branch: 'CIVIL', students: 6 },
	{ number: 11, company: 'Infosys', year: 2023, branch: 'ME', students: 2 },
	{ number: 12, company: 'Infosys', year: 2023, branch: 'IT', students: 1 },
	{ number: 13, company: 'Experion', year: 2023, branch: 'CSE', students: 13 },
	{ number: 14, company: 'Experion', year: 2023, branch: 'ECE', students: 7 },
	{ number: 15, company: 'Experion', year: 2023, branch: 'IT', students: 4 },
	{ number: 16, company: 'GADGEON', year: 2023, branch: 'CSE', students: 2 },
	{ number: 17, company: 'SUTHERLAND GLOBAL', year: 2023, branch: 'CSE', students: 8 },
	{ number: 18, company: 'SUTHERLAND GLOBAL', year: 2023, branch: 'ECE', students: 18 },
	{ number: 19, company: 'SUTHERLAND GLOBAL', year: 2023, branch: 'ME', students: 15 },
	{ number: 20, company: 'SUTHERLAND GLOBAL', year: 2023, branch: 'CIVIL', students: 13 },
	{ number: 21, company: 'SUTHERLAND GLOBAL', year: 2023, branch: 'IT', students: 8 },
	{ number: 22, company: 'SUTHERLAND GLOBAL', year: 2023, branch: 'EEE', students: 8 },
	{ number: 23, company: '6d Technologies', year: 2023, branch: 'CSE', students: 13 },
	{ number: 24, company: '6d Technologies', year: 2023, branch: 'IT', students: 4 },
	{ number: 25, company: '6d Technologies', year: 2023, branch: 'ECE', students: 2 },
	{ number: 26, company: 'IBS', year: 2023, branch: 'CSE', students: 55 },
	{ number: 27, company: 'IBS', year: 2023, branch: 'ECE', students: 8 },
	{ number: 28, company: 'IBS', year: 2023, branch: 'IT', students: 2 },
	{ number: 29, company: 'EY', year: 2023, branch: 'CSE', students: 12 },
	{ number: 30, company: 'EY', year: 2023, branch: 'IT', students: 3 },
	{ number: 31, company: 'Tech Mahindra', year: 2023, branch: 'CSE', students: 10 },
	{ number: 32, company: 'Tech Mahindra', year: 2023, branch: 'IT', students: 1 },
	{ number: 33, company: 'Acabes', year: 2023, branch: 'CSE', students: 10 },
	{ number: 34, company: 'South Indian Bank', year: 2023, branch: 'CSE', students: 19 },
	{ number: 35, company: 'South Indian Bank)', year: 2023, branch: 'ECE', students: 6 },
	{ number: 36, company: 'South Indian Bank)', year: 2023, branch: 'ME', students: 1 },
	{ number: 37, company: 'South Indian Bank)', year: 2023, branch: 'CIVIL', students: 1 },
	{ number: 38, company: 'South Indian Bank', year: 2023, branch: 'IT', students: 2 },
	{ number: 39, company: 'Qburst', year: 2023, branch: 'CSE', students: 8 },
	{ number: 40, company: 'Envestnet', year: 2023, branch: 'CSE', students: 3 },
	{ number: 41, company: 'Envestnet', year: 2023, branch: 'IT', students: 1 },
	{ number: 42, company: 'INCTURE', year: 2023, branch: 'CSE', students: 4 },
	{ number: 43, company: 'INCTURE', year: 2023, branch: 'IT', students: 6 },
	{ number: 44, company: 'Quest Global', year: 2023, branch: 'CSE', students: 9 },
	{ number: 45, company: 'Quest Global', year: 2023, branch: 'ECE', students: 5 },
	{ number: 46, company: 'Quest Global', year: 2023, branch: 'IT', students: 4 },
	{ number: 47, company: 'ASCIA Technologies', year: 2023, branch: 'CSE', students: 2 },
	{ number: 48, company: 'ASCIA Technologies', year: 2023, branch: 'ECE', students: 1 },
	{ number: 49, company: 'Mindcurve', year: 2023, branch: 'CSE', students: 1 },
	{ number: 50, company: 'SKALERYA', year: 2023, branch: 'CSE', students: 1 },
	{ number: 51, company: 'IBM', year: 2023, branch: 'CSE', students: 1 },
	{ number: 52, company: 'RDC', year: 2023, branch: 'CIVIL', students: 3 },
	{ number: 53, company: 'Jobin & Jismi', year: 2023, branch: 'ECE', students: 3 },
	{ number: 54, company: 'Jobin & Jismi', year: 2023, branch: 'CIVIL', students: 3 },
	{ number: 55, company: 'Federal Bank', year: 2023, branch: 'CSE', students: 1 },
	{ number: 56, company: 'Federal Bank', year: 2023, branch: 'ECE', students: 2 },
	{ number: 57, company: 'Federal Bank', year: 2023, branch: 'CIVIL', students: 1 },
	{ number: 58, company: 'Federal Bank', year: 2023, branch: 'EEE', students: 1 },
	{ number: 59, company: 'Federal Bank', year: 2023, branch: 'ME', students: 1 },
	{ number: 60, company: 'Turbolab', year: 2023, branch: 'CSE', students: 2 },
	{ number: 61, company: 'Turbolab', year: 2023, branch: 'ECE', students: 1 },
	{ number: 62, company: 'Turbolab', year: 2023, branch: 'IT', students: 1 },	
	{ number: 63, company: 'Guidehouse', year: 2023, branch: 'CSE', students: 4 },	
	{ number: 64, company: 'Zerone Consulting Pvt. Ltd.', year: 2023, branch: 'CSE', students: 3 },	
	{ number: 65, company: 'VIT', year: 2023, branch: 'CSE', students: 3 },	
	{ number: 66, company: 'Factweavers', year: 2023, branch: 'CSE', students: 2 },	
	{ number: 67, company: 'Mitsogo', year: 2023, branch: 'CSE', students: 1 },	
	{ number: 68, company: 'VVDN', year: 2023, branch: 'EEE', students: 1 },	
	{ number: 69, company: 'ELEATION', year: 2023, branch: 'ME', students: 3 },
	{ number: 70, company: 'ELEATION', year: 2023, branch: 'CIVIL', students: 2 },
	{ number: 71, company: 'NMS Works', year: 2023, branch: 'CSE', students: 5 },
	{ number: 72, company: 'Image Infosystems', year: 2023, branch: 'CSE', students: 3 },
];

const rows2024 = [
	{ number: 1, company: 'Sutherland Global ', year: 2024, branch: '-', students: 137 },
	{ number: 2, company: 'Digital Core Technologies', year: 2024, branch: '-', students: 1 },
	{ number: 3, company: 'ESAF Small Finance Bank', year: 2024, branch: '-', students: 9 },
	{ number: 4, company: 'Experion Technologies', year: 2024, branch: '-', students: 1 },
	{ number: 5, company: 'Incture Technologies', year: 2024, branch: '-', students: 5 },
	{ number: 6, company: 'Innovature', year: 2024, branch: '-', students: 1 },
	{ number: 7, company: 'J.B.D.S. Power', year: 2024, branch: '-', students: 7 },
	{ number: 8, company: 'Litmus7 ', year: 2024, branch: '-', students: 2 },
	{ number: 9, company: 'Qburst', year: 2024, branch: '-', students: 1 },
	{ number: 10, company: 'Qspiders', year: 2024, branch: '-', students: 54 },
	{ number: 11, company: 'Qvantel Software Solutions Ltd', year: 2024, branch: '-', students: 3 },
	{ number: 12, company: 'Turbolab Technologies', year: 2024, branch: '-', students: 4 },
	{ number: 13, company: 'UST', year: 2024, branch: '-', students: 3 },
	{ number: 14, company: 'Prudent Technologies', year: 2024, branch: '-', students: 4 },
	{ number: 15, company: 'Pix Dynamics', year: 2024, branch: '-', students: 1 },
	{ number: 16, company: 'Mitsogo', year: 2024, branch: '-', students: 1 },
	{ number: 17, company: '6D Technologies', year: 2024, branch: '-', students: 1 },	

];

export default function StickyHeadTable() {
	const classes = useStyles();
	const [year, setYear] = React.useState('2024');
	const handleClick = (e) => {
		e.preventDefault();
		console.log(e.currentTarget.value);
		setYear(e.currentTarget.value);
	};

	return (
		<div id="placement-page">
			<Header />
			<Container>
				<div style={{ textAlign: 'center', marginBottom: '3vh', marginTop: '5vh' }}>
					<h1 className="sm:text-3xl text-2xl font-medium title-font text-center text-gray-900 mt-10">
						Placement Cell
					</h1>
				</div>
				<div>
					<p className="text-style">
						Placement Department is highly dynamic and has been efficient in placing students into profiles
						of their choice. The Placement Department is headed by Dean-Placement and ably supported by
						Assistant Placement Officers, Staff and Student Coordinators.
					</p>

					<p className="text-style">
						Over 170 companies visit the campus every year for recruitment. Some of the highest packages are
						offered by - SPG - 21 LPA, Adobe Systems - 20.35 LPA, Amazon 16 LPA, Toppr - 15.5 LPA, Anglo
						Eastern -15 LPA, Money View - 12 LPA, Juspay technologies- 12 LPA, HSBC -12 LPA, SAP - 10 LPA,
						Accolite Software - 10 LPA. The average package is 4 LPA.
					</p>

					<p className="text-style">
						The Placement Department also actively takes part in providing internship opportunities to the
						students. The placement Department has also set up Aptitude Lab for continual assessment of
						students.
					</p>
				</div>
				<div style={marginStyle}>
					<div>
						<h4 className="text-2xl font-medium title-font text-gray-900 mt-10">Recruitment Details</h4>
						<div className={classes.root}>
							<ButtonGroup
								variant="contained"
								color="primary"
								aria-label="contained primary button group"
								className="placement-btn-style"
							>
								<Button value="2024" onClick={handleClick}>
									2024
								</Button>
								<Button value="2023" onClick={handleClick}>
									2023
								</Button>
								<Button value="2022" onClick={handleClick}>
									2022
								</Button>
								<Button value="2021" onClick={handleClick}>
									2021
								</Button>
								<Button value="2020" onClick={handleClick}>
									2020
								</Button>
								
							</ButtonGroup>
						</div>
					</div>
					{year === '2024' ? <RecruitmentTable columns={columns_companywise} rows={rows2024} /> : ''}
					{year === '2023' ? <RecruitmentTable columns={columns} rows={rows2023} /> : ''}
					{year === '2022' ? <RecruitmentTable columns={columns} rows={rows2022} /> : ''}
					{year === '2021' ? <RecruitmentTable columns={columns} rows={rows2021} /> : ''}
					{year === '2020' ? <RecruitmentTable columns={columns} rows={rows1} /> : ''}
					
				</div>
				{/*<div style={{ height: '50vh', marginTop: '5vh', marginBottom: '5vh' }}>
					<PlacementPDF />
				</div>*/}
			</Container>
			<Footer />
		</div>
	);
}

const marginStyle = {
	marginTop: '3vh',
	marginBottom: '3vh',
};
