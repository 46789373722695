import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@material-ui/core';
import VjcetService from '../../vjcet-service';
import { FormatAlignCenter } from '@material-ui/icons';

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	th: {
		fontWeight: 700,
	},
});

function createData(
	branch,
	deposit,
	tution_fee,
	admis_fee,
	training_fee,
	student_insurance,
	university_fee,
	pta,
	value_added,
	total,
) {
	return { branch, deposit, tution_fee, admis_fee, training_fee, student_insurance, university_fee, pta, value_added, total };
}

const rows = [
	createData(
		'Artificial Intelligence and Data Science',
		'10,000',
		'50,250',
		500,
		'2,500',
		200,
		'4,365',
		'1,000',
		'7,500',
		'76,315/-**',
	),
	createData('Civil Engineering', '10,000', '30,000', 500, '2,500', 200, '4,365', '1,000', '7,500', '56,065/-'),
	createData('Computer Science and Design', '10,000', '45,000', 500, '2,500', 200, '4,365', '1,000', '7,500', '71065/-'),
	createData(
		'Computer Science and Engineering',
		'10,000',
		'50,250',
		500,
		'2,500',
		200,
		'4,365',
		'1,000',
		'7,500',
		'76,315/-**',
	),
	
	createData(
		'Electrical and Electronics Engineering',
		'10,000',
		'30,000',
		500,
		'2,500',
		200,
		'4,365',
		'1,000',
		'7,500',
		'56,065/-',
	),
	createData(
		'Electronics and Communication Engineering',
		'10,000',
		'45,000',
		500,
		'2,500',
		200,
		'4,365',
		'1,000',
		'7,500',
		'71,065/-',
	),
	createData('Information Technology', '10,000', '45,000', 500, '2,500', 200, '4,365', '1,000', '7,500', '71,065/-'),
	createData('Mechanical Engineering', '10,000', '30,000', 500, '2,500', 200, '4,365', '1,000', '7,500', '56,065/-'),
	// createData('MBA', '5,000', '75,000', 500, '-', '-', '500', '-', '81,000/-'),
	
];

export default function Fee() {
	const classes = useStyles();

	return (
		<div>
			<div>				
				<h2 style={{ marginTop: '30px', marginBottom: '30px',fontWeight:'bold'  }}>
					First Semester Fee Structure for Management and Government Quota Admissions 
					{/*VjcetService.ACADEMIC_YEAR_TITLE*/}
				</h2>				
			</div>
			<Grid item xs={12}>
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="simple table">
						<TableHead>
							<StyledTableRow>
								<StyledTableCell>Branch</StyledTableCell>
								<StyledTableCell>
									Caution Deposit <br />
									<span className="sub-info">(one time)</span>
								</StyledTableCell>
								<StyledTableCell>
									Tuition Fee*
									<br />
									<span className="sub-info">(semester wise)</span>
								</StyledTableCell>							
								<StyledTableCell>
									Admission Fee <br />
									<span className="sub-info">(one time)</span>
								</StyledTableCell>
								<StyledTableCell>
									Fee for Series Test, Internet and Union & Association <br />
									<span className="sub-info">(anually) </span>
								</StyledTableCell>
								<StyledTableCell>Students Insurance <br />
									<span className="sub-info">(anually) </span>
								</StyledTableCell>
								<StyledTableCell>
									Proportionate fee for university / statutory bodies <br />
									<span className="sub-info">(one time)</span>
								</StyledTableCell>
								<StyledTableCell>PTA <br />
								<span className="sub-info">(anually)</span>
								</StyledTableCell>
								<StyledTableCell>
									Fee for Value-Added Courses<br />
									<span className="sub-info">(one time)</span>
								</StyledTableCell>
								<StyledTableCell>TOTAL</StyledTableCell>
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{rows.map((row) => (
								<StyledTableRow key={row.branch}>
									<TableCell component="th" scope="row">
										{row.branch}
									</TableCell>
									<TableCell>{row.deposit}</TableCell>
									<TableCell>{row.tution_fee}</TableCell>
									<TableCell>{row.admis_fee}</TableCell>
									<TableCell>{row.training_fee}</TableCell>
									<TableCell>{row.student_insurance}</TableCell>
									<TableCell>{row.university_fee}</TableCell>
									<TableCell>{row.pta}</TableCell>
									<TableCell>{row.value_added}</TableCell>
									<TableCell style={{ whiteSpace: 'nowrap' }}>Rs.{row.total}</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>			
			<div style={{ marginTop: '30px', color: '#3f3d56' }}>
				<p>* Tuition fee will vary as per the scholarship scheme.</p>
				<p>
					** Additional interest free Refundable Deposit of Rs.1,00,000/- (Rupees One Lakh Only) is payable
					for{' '}
					<span style={{ color: '#9d0055' }}>
						admissions under Management Quota in Artificial Intelligence &amp; Data Science and Computer
						Science &amp; Engineering{' '}
					</span>{' '}
					courses
				</p>
				{/* <p style={{ color: '#3f3d56' }} className="text-gray-900 title-font ">*** Subject to the approval from Govt. of Kerala</p> */}
				{/* <p style={{ marginTop: '15px' }}>
					<span className="text-gray-900 font-extrabold title-font ">
						Scholarships (Not Applicable for NRI Quota admissions)
					</span>
					<ul className="feelist">
						<li>
							Students with KEAM (Kerala Engineering Entrance Examination) Rank between 1 to 10,000 are
							eligible for a scholarship of Rs.18,750/- (per semester) in Tuition fee.
						</li>
						<li>
							Students taking admission in Electrical & Electronics Engineering branch are eligible for a
							scholarship of Rs.18,750/- (per semester) in Tuition fee.
						</li>
						<li>
							Students taking admission in Civil Engineering, Electronics & Communication Engineering &
							Mechanical Engineering branches are eligible for a scholarship of Rs.7,500/- (per semester)
							in Tuition fee.
						</li>
						<li>
							Over and above, students who belong to Kothamangalam and Idukki Dioceses are eligible for a
							fee concession of Rs. 5,000/- at the time of admission.
						</li>
					</ul>
				</p> */}

				{/* <p className="feetable_container">
					<table className="feetable">
						<tr>
							<th className="feetable">KEAM Rank &lt; 10000</th>
							<th className="feetable">
								Tuition Fee to be paid <br />
								(per semester)
							</th>
						</tr>
						<tbody>
							<tr>
								<td className="feetable">All Branches</td>
								<td className="feetable text-center">Rs.18,750/-</td>
							</tr>
						</tbody>
					</table>

					<table className="feetable">
						<tr>
							<th className="feetable">Branch</th>
							<th className="feetable">
								Tuition Fee to be paid <br />
								(per semester)
							</th>
						</tr>
						<tbody>
							<tr>
								<td className="feetable">Civil Engineering</td>
								<td className="feetable text-center">Rs.30,000/-</td>
							</tr>
							<tr>
								<td className="feetable">Electronics & Communication Engineering</td>
								<td className="feetable text-center">Rs.30,000/-</td>
							</tr>
							<tr>
								<td className="feetable">Mechanical Engineering</td>
								<td className="feetable text-center">Rs.30,000/-</td>
							</tr>
							<tr>
								<td className="feetable">Electrical & Electronics Engineering</td>
								<td className="feetable text-center">Rs.18,750/-</td>
							</tr>
						</tbody>
					</table>
				</p> */}
			</div>
			<div>
				<h4 style={{ marginTop: '30px', marginBottom: '30px', fontWeight:'bold' }}>
					Scholarship
				</h4>				
				<ul style={{margin:'0px 40px', listStyleType:'disc'}}>
					<li>
						Students with KEAM (Kerala Engineering Entrance Examination) Rank between 1 to 7,500 are eligible for a scholarship of Rs.25, 125/- (per semester) in Tuition fee.				
					</li>					
				</ul>
				<table className="feetable" style={{textAlign:'center', margin:'0px auto'}}>
					<tr>
						<th className="feetable">KEAM Rank &lt; 7,500</th>
						<th className="feetable">
							Tuition Fee to be paid <br />
							(per semester)
						</th>
					</tr>
					<tbody>
						<tr>
							<td className="feetable">All Branches</td>
							<td className="feetable text-center">Rs. 25,125/-</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}
