import React from 'react';
import VjcetService from '../../vjcet-service';

const Online = () => {
	return (
		<div>
			<h4>ONLINE APPLICATION SUBMISSION</h4>
			<p className="text-justify" style={{ marginTop: '30px', marginBottom: '30px' }}>
				Candidates can pay the Application form fee through the Online Payment Gateway option. Candidates should
				have a valid Debit Card/Credit card or Net Banking facility to proceed further. Upon successful
				completion of Application submission, candidate will be given an Application Number (Candidates will
				receive a mail with the Application Number).Applicant need not send the print out of the Application
				Form via Post. Applicant must upload his/her recent passport size photo and the scanned copy of the
				following documents(pdf, jpeg or doc formats)( Each file should not exceed 2MB).
			</p>
			<ul>
				<li>A passport size photo</li>
				<li>Proof of Date of Birth (10th / Birth Certificate)</li>
				<li>Signature of the Candidate </li>
				<li>Signature of Father/Guardian </li>
				<li>+2 Marklist</li>
				<li>
					KEAM {VjcetService.APPLICATION_YEAR} Admit card of the entrance examination conducted by the
					Commissioner for Entrance Examination.
				</li>
				<li>KEAM {VjcetService.APPLICATION_YEAR} Score Sheet.</li>
				<li className="text-justify">
					Proof of Community Quota: All Christian denominations are eligible to be considered under Community
					Quota. Applicant can claim the same by uploading a letter from parish Priest/Village office.
				</li>
			</ul>
		</div>
	);
};

export default Online;
