import React from 'react';
import { Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import accenture from '../images/logos/accenture.png';
import borad from '../images/logos/borad.png';
import cognizant from '../images/logos/cognizant.png';
import css from '../images/logos/css.png';
import elgi from '../images/logos/elgi.png';
import federalbank from '../images/logos/federalbank.png';
import hcl from '../images/logos/hcl.png';
import itc from '../images/logos/itc.png';
import mphasis from '../images/logos/mphasis.png';

import techMahindra from '../images/logos/techmahindra.png';

import Indoshell from '../images/logos/Indoshell.png';
import Amazon from '../images/logos/Amazon.png';
import Silverpeak from '../images/logos/Silverpeak.png';
import ENY from '../images/logos/E&Y.png';
import mitsogo from '../images/logos/mitsogo.jpg';
import Hexaware from '../images/logos/Hexaware.png';
import TCS from '../images/logos/TCS.png';
import DigtalCoreTechnologies from '../images/logos/Digtal-Core-Technologies.png';
import Infosys from '../images/logos/Infosys.png';
import VVDN from '../images/logos/VVDN.jpg';
import NMSWorks from '../images/logos/NMS-Works.jpg';
import D6Techonologies from '../images/logos/6D-Techonologies.png';
import Experion from '../images/logos/Experion.jpg';
import IBS from '../images/logos/IBS.png';
import Sutherland from '../images/logos/Sutherland.png';

import ust from '../images/logos/ust.png';
import line from '../images/icons/line.png';

import { Button } from '@material-ui/core';

const Placement = () => {
	return (
		<Container>
			<h1 className="sm:text-3xl text-2xl font-medium title-font text-center text-gray-900 mt-10">
				Placement Details
			</h1>
			<div id="placement-dt" className="border-r-2 border-gray-300">
				<div id="placement-text">
					<div className="placement-nav">
						<Link className="logo" to="/placement">
							<b>KNOW MORE</b>
						</Link>
					</div>
					<p className="text-style-highlight">
						Total placement offers for this year (2020-2024 Batch) reaches 235 (till date).
					</p>

					<p className="text-style text-justify">
						The Placement and Training cell monitors the employment opportunities, cater to enhance
						employability of students and arrange on and off campus interviews. Our Campus recruitment
						program starts right from the penultimate semester. It’s a policy of the Placement Cell not to
						patronize companies bend on doing Education & Training activities to attract the students in the
						name of recruitment against payment. We do not encourage the students, those who are placed
						through campus selection in a core company to attend the further campus interviews. The students
						aspiring for higher studies are encouraged to undergo GATE/CAT exams.
					</p>

					{/* <div className="btn-style1">
                        <Button variant="contained" style={btnStyles}  >
                            DOWNLOAD PDF
                        </Button>
                        </div> */}
				</div>
				<div className="company-logos">
					<div className="grid grid-cols-5 gap-2">
						<img src={accenture} alt="accenture" />
						<img src={Amazon} alt="Amazon" />
						<img src={borad} alt="borad" />
						<img src={cognizant} alt="Cognizant" />
						<img src={css} alt="css" />
					</div>
					<div className="grid grid-cols-5 gap-2">
						<img src={DigtalCoreTechnologies} alt="DigtalCoreTechnologies" />
						<img src={D6Techonologies} alt="D6Techonologies" />
						<img src={elgi} alt="elgi" />
						<img src={Experion} alt="Experion" />
						<img src={ENY} alt="ENY" />
					</div>
					<div className="grid grid-cols-5 gap-2">
						<img src={federalbank} alt="accenture" />
						<img src={hcl} alt="accenture" />
						<img src={Hexaware} alt="Hexaware" />
						<img src={Indoshell} alt="Indoshell" />
						<img src={IBS} alt="IBS" />
					</div>
					<div className="grid grid-cols-5 gap-2">
						<img src={Infosys} alt="Infosys" />
						<img src={itc} alt="accenture" />
						<img src={mitsogo} alt="mitsogo" />
						<img src={NMSWorks} alt="NMSWorks" />
						<img src={Silverpeak} alt="Silverpeak" />
					</div>
					<div className="grid grid-cols-5 gap-2">
						<img src={Sutherland} alt="Sutherland" />
						<img src={TCS} alt="TCS" />
						<img src={VVDN} alt="VVDN" />
					</div>
				</div>
			</div>
		</Container>
	);
};

const btnStyles = {
	color: '#9d0055',
	backgroundColor: '#fff',
	minWidth: '150px',
	height: '50px',
	fontSize: '14px',
	fontWeight: '700',
	borderRadius: '30px',
	border: '1px solid #9d0055',
};
export default Placement;
