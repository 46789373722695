import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
	AppBar,
	Tabs,
	Tab,
	Typography,
	Box,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Avatar,
	Divider,
	ListItemIcon,
} from '@material-ui/core';
import VjcetService from '../../vjcet-service';

import { Container, Link } from '@material-ui/core';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	tab: {
		minWidth: 200, // a number of your choice
		width: 200, // a number of your choice
	},
}));

export default function Instruction() {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Container>
			<div id="instruction">
				<div className={classes.root}>
					<AppBar
						position="static"
						style={{
							color: 'black',
							background: '#f4f4f4',
							boxShadow: 'none',
							paddingTop: '30px',
							overflow: 'hidden',
						}}
					>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label="simple tabs example"
							variant="scrollable"
							indicatorColor="primary"
							scrollButtons="auto"
							aria-label="scrollable auto tabs example"
						>
							<Tab className={classes.tab} label="MANAGEMENT QUOTA" {...a11yProps(0)} />
							<Tab label="NRI QUOTA" {...a11yProps(1)} />
							<Tab label="GOVT QUOTA" {...a11yProps(1)} />
						</Tabs>
					</AppBar>
					<TabPanel value={value} index={0}>
						<div className="section-apply">
							<h4 style={{ marginBottom: '30px' }}>How To Apply</h4>
							<p className="text-justify">
								Candidates are advised to visit the college website frequently for all further
								notifications regarding the schedule of admission.
							</p>
							<p className="text-justify">
								Candidates can only apply through Online Application. Fee for application is Rs. 500/-.
								(Rupees Five Hundred only)
							</p>
						</div>
						<div id="instruction-detl">
							<h4>Eligibility Criteria</h4>
							<ul>
								<li className="text-justify">
									<b>Nationality</b>:Candidates must be citizens of India.
								</li>
								<li className="text-justify">
									<b>Age</b>:Applicants should have completed 17 years of age on 31st December   {VjcetService.APPLICATION_YEAR}.
								</li>
								<li className="text-justify">
									<b>Academic Requirements:</b>Pass in Higher Secondary Examination of the Board of
									Higher Secondary Education of Kerala or an examination recognized equivalent thereto
									with 45% marks in Mathematics, Physics and Chemistry put together.
									<ul>
										<li className="text-justify">
											Note:-In two year Kerala Higher Secondary courses or examinations recognized
											equivalent thereto with two year course where the Board Examinations are
											conducted in both years, the total marks of two years in the respective
											subjects as shown in the mark lists of the respective Higher Secondary
											Boards will be considered for academic eligibility.
										</li>
										<li className="text-justify">
											In Higher Secondary courses or examinations recognized equivalent thereto
											where Board Examinations are conducted only at the end of 12th class (final
											year), the marks in the respective subjects as shown in the mark lists of
											the respective Higher Secondary Boards will be considered for academic
											eligibility.
										</li>
										<li className="text-justify">
											For all other type of Higher Secondary courses or examinations recognized
											equivalent thereto, the marks of the respective subjects as shown in the
											mark list of the respective Board of Examinations will be considered for
											academic eligibility.
										</li>
									</ul>
								</li>
								<li className="text-justify">
									<b>Entrance Examination</b>:Candidates should qualify in the Engineering Entrance
									Exam {VjcetService.APPLICATION_YEAR} conducted by the Commissioner of Entrance Exams,
									Govt. of Kerala.
								</li>
							</ul>
							<div className="download_links">
								<a className="download_button " href="/VJC_Brochure_2024.pdf" target="_blank">
									<b>Download Brochure</b>
								</a>
								<a className="download_button" href="/VJC_Prospectus_2024.pdf" target="_blank">
									<b>Download Prospectus</b>
								</a>
							</div>
						</div>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<div>
							<p className="text-justify" style={{ marginBottom: '30px' }}>
								15% seats (i.e., 9 out of 60 seats) are allotted in the NRI category. An NRI can sponsor
								the children or dependents and he/she will have to give an{' '}
								<a class="underline	font-semibold italic text-base" href="/NRI-Undertaking.pdf" target="_blank">
									undertaking
								</a>{' '}
								in the prescribed format. Also he should provide a copy of <strong>Passport</strong> and{' '}
								<strong>Visa</strong>.
							</p>
							<h4 style={{ marginBottom: '30px' }}>Criteria of Eligibility for Admission</h4>
							<ul>
								<li className="text-justify">
									<b>Age:</b>
									Applicants should have completed 17 years of age on 31st December{' '}
									{VjcetService.APPLICATION_YEAR}.
								</li>
								<li className="text-justify">
									<b>Academic Requirements:</b>
									Pass in Higher Secondary Examination of the Board of Higher Secondary Education of
									Kerala or an examination recognized equivalent thereto with 45% marks in
									Mathematics, Physics and Chemistry put together .
									<p>
										In case, the candidate has not studied Chemistry, the marks obtained in Computer
										Science shall be considered. In case, the candidate has not studied Chemistry
										and Computer Science, the marks obtained in Biotechnology shall be considered.
										In case, the candidate has not studied Chemistry, Computer Science and
										Biotechnology, the marks obtained in Biology shall be considered.
									</p>
								</li>
								<li className="text-justify">
									<b>Entrance Examination:</b>Candidates are exempted from qualifying in the
									Engineering Entrance Exam {VjcetService.APPLICATION_YEAR} conducted by the Commissioner
									of Entrance Exams, Government of Kerala.
								</li>
							</ul>
							<div style={{ marginTop: '1rem', color: '#9d0055' }}>
								<p>
									<b>For Enquires, Contact:</b>
								</p>
								<ul style={contactStyle}>
									<li>Mr. Sunny Jacob,</li>
									<li>Administrative Officer</li>
									<li>Office: +91-485-2262255, 2262211</li>
									<li>Mob: 9447465399</li>
								</ul>
							</div>
						</div>
					</TabPanel>
					<TabPanel value={value} index={2}>
						<div>
							<div style={{ marginBottom: '30px' }}>
								<p className="text-center text-bold" style={{ marginBottom: '1rem', color: '#9d0055' }}>
									<b>Institution Code: VJC</b>
								</p>
								<p className="text-justify">
									50% of the total seats (i.e., 30 in a batch of 60 students) are allotted through the
									common counseling by the Entrance Commissioner.
								</p>
							</div>

							<h4 style={{ marginBottom: '30px' }}>Eligibility Criteria</h4>
							<ul>
								<li className="text-justify">
									<b>Nationality:</b> Candidates must be citizens of India.
								</li>
								<li className="text-justify">
									<b>Age:</b> Applicants should have completed 17 years of age on 31st December
									{VjcetService.APPLICATION_YEAR}.
								</li>
								<li className="text-justify">
									<b>Academic Requirements:</b> Candidates who have passed Higher Secondary
									Examination, Kerala, or Examinations recognized as equivalent thereto, with 45 % marks in Mathematics, Physics and
									Chemistry put together are eligible for admission. In case, the candidate has not
									studied Chemistry, the marks obtained in Computer Science shall be considered. In
									case, the candidate has not studied Chemistry and Computer Science, the marks
									obtained in Biotechnology shall be considered. In case, the candidate has not
									studied Chemistry, Computer Science and Biotechnology, the marks obtained in Biology
									shall be considered.
									<ul>
										<li className="text-justify">
											Note:In two year Kerala Higher Secondary courses or examinations recognized
											equivalent thereto with two year course where the Board Examinations are
											conducted in both years, the total marks of two years in the respective
											subjects as shown in the mark lists of the respective Higher Secondary
											Boards will be considered for academic eligibility.
										</li>
										<li className="text-justify">
											In Higher Secondary courses or examinations recognized equivalent thereto
											where Board Examinations are conducted only at the end of 12th class (final
											year), the marks in the respective subjects as shown in the mark lists of
											the respective Higher Secondary Boards will be considered for academic
											eligibility.
										</li>
										<li className="text-justify">
											For all other type of Higher Secondary courses or examinations recognized
											equivalent thereto, the marks of the respective subjects as shown in the
											mark list of the respective Board of Examinations will be considered for
											academic eligibility.
										</li>
									</ul>
								</li>
								<li className="text-justify">
									<b>Entrance Examination:</b> Candidates should qualify in the Engineering Entrance
									Exam {VjcetService.APPLICATION_YEAR} conducted by the Commissioner of Entrance Exams,
									Govt. of Kerala.
								</li>
							</ul>
							<div style={{ marginTop: '1rem', color: '#9d0055' }}>
								<p>
									<b>For Enquires, Contact:</b>
								</p>
								<ul style={contactStyle}>
									<li>Mr. Manu Jose,</li>
									<li>Assistant Professor</li>
									<li>8281352211</li>
									<li>8281652211</li>
								</ul>
							</div>
						</div>
					</TabPanel>
				</div>
			</div>
		</Container>
	);
}

const contactStyle = {
	listStyle: 'none',
};
