import React from 'react'
import code from '../../images/code1.png'
import code1 from '../../images/college_code.png'
import './style.css'

function Code() {
    return (
        <div className="code">
            <img src={code1} alt="college code" style={{ width: "90%" }} />
        </div>
    )
}

export default Code
