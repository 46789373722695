import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Btn from '../Button';
import './style.css';
import SendIcon from '@material-ui/icons/Send';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import VjcetService from '../../vjcet-service';
import csd from '../../images/CSD.png'

export default class CsdModal extends Component {
	state = {
		modal: { open: false },
	};

	handleOpen = () => {
		this.setState({
			modal: { open: true },
		});
	};

	handleClose = () => {
		this.setState({
			modal: { open: false },
		});
	};

	render() {
		const { modal } = this.state;
		return (
			<div>
				<Btn title="Learn More"  click={this.handleOpen} height="25px" fontSize="9px" background="#ffffff"  />
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className="modal"
					open={modal.open}
					onClose={this.handleClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={modal.open} className="paper">
						<div id="csd-image">
						<img
							className="image"
							src={csd}
					
							alt="no image"
						/>
						</div>
					</Fade>
				</Modal>
			</div>
		);
	}
}
