import React from 'react';
import PhoneIcon from '@material-ui/icons/Smartphone';

const Contacts = () => {
    return (
        <div>
            <div>
                <h4>POSTAL ADDRESS</h4>
                <ul className="list-style">
                    <li> The Principal,</li>
                    <li>Viswajyothi College of Engineering and Technology,</li>
                    <li>Vazhakulam P O, </li>
                    <li>Muvattupuzha, </li>
                    <li>Ernakulam 686 670</li>
                    <li>Email: admission@vjcet.org</li>
                </ul>
            </div>

            <div style={{ marginTop: "2vh" }}>
                <h4>ADMISSION COORDINATOR </h4>
                <ul className="list-style">
                    <li>Mr. Manu Jose (Assistant Professor) </li>
                    <li >Mob:<a style={{textDecoration:"none",color:"#9d0055"}} href="tel:8281352211"><PhoneIcon style={{ fontSize: 15 }} />8281352211</a> </li>
                </ul>
            </div>

        </div>

    )

}

export default Contacts;